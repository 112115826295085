import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

import packageJson from '../../../package.json';
import {InfoApi} from '../interfaces/api/info-api';
import {environment} from '../../environments/environment';

const version = packageJson.version;

const GENERAL_MENU_ITEMS: string[] = [
	'Audience',
	'Channels',
	'Content',
	'Regions',
	'Spokesperson'
];

@Injectable({
	providedIn: 'root'
})
class DataService {

	constructor(private http: HttpClient) {
	}

	public getData(url: string, options = {}): any {
		return this.http.get(environment.baseUrl + url, options);
	}

	public postData(url: string, model = {}): any {
		return this.http.post(environment.baseUrl + url, model);
	}

	public putData(url: string, options = {}): any {
		return this.http.put(environment.baseUrl + url, options);
	}

	public deleteData(url: string, options = {}): any {
		return this.http.delete(environment.baseUrl + url, options);
	}

	public getTypes() {
		return of(GENERAL_MENU_ITEMS);
	}

	public getAppVersion(): Observable<{frontendVersion: string; backendVersion: string}> {
		return this.http.get(  environment.url + 'actuator/info')
			.pipe(
				map((data: InfoApi) => {
					return {
						backendVersion: data.build.version,
						frontendVersion: version,
					};
				})
			);
	}
}

export default DataService

import {Component, OnInit} from '@angular/core';
import {AuthSessionService} from '../services/auth-session.service';

/** This component handles redirecting users to the auth provider */
@Component({
	template: `
		<h3>Redirecting to login server</h3>
	`
})
export class LoginComponent implements OnInit {
	constructor(
		private authSessionService: AuthSessionService) {
	}

	/** once the component is up and running, redirect the user to the auth provider */
	ngOnInit() {
		this.authSessionService.ADFSLogin();
	}
}

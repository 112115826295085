import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './oauth/login.component';
import {ManagementPath} from './enums/management-path-enum';
import {OauthTokenGuard} from './oauth/oauth-token.guard';
import {MANAGEMENT_ROUTES} from './constants/management-routes';
import {NotFoundComponent} from './components/shared/not-found/not-found.component';
import {AuthenticationGuard} from './oauth/authentication.guard';
import {GuestScreenComponent} from './views/guest-screen/guest-screen.component';
import {GeneralViewComponent} from './views/general/general-view.component';
import {OAuthCallbackComponent} from './oauth/oauth-callback.component';
import {ManagementViewComponent} from './views/management/management-view.component';

const routes: Routes = [
	{
		path: 'oauth',
		component: OAuthCallbackComponent,
		canActivate: [OauthTokenGuard]
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: '',
		component: GeneralViewComponent,
		canActivate: [AuthenticationGuard],
	},
	{
		path: ManagementPath.Main,
		component: ManagementViewComponent,
		canActivate: [AuthenticationGuard],
		children: MANAGEMENT_ROUTES
	},
	{
		path: 'guest',
		component: GuestScreenComponent,
	},
	{
		path: '404', component: NotFoundComponent
	},
	{
		path: '**', redirectTo: '/404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}

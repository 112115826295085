import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CategoryItem} from '../../../interfaces/category/category-item';
import {CategoryListEvent} from '../../../interfaces/category/category-list-event';
import {ToolEvent} from '../../../enums/tool-event-enum';
import {CategoryName} from '../../../enums/api/сategory-name-enum';

@Component({
	selector: 'app-category-list-table',
	templateUrl: './category-list-table.component.html',
	styleUrls: ['./category-list-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryListTableComponent {
	@Input() list: CategoryItem[];
	@Input() headerTitle: string;
	@Input() readonly = false;
	@Input() currentCategoryName: CategoryName;
	@Output() categoryClick: EventEmitter<CategoryListEvent> = new EventEmitter();
	@Output() categoryItemDropEvent: EventEmitter<CategoryItem> = new EventEmitter();
	@Output() setDraggedItemEvent: EventEmitter<CategoryItem> = new EventEmitter();
	private droppedItem: CategoryItem;

	categoryClickEvent(toolEvent: ToolEvent, categoryItem: CategoryItem) {
		this.categoryClick.emit({toolEvent, categoryItem});
	}

	setDroppedItem(item: CategoryItem) {
		this.droppedItem = item;
	}

	setDraggedItem(item: CategoryItem) {
		this.setDraggedItemEvent.emit(item);
	}

	onCategoryItemDropEvent() {
		this.categoryItemDropEvent.emit(this.droppedItem);
	}
}

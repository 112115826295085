import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {SpokesPerson} from '../../../../../../interfaces/spokespeople/spokespeople';
import {SelectItem} from 'primeng';

@Component({
	selector: 'app-event-spokespeople',
	templateUrl: './event-spokespeople.component.html',
	styleUrls: ['./event-spokespeople.component.scss']
})
export class EventSpokespeopleComponent implements OnInit {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() spokesPeople: SpokesPerson[];
	@Input() selectedPerson: SpokesPerson;
	@Input() disabled: boolean;

	@Input() selectedItem: SelectItem;
	@Output() personChange = new EventEmitter<SpokesPerson>();

	public displayPersonSidebar = false;

	static getFullName(firstName: string, lastName: string): string {
		return (firstName ? firstName + ' ' : null) + lastName;
	}

	ngOnInit() {
		this.selectedItem = this.selectedPerson ? {
			value: this.selectedPerson.id,
			label: EventSpokespeopleComponent.getFullName(this.selectedPerson?.firstName, this.selectedPerson?.lastName)
		} : null;
	}

	public onChangeSelectItem(person: SpokesPerson) {
		this.selectedItem = person
			? {value: person.id, label: EventSpokespeopleComponent.getFullName(person?.firstName, person?.lastName)}
			: null;

		this.personChange.emit(person);
		this.onDisplay(false);
	}

	public onDisplay(isShow: boolean) {
		this.displayPersonSidebar = isShow;
	}

}

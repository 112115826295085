import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {CategoryListEvent} from '../../../interfaces/category/category-list-event';
import {ToolEvent} from '../../../enums/tool-event-enum';
import {CategoryItem} from '../../../interfaces/category/category-item';
import {DataStatus} from '../../../enums/data-status-enum';
import {SidebarMode} from '../../../enums/sidebar-mode-enum';
import {CategoryService} from '../../../services/management/category.service';
import {ManagementService} from '../../../services/management.service';
import {ActivatedRoute} from '@angular/router';
import {CategoryConfigData} from '../../../interfaces/category/categoty-config-data';
import {UnsavedChangesService} from 'src/app/services/unsaved-changes.service';
import {ErrorApi} from '../../../interfaces/api/error-api';

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {
	public categoryConfig: CategoryConfigData = {
		CATEGORY: '',
		CATEGORY_NAME: null
	};

	configDataSub: Subscription;

	public readonly sidebarMode = SidebarMode;
	public readonly sidebarStatus = DataStatus;

	public categories: CategoryItem[];
	public currentCategory: CategoryItem;
	private draggedItem: CategoryItem;
	public showCategoryDeleteSidebar: boolean;
	public currentDataStatus: DataStatus = DataStatus.Loading;
	public categorySideMode: SidebarMode = SidebarMode.Closed;
	public showLoader = true;
	public searchValue: string;
	public categoryError: ErrorApi;

	private catSubscription: Subscription;
	private addCategoryItemSub: Subscription;
	private searchSub: Subscription;
	public closeAllSub: Subscription;

	get categorySideTitle(): string {
		return this.categorySideMode === SidebarMode.Edit
			? (`Edit ${this.categoryConfig.CATEGORY}: ${this.currentCategory?.title || ''}`)
			: `Add New ${this.categoryConfig.CATEGORY}`;
	}

	get showCategorySidebar(): boolean {
		return this.categorySideMode !== SidebarMode.Closed;
	}

	constructor(
		private categoryService: CategoryService,
		private managementService: ManagementService,
		private unsavedChangesService: UnsavedChangesService,
		public route: ActivatedRoute) {
	}

	ngOnInit() {
		this.configDataSub = this.route.data.subscribe((conf: CategoryConfigData) => {
			this.categoryConfig = conf;
		});
		this.searchSub = this.managementService.searchValue$
			.pipe(debounceTime(300))
			.subscribe((value: string) => this.handleSearch(value));
		this.addCategoryItemSub = this.managementService.categoryItemEvent$.subscribe(() => this.addCategory());

		this.closeAllSidebars();
	}

	private closeAllSidebars() {
		this.closeAllSub = this.unsavedChangesService.closeAll().subscribe(() => {
			this.categorySideMode = SidebarMode.Closed;
			this.clearSideData();
		});
	}

	setDraggedItem(item: CategoryItem) {
		this.draggedItem = item;
	}

	onCategoryItemDrop(droppedItem: CategoryItem) {
		this.showLoader = true;
		const newItem = {...this.draggedItem, position: droppedItem.position};
		this.saveCategory(newItem);
	}

	handleSearch(searchValue) {
		this.searchValue = searchValue;
		this.getCategoryData();
	}

	public listClickEvent(catEvent: CategoryListEvent) {
		switch (catEvent.toolEvent) {
			case ToolEvent.Delete:
				this.currentCategory = catEvent.categoryItem;
				this.openCategoryDeleteSidebar();
				break;
			case ToolEvent.Edit:
				this.openCategory(catEvent.categoryItem.id);
				break;
		}
	}

	public addCategory() {
		this.categorySideMode = SidebarMode.Add;
		this.currentDataStatus = DataStatus.Ready;
		this.currentCategory = {} as CategoryItem;
	}

	private openCategory(categoryId: number) {
		this.categorySideMode = SidebarMode.Edit;
		this.currentDataStatus = DataStatus.Loading;

		this.categoryService.getCategoryById(categoryId)
			.subscribe(res => {
				this.currentCategory = res;
				this.currentDataStatus = DataStatus.Ready;
			});
	}

	public saveCategory(newCategory: CategoryItem) {
		this.currentDataStatus = DataStatus.Saving;

		if (newCategory.id) {
			this.categoryService.updateCategory(newCategory).subscribe(
				() => this.updateCategoryData(),
				(error) => this.handleCategoryError(error)
			);
		} else {
			this.categoryService.addCategory(newCategory, this.categoryConfig).subscribe(
				() => this.updateCategoryData(),
				(error) => this.handleCategoryError(error)
			);
		}
	}

	private updateCategoryData() {
		this.getCategoryData();
		this.closeCategorySidebar();
	}

	private handleCategoryError(error: ErrorApi) {
		this.currentDataStatus = DataStatus.Ready;
		this.categoryError = error;
	}

	public resetCategoryError() {
		this.categoryError = null;
	}

	public deleteCategory(catId: number) {
		this.currentDataStatus = DataStatus.Saving;
		this.categoryService.deleteCategory(catId, this.categoryConfig)
			.subscribe(() => {
				this.getCategoryData();
				this.categorySideMode = SidebarMode.Closed;
				this.showCategoryDeleteSidebar = false;
				this.clearSideData();
			});
	}

	public closeCategorySidebar(isUnsavedData?: boolean) {
		if (isUnsavedData) {
			return;
		}

		this.categorySideMode = SidebarMode.Closed;
		this.clearSideData();
	}

	public openCategoryDeleteSidebar() {
		this.showCategoryDeleteSidebar = true;
		this.currentDataStatus = DataStatus.Loading;
	}

	public closeCategoryDeleteSidebar() {
		this.showCategoryDeleteSidebar = false;
		this.currentDataStatus = DataStatus.Ready;
	}

	private getCategoryData() {
		this.showLoader = true;

		if (this.searchValue?.length) {
			this.categoryService.searchCategoriesByTitle(this.categoryConfig.CATEGORY_NAME, this.searchValue)
				.subscribe(res => this.updateCategoryList(res));
		} else {
			this.catSubscription = this.categoryService.getCategories(this.categoryConfig.CATEGORY_NAME)
				.subscribe(res => this.updateCategoryList(res));
		}
	}

	private updateCategoryList(list) {
		this.categories = list;
		this.showLoader = false;
	}

	private clearSideData() {
		this.currentDataStatus = DataStatus.Loading;
		this.currentCategory = null;
	}

	ngOnDestroy() {
		this.configDataSub.unsubscribe();
		this.catSubscription.unsubscribe();
		this.addCategoryItemSub.unsubscribe();
		this.searchSub.unsubscribe();
		this.closeAllSub.unsubscribe();
	}
}

import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

import {MenuItem} from 'primeng/api';

import {Subscription} from 'rxjs';
import {switchMap, debounceTime} from 'rxjs/operators';

import {HeaderMode} from '../../../enums/header-mode-enum';
import {FilterEvent} from 'src/app/enums/event-filter-enum';
import {FilterAction} from 'src/app/enums/filter/filter-action-enum';
import {FilterService} from 'src/app/services/filter/filter.service';
import {ExportPngService} from 'src/app/services/exportPng/export-png.service';
import {AppHeaderService} from '../../../services/general/app-header.service';
import {EventsService} from '../../../services/events/events.service';
import {KeywordsService} from '../../../services/management/keywords.service';
import {CalendarEvent} from '../../../interfaces/events/calendar-event';
import {Keyword} from '../../../interfaces/keywords/keyword';
import {CalendarSearchItem} from '../../../interfaces/calendar/calendar-search-item';

@Component({
	selector: 'app-header',
	templateUrl: './app-header.component.html',
	styleUrls: ['./app-header.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppHeaderComponent implements OnInit, OnDestroy {
	@Input() mode: HeaderMode;
	@Input() userName: string;
	@Input() menuItems: MenuItem[];

	public searchValue: string;
	public eventList: CalendarEvent[];
	public keywordList: Keyword[];

	public readonly headerMode = HeaderMode;
	public readonly filterEvent = FilterEvent;

	public appliedFilter = false;

	public filterActionSub: Subscription;
	public headerSearchSub: Subscription;

	constructor(
		private router: Router,
		private filterService: FilterService,
		private exportPngService: ExportPngService,
		private appHeaderService: AppHeaderService,
		private eventsService: EventsService,
		private keywordsService: KeywordsService,
	) {
	}

	ngOnInit() {
		this.filterActionSub = this.filterService.getFilterAction().subscribe((applied: FilterAction) => {
			this.appliedFilter = applied === FilterAction.Applied;
		});
		this.headerSearchSub = this.appHeaderService.getHeaderSearchValue()
			.pipe(
				debounceTime(300),
				switchMap((value: string) => {
					this.searchValue = value;
					return this.eventsService.getEventByTitle(value);
				}))
			.subscribe((data: CalendarEvent[]) => {
				this.eventList = data;
			});
	}

	ngOnDestroy() {
		this.filterActionSub.unsubscribe();
		this.headerSearchSub.unsubscribe();
	}

	public onClickReturn(): void {
		this.router.navigate(['/']);
	}

	public onPngDownload(): void {
		this.exportPngService.exportPng();
	}

	public onFilterClick(filterEvent: FilterEvent): void {
		this.filterService.setFilterEvent(filterEvent);
	}

	public onSearchIconClick(): void {
		this.mode = HeaderMode.GeneralSearch;
		this.getKeywords();
	}

	private getKeywords() {
		this.keywordsService.getKeywords()
			.subscribe(res => {
				this.keywordList = res;
			});
	}

	public addItemClick(): void {
		this.appHeaderService.addEvent();
	}

	public onSearchUpdate(searchValue: string): void {
		this.appHeaderService.setHeaderSearchValue(searchValue);
	}

	public clearSearchClick() {
		this.searchValue = null;
		this.mode = HeaderMode.General;
	}

	public keywordsClickEventHandler(item: Keyword): void {
		this.onSearchUpdate(item.text);
	}

	public seeAllClickEventHandler() {
		this.mode = HeaderMode.General;
		this.appHeaderService.setCalendarSearchItem({title: this.searchValue});
	}

	public eventItemClickHandler(item: CalendarEvent): void {
		const searchItem: CalendarSearchItem = {
			title: item.title,
			startDate: item.startDate
		}
		this.searchValue = searchItem.title;
		this.mode = HeaderMode.General;
		this.appHeaderService.setCalendarSearchItem(searchItem);
	}
}

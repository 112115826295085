import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
	selector: 'app-calendar',
	templateUrl: './app-calendar.component.html',
	styleUrls: ['./app-calendar.component.scss']
})
export class AppCalendarComponent {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() minDate?: Date = null;
	@Input() maxDate?: Date = null;

	@Output() selectDate = new EventEmitter<Date>();

	private readonly shortMounts: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	private readonly shortDays: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
	public en = {
		firstDayOfWeek: 1,
		dayNames: this.shortDays,
		dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		dayNamesMin: this.shortDays,
		monthNames: this.shortMounts,
		monthNamesShort: this.shortMounts,
		today: 'Today',
		clear: 'Clear',
		weekHeader: 'Wk'
	};

	public onSelect(date: Date) {
		this.selectDate.emit(date);
	}

}

import {Component, EventEmitter, Input, Output} from '@angular/core';

import {CategoryItem} from '../../../../../../../interfaces/category/category-item';

@Component({
	selector: 'app-audience-item',
	templateUrl: './audience-item.component.html',
	styleUrls: ['./audience-item.component.scss']
})
export class AudienceItemComponent {
	@Input() title: string;
	@Input() disabled: boolean;
	@Input() primary: boolean;
	@Input() selectedItem: CategoryItem;
	@Output() itemClick = new EventEmitter<number>();
	@Output() deleteClick = new EventEmitter<number>();

	public displaySelectSidebar = false;

	public onItemClick() {
		this.itemClick.emit(this.selectedItem?.id);
	}

	public onItemDelete() {
		this.deleteClick.emit(this.selectedItem?.id);
	}
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ManagementMenu} from '../../../interfaces/managment-menu';

@Component({
	selector: 'management-menu',
	templateUrl: './management-menu.component.html',
	styleUrls: ['./management-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagementMenuComponent{
	@Input() list: ManagementMenu[];
}

import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-detail-sidebar',
	templateUrl: './detail-sidebar.component.html',
	styleUrls: ['./detail-sidebar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DetailSidebarComponent {
	@Input() visible: boolean;
	@Output() visibleChange = new EventEmitter<boolean>();
	@Input() title: string;

	displaySelectSidebar = false;

	onChangeVisible(value: boolean) {
		this.visibleChange.emit(value);
	}
}

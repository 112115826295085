import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, Validators} from '@angular/forms';
import {SelectItem} from 'primeng';

import {FormSidebarComponent} from '../../../shared/app-sidebars/form-sidebar/form-sidebar.component';
import {CategoryItem} from '../../../../interfaces/category/category-item';
import {CategoryType} from '../../../../interfaces/category/category-type';
import {CommsCalendarError} from '../../../../interfaces/comms-calendar-error';
import {COMMS_CALENDAR_ERROR} from '../../../../constants/error-codes';

@Component({
	selector: 'app-audience-category-form',
	templateUrl: './audience-category-form.component.html',
	styleUrls: ['../../../shared/app-sidebars/form-sidebar/form-sidebar.component.scss'],
})
export class AudienceCategoryFormComponent extends FormSidebarComponent implements OnInit {
	private _categoryItem: CategoryItem;

	@Input() set categoryItem(value: CategoryItem) {
		this._categoryItem = value;

		if (this.audienceTypes) {
			this.items = this.audienceTypes.map(at => {
				return {label: at.title, value: at.id};
			});

			// when category was received
			if (value?.id) {
				this.titleControl.setValue(value?.title);
				this.descriptionControl.setValue(value?.description);
			}

			// add category from empty form in type
			if (value?.typeId) {
				this.selectedItem = this.items.find(item => item.value === value.typeId);
				this.typeControl.setValue(this.selectedItem.value);
			}
		}

	}

	get categoryItem(): CategoryItem {
		return this._categoryItem;
	}

	@Input() audienceTypes: CategoryType[];
	@Output() deleteClick = new EventEmitter<number>();
	@Output() saveClick = new EventEmitter<CategoryItem>();

	private handledError: CommsCalendarError = COMMS_CALENDAR_ERROR._102;
	public items: SelectItem[];
	public selectedItem: SelectItem;

	get descriptionControl(): AbstractControl {
		return this.sideForm?.get('description');
	}

	get typeControl(): AbstractControl {
		return this.sideForm?.get('categoryType');
	}

	public audienceFormError(formControl: string) {
		this.handledError.message = `Audience already exists in system`;
		return this.requiredError(formControl, this.handledError);
	}

	ngOnInit() {
		this.sideForm = this.formBuilder.group({
			title: ['', [Validators.required, this.uniqueValueValidator(this.handledError)]],
			categoryType: [null, Validators.required],
			description: [null]
		});
	}

	public onChangeSelectedItem(item: SelectItem) {
		this.categoryItem.typeId = item.value;
	}

	public saveEvent() {
		this.categoryItem.title = this.titleControl.value;
		this.categoryItem.description = this.descriptionControl.value || null;
		this.saveClick.emit(this.categoryItem);
	}

	public deleteButtonEvent() {
		this.deleteClick.emit(this.categoryItem.id);
	}
}

import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SelectItem} from 'primeng';

import {Subscription} from 'rxjs';

import {ScrollData} from '../../../interfaces/scroll.data';
import {DateRangeValue} from 'src/app/enums/api/date-range-enum';
import {CalendarTimeslot} from 'src/app/interfaces/calendar/calendar-data';
import {AppHeaderService} from '../../../services/general/app-header.service';
import {HeaderMenuSelectedItem} from 'src/app/interfaces/header-menu-item';
import {CategoryHeaderMenuItem} from '../../../enums/api/category-header-enum';

@Component({
	selector: 'app-calendar-header',
	templateUrl: './calendar-header.component.html',
	styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent implements OnInit, OnDestroy {
	@Input() public menuItems: SelectItem[];
	@Input() public scrollData: ScrollData;
	@Input() public dates: CalendarTimeslot[];
	@Input() public currentDateRange: DateRangeValue;
	@Input() public set selectedCategory(value: CategoryHeaderMenuItem) {
		this.selectedMenuItem = this.menuItems.find((item) => value === item.value.name)?.value;
	}

	public selectedMenuItem: HeaderMenuSelectedItem;
	public isReversedViewForPrint = false;
	public dateRangeValue: string;
	public defaultPaddingRight = 24;

	public readonly dateRange = DateRangeValue;

	private appHeaderServiceSub: Subscription;

	@Output() public menuItemChange: EventEmitter<HeaderMenuSelectedItem> = new EventEmitter();

	constructor(
		private cdr: ChangeDetectorRef,
		private appHeaderService: AppHeaderService,
	) {}

	ngOnInit() {
		this.getSelectedDateRange();
	}

	ngOnDestroy() {
		this.appHeaderServiceSub.unsubscribe();
	}

	public setReverseColorsForPrint(toggle: boolean): void {
		this.isReversedViewForPrint = toggle;
		if (toggle) {
			this.cdr.detectChanges();
		}
	}

	public getPaddingRight(): string {
		return this.defaultPaddingRight + this.scrollData.width + 'px';
	}

	public handleModelChange(event: HeaderMenuSelectedItem): void {
		this.menuItemChange.emit(event)
	}

	private getSelectedDateRange(): void {
		this.appHeaderServiceSub = this.appHeaderService.getDateRangeValue().subscribe((value: string) => {
			this.dateRangeValue = value;
		})
	}
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {components} from './components';
import {primeNGModules} from './prime-ng-modules';

const modules = [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
]

@NgModule({
	declarations: [
		...components,
	],
	exports: [
		...components,
		...modules,
		...primeNGModules,
	],
	imports: [
		...modules,
		...primeNGModules,
	]
})
export class SharedModule {
}

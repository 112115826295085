import {StatusCodes} from 'http-status-codes/build/cjs/status-codes';

export const COMMS_CALENDAR_ERROR = {
	_001: {
		errorCode: 'COMMS_CALENDAR_001',
		message: 'User is not registered in the system',
		statusCode: StatusCodes.FORBIDDEN
	},
	_101: {
		errorCode: 'COMMS_CALENDAR_101',
		message: 'Category not found',
		statusCode: StatusCodes.NOT_FOUND
	},
	_102: {
		errorCode: 'COMMS_CALENDAR_102',
		message: 'Category with this title already exists',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_201: {
		errorCode: 'COMMS_CALENDAR_201',
		message: 'CategoryType not found',
		statusCode: StatusCodes.NOT_FOUND
	},
	_202: {
		errorCode: 'COMMS_CALENDAR_202',
		message: 'CategoryType with this title already exists',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_301: {
		errorCode: 'COMMS_CALENDAR_301',
		message: 'Event not found',
		statusCode: StatusCodes.NOT_FOUND
	},
	_302: {
		errorCode: 'COMMS_CALENDAR_302',
		message: 'The end date must not be less than the start date',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_303: {
		errorCode: 'COMMS_CALENDAR_303',
		message: 'Amount of links must be 10 or less',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_304: {
		errorCode: 'COMMS_CALENDAR_304',
		message: 'Amount of primary audience must be only 1',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_305: {
		errorCode: 'COMMS_CALENDAR_305',
		message: 'Amount of secondary audience must be 4 or less',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_306: {
		errorCode: 'COMMS_CALENDAR_306',
		message: 'Title max length: 70',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_307: {
		errorCode: 'COMMS_CALENDAR_307',
		message: 'Description max length: 280',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_401: {
		errorCode: 'COMMS_CALENDAR_401',
		message: 'Keyword not found',
		statusCode: StatusCodes.NOT_FOUND
	},
	_407: {
		errorCode: 'COMMS_CALENDAR_407',
		message: 'Text already exist',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_501: {
		errorCode: 'COMMS_CALENDAR_501',
		message: 'Spokesperson not found',
		statusCode: StatusCodes.NOT_FOUND
	},
	_508: {
		errorCode: 'COMMS_CALENDAR_508',
		message: 'Spokesperson already exists',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_601: {
		errorCode: 'COMMS_CALENDAR_601',
		message: 'User not found',
		statusCode: StatusCodes.NOT_FOUND
	},
	_602: {
		errorCode: 'COMMS_CALENDAR_602',
		message: 'User with this email already exists',
		statusCode: StatusCodes.BAD_REQUEST
	},
	_603: {
		errorCode: 'COMMS_CALENDAR_603',
		message: 'User by this email not found in the LDAP server',
		statusCode: StatusCodes.NOT_FOUND
	},
};

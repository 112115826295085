import {Injectable} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpInterceptor,
	HttpClient,
	HttpErrorResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../error-handlers/http-error.handler';
import {HttpStatusEnum} from '../enums/http-status.enum';
import {AppMessageService} from './app-message.service';
import {COMMS_CALENDAR_ERROR} from '../constants/error-codes';

const uniqueErrorCodes = [
	COMMS_CALENDAR_ERROR._102.errorCode,
	COMMS_CALENDAR_ERROR._202.errorCode,
	COMMS_CALENDAR_ERROR._407.errorCode,
	COMMS_CALENDAR_ERROR._602.errorCode,
	COMMS_CALENDAR_ERROR._603.errorCode,
	COMMS_CALENDAR_ERROR._508.errorCode,
];

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private http: HttpClient,
		private appMessageService: AppMessageService,
		private errorHandler: HttpErrorHandler) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		return next.handle(request)
			.pipe(
				catchError((errorResponse: HttpErrorResponse) => {
					if (uniqueErrorCodes.includes(errorResponse?.error?.error?.errorCode)) {
						return throwError(errorResponse.error.error);
					}

					return this.errorHandler.handleError(errorResponse, HttpStatusEnum.GET_FAILURE_MESSAGE);
				})
			);
	}
}

import {
	AccordionModule,
	ButtonModule,
	CalendarModule,
	CheckboxModule,
	DialogModule,
	DragDropModule,
	DropdownModule,
	InputSwitchModule,
	InputTextModule,
	InputTextareaModule,
	MenuModule,
	MenubarModule,
	OverlayPanelModule,
	PanelMenuModule,
	ProgressSpinnerModule,
	RadioButtonModule,
	SelectButtonModule,
	SidebarModule,
	TableModule,
	TieredMenuModule,
	TooltipModule,
} from 'primeng';

export const primeNGModules = [
	AccordionModule,
	ButtonModule,
	CalendarModule,
	CheckboxModule,
	DialogModule,
	DragDropModule,
	DropdownModule,
	InputSwitchModule,
	InputTextModule,
	InputTextareaModule,
	MenuModule,
	MenubarModule,
	OverlayPanelModule,
	PanelMenuModule,
	ProgressSpinnerModule,
	RadioButtonModule,
	SelectButtonModule,
	SidebarModule,
	TableModule,
	TieredMenuModule,
	TieredMenuModule,
	TooltipModule,
]

import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import {Subscription} from 'rxjs';

import {OverlayPanel} from 'primeng';

import * as moment from 'moment';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

import {DatesRange} from '../../../../interfaces/calendar/dates-range';
import {DatesService} from '../../../../services/general/dates.service';
import {CalendarView} from '../../../../enums/calendar/calendar-view-enum';
import {DataRangeType} from '../../../../interfaces/calendar/date-range-type';
import {CalendarParams} from '../../../../interfaces/calendar/calendar-params';
import {AppHeaderService,} from '../../../../services/general/app-header.service';
import {CalendarRangeAction} from '../../../../enums/calendar/calendar-range-action-enum';
import {DateRangeLabel, DateRangeValue} from '../../../../enums/api/date-range-enum';
import {DATE_API_FORMAT, DATE_DEFAULT_FORMAT, DATE_MONTH_YEAR, DATE_YEAR_MONTH} from '../../../../constants/calendar.constants';
import {CalendarSearchItem} from '../../../../interfaces/calendar/calendar-search-item';

@Component({
	selector: 'app-calendar-switchers',
	templateUrl: './calendar-switchers.component.html',
	styleUrls: [
		'../app-header.component.scss',
		'./calendar-switchers.component.scss'
	],
	encapsulation: ViewEncapsulation.None,
})
export class CalendarSwitchersComponent implements OnInit, OnDestroy {
	@ViewChild('weekCalendar', {static: false}) public weekCalendarOverlay: OverlayPanel;
	@ViewChild('monthCalendar', {static: false}) public monthCalendarOverlay: OverlayPanel;
	@ViewChild('quarterCalendar', {static: false}) public quarterCalendarOverlay: OverlayPanel;

	public dateRangeTypes: DataRangeType[] = [
		{
			label: DateRangeLabel.Week,
			value: DateRangeValue.Week,
			icon: 'pi pi-check',
			styleClass: 'active',
			command: (event) => this.setSelectedDataRangeType(event.item),
		},
		{
			label: DateRangeLabel.Month,
			value: DateRangeValue.Month,
			icon: 'pi pi-check',
			styleClass: 'inactive',
			command: (event) => this.setSelectedDataRangeType(event.item),
		},
		{
			label: DateRangeLabel.Quarter,
			value: DateRangeValue.Quarter,
			icon: 'pi pi-check',
			styleClass: 'inactive',
			command: (event) => this.setSelectedDataRangeType(event.item),
		}
	];

	public selectedDateRangeTypeLabel: DateRangeLabel;
	public selectedDateRange: string = moment().format(DATE_MONTH_YEAR);
	public selectedTime: string = moment().format(DATE_DEFAULT_FORMAT);
	public todayButtonIsActive = false;
	public calendarParams: CalendarParams;
	public calendarView: CalendarView = CalendarView.Date;

	public minDate: Date;
	public maxDate: Date;
	public monthCalendarMinDate: Date;

	public todayButtonActiveSub: Subscription;
	public dateRangeValueSub: Subscription;
	public calendarParamsSub: Subscription;
	public searchItemSub: Subscription;

	constructor(
		private appHeaderService: AppHeaderService,
		private datesService: DatesService,
	) {
	}

	ngOnInit(): void {
		this.todayButtonActiveSub = this.appHeaderService.todayButtonActive().subscribe((value: boolean) => {
			this.todayButtonIsActive = value;
		});

		this.dateRangeValueSub = this.appHeaderService.getDateRangeValue().subscribe((value: string) => {
			this.selectedDateRange = value;
		});

		this.searchItemSub = this.appHeaderService.getCalendarSearchItem()
			.subscribe((item: CalendarSearchItem) => this.handleSearch(item));

		this.datesService.getDates().subscribe((dates: DatesRange) => {
			this.minDate = moment(dates.limitInThePast).toDate();
			this.maxDate = moment(dates.limitInTheFuture).toDate();
			this.monthCalendarMinDate = moment(dates.limitInThePast).startOf('month').toDate();
		});

		this.calendarParamsSub = this.appHeaderService.getCalendarParams().subscribe((data: CalendarParams) => {
			this.calendarParams = data;
			const activeRangeType = this.dateRangeTypes.find((item: DataRangeType) => item.value === data.period);
			this.selectedDateRangeTypeLabel = activeRangeType.label;
			this.setActiveRangeType(activeRangeType);
			this.selectedTime = this.setSelectedTimeRange(data);
		});
	}

	ngOnDestroy() {
		this.todayButtonActiveSub.unsubscribe();
		this.dateRangeValueSub.unsubscribe();
		this.calendarParamsSub.unsubscribe();
		this.searchItemSub.unsubscribe();
	}

	public get decreaseRangeDisabled(): boolean {
		return this.disableSwitchRangeButtons(this.minDate);
	}

	public get increaseRangeDisabled(): boolean {
		return this.disableSwitchRangeButtons(this.maxDate);
	}

	public handleOpenCalendar(event: Event, dateRangeValue: DateRangeValue): void {
		switch (dateRangeValue) {
			case DateRangeValue.Week:
				this.calendarView = CalendarView.Date;
				return this.weekCalendarOverlay.show(event);
			case DateRangeValue.Month:
				this.calendarView = CalendarView.Month;
				return this.monthCalendarOverlay.show(event);
			case DateRangeValue.Quarter:
				this.calendarView = CalendarView.Month;
				return this.quarterCalendarOverlay.show(event);
		}
	}

	private handleSearch(item: CalendarSearchItem) {
		const start = moment(this.selectedTime);
		const end = moment(item?.startDate);
		switch (this.calendarParams?.period) {
			case DateRangeValue.Week:
				start.add(7 - start.isoWeekday(), 'day');
				const difference = moment.duration(end.diff(start));
				const asWeeks = difference.asWeeks() > 0 ? Math.ceil(difference.asWeeks()) : Math.trunc(difference.asWeeks());
				return this.handleRangeChange(asWeeks, item);
			case DateRangeValue.Month:
				const asMonths = (end.year() - start.year()) * 12 + (end.month() - start.month());
				return this.handleRangeChange(asMonths, item);
			case DateRangeValue.Quarter:
				const asQuarters = (end.year() - start.year()) * 4 + (end.quarter() - start.quarter());
				return this.handleRangeChange(asQuarters, item);
		}
	}

	private handleRangeChange(count: number, item: CalendarSearchItem): void {
		this.calendarParams.range = count;
		this.selectedTime = this.setSelectedTime(count);
		this.calendarParams.selectedQuarterTime = this.selectedTime;
		this.calendarParams.searchValue = item?.title;
		this.appHeaderService.setCalendarParams(this.calendarParams);
	}

	public handleRangeDecrease(): void {
		this.calendarParams.range = --this.calendarParams.range;
		this.selectedTime = this.setSelectedTime(CalendarRangeAction.Decrease);
		this.calendarParams.selectedQuarterTime = this.selectedTime;
		this.appHeaderService.setCalendarParams(this.calendarParams);
	}

	public handleRangeIncrease(): void {
		this.calendarParams.range = ++this.calendarParams.range;
		this.selectedTime = this.setSelectedTime(CalendarRangeAction.Increase);
		this.calendarParams.selectedQuarterTime = this.selectedTime;
		this.appHeaderService.setCalendarParams(this.calendarParams);
	}

	public handleTodayClick(): void {
		if (!this.todayButtonIsActive) {
			return;
		}

		this.clearCalendarParams();
		this.selectedTime = moment().format(DATE_DEFAULT_FORMAT);
		this.appHeaderService.setCalendarParams(this.calendarParams);
	}

	public handleWeekCalendarSelect(event: Date): void {
		this.weekCalendarOverlay.hide();

		this.selectedTime = moment(event).format(DATE_DEFAULT_FORMAT);
		this.calendarParams.selectedDate = moment(event).format(DATE_API_FORMAT);
		this.calendarParams.yearMonth = '';
		this.calendarParams.yearQuarter = '';
		this.calendarParams.range = 0;
		this.appHeaderService.setCalendarParams(this.calendarParams);
	}

	public handleMonthCalendarSelect(event: Date): void {
		this.monthCalendarOverlay.hide();

		this.selectedTime = moment(event).startOf('month').set({date: moment().date()}).format(DATE_DEFAULT_FORMAT);
		this.calendarParams.yearMonth = moment(event).format(DATE_YEAR_MONTH);
		this.calendarParams.selectedDate = '';
		this.calendarParams.yearQuarter = '';
		this.calendarParams.range = 0;
		this.appHeaderService.setCalendarParams(this.calendarParams);
	}

	public handleQuarterCalendarSelect(event: Date): void {
		this.quarterCalendarOverlay.hide();

		this.selectedTime = moment(event).format(DATE_DEFAULT_FORMAT);
		this.calendarParams.yearQuarter = `${moment(event).year()}-Q${moment(event).quarter()}`;
		this.calendarParams.selectedQuarterTime = this.selectedTime;
		this.calendarParams.selectedDate = '';
		this.calendarParams.yearMonth = '';
		this.calendarParams.range = 0;
		this.appHeaderService.setCalendarParams(this.calendarParams);
	}

	private disableSwitchRangeButtons(diffDate: Date): boolean {
		switch (this.calendarParams.period) {
			case DateRangeValue.Week:
				return this.checkIsDisableWeekRange(this.calendarParams.selectedDate, diffDate, 'weeks');
			case DateRangeValue.Month:
				return this.checkIsDisableRange(this.selectedTime, diffDate, 'months');
			case DateRangeValue.Quarter:
				return this.checkIsDisableRange(this.selectedTime, diffDate, 'quarters');
		}
	}

	private checkIsDisableRange(currentDate: string, diffDate: Date, unit: DurationConstructor): boolean {
		return !moment(currentDate || this.calendarParams.currentDate)
			.diff(diffDate, unit);
	}

	private checkIsDisableWeekRange(currentDate: string, diffDate: Date, unit: DurationConstructor): boolean {
		return !moment(currentDate || this.calendarParams.currentDate)
			.add(this.calendarParams.range, unit)
			.diff(diffDate, unit);
	}

	private setSelectedDataRangeType(item: DataRangeType): void {
		this.selectedTime = moment().format(DATE_DEFAULT_FORMAT);

		if (this.selectedDateRangeTypeLabel !== item.label) {
			this.calendarParams.period = item.value;
			this.calendarParams.range = 0;
			this.appHeaderService.setCalendarParams(this.calendarParams);
		}

		this.selectedDateRangeTypeLabel = item.label;
		this.setActiveRangeType(item);
	}

	private setActiveRangeType(item: DataRangeType): void {
		this.dateRangeTypes.forEach((dataRangeType: DataRangeType) => {
			dataRangeType.styleClass = 'inactive';
		});
		item.styleClass = this.selectedDateRangeTypeLabel === item.label ? 'active' : 'inactive';
	}

	private setSelectedTime(count: number): string {
		switch (this.calendarParams.period) {
			case DateRangeValue.Week:
				return this.selectedTime;
			case DateRangeValue.Month:
				return moment(this.selectedTime).add(count, 'months').format(DATE_DEFAULT_FORMAT)
			case DateRangeValue.Quarter:
				return moment(this.selectedTime).add(count, 'quarters').format(DATE_DEFAULT_FORMAT)
		}
	}

	private setSelectedTimeRange(calendarParams: CalendarParams): string {
		switch (this.calendarParams.period) {
			case DateRangeValue.Week:
				return moment(calendarParams.selectedDate || this.calendarParams.currentDate).format(DATE_DEFAULT_FORMAT);
			case DateRangeValue.Month:
				return moment(calendarParams.yearMonth || this.calendarParams.currentDate)
					.add(calendarParams.range, 'months')
					.startOf('month').format(DATE_DEFAULT_FORMAT);
			case DateRangeValue.Quarter:
				return moment(calendarParams.selectedQuarterTime || this.selectedTime).format(DATE_DEFAULT_FORMAT);
		}
	}

	private clearCalendarParams(): void {
		this.calendarParams.currentDate = moment().format(DATE_API_FORMAT);
		this.calendarParams.range = 0;
		this.calendarParams.selectedDate = '';
		this.calendarParams.yearMonth = '';
		this.calendarParams.yearQuarter = '';
		this.calendarParams.selectedQuarterTime = '';
	}
}

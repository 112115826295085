import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

import { FilterCategory } from 'src/app/interfaces/filter-category';

@Component({
	selector: 'app-filter-spokesperson',
	templateUrl: './filter-spokesperson.component.html',
	styleUrls: ['./filter-spokesperson.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class FilterSpokespersonComponent {
	@Input() public selectedSpokesPeople: FilterCategory[] = [];
	@Input() public spokesPeople: FilterCategory[];

	@Output() public selectSpokesPeople: EventEmitter<Array<number | string>> = new EventEmitter();

	public handleModelChange(event: Array<number | string>): void {
		this.selectSpokesPeople.emit(event)
	}
}

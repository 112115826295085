import {Injectable, ErrorHandler} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Message,
	MessageService
} from 'primeng/api';
import {EMPTY} from 'rxjs';
import {StatusCodes} from 'http-status-codes';
import {AuthSessionService} from '../services/auth-session.service';
import {Router} from '@angular/router';
import {COMMS_CALENDAR_ERROR} from '../constants/error-codes';
import {GuestPath} from '../enums/guest-path-enum';

/** Error handler class to assist with cleaning up repeated and consistent HTTP error handling */
@Injectable({
	providedIn: 'root'
})
export class HttpErrorHandler implements ErrorHandler {

	constructor(
		private readonly messageService: MessageService,
		private router: Router,
		private authSessionService: AuthSessionService) {
	}

	/**
	 * takes in the errorResponse and errorMessage and uses them to handle the error appropriately.
	 * @param errorResponse the HttpErrorResponse object
	 * @param errorMessage optionally, the Message object for the error
	 * @returns an empty Observable
	 */
	handleError(errorResponse: HttpErrorResponse, errorMessage?: Message) {
		if (errorResponse.error instanceof ErrorEvent) {
			console.error('An error occurred:', errorResponse.error.message);
		} else {
			console.error(
				`Backend returned code ${errorResponse.status}, ` +
				`body was: ${errorResponse.error}`);
		}

		console.error('An Error Has Occurred:', errorResponse);

		const isForbidden = errorResponse.status === StatusCodes.FORBIDDEN
							&& errorResponse.error?.error?.errorCode === COMMS_CALENDAR_ERROR._001.errorCode;

		if (errorMessage && !isForbidden) {
			this.messageService.add(errorMessage);
		}

		if (errorResponse.status === StatusCodes.UNAUTHORIZED) {
			if (errorResponse.error?.errorCode === COMMS_CALENDAR_ERROR._001.errorCode) {
				this.router.navigate([GuestPath.Main]);
			} else {
				this.authSessionService.ADFSLogin();
			}

			return EMPTY;
		}

		if (isForbidden) {
			this.router.navigate([GuestPath.Main]);

			return EMPTY;
		}

		return EMPTY;
	}
}

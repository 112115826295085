import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
	selector: 'app-event-description',
	templateUrl: './event-description.component.html',
	styleUrls: ['../../detail-form.scss', './event-description.component.scss']
})
export class EventDescriptionComponent implements OnInit {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() disabled: boolean;

	public readonly maxLength = 280;

	public tempDescriptionForm: UntypedFormGroup;
	public displayDescriptionSidebar = false;

	get eventDescriptionControl(): AbstractControl {
		return this.parentForm?.get(this.controlName);
	}

	get tempDescriptionControl(): AbstractControl {
		return this.tempDescriptionForm?.get('description');
	}

	get isDisableActions(): boolean {
		return this.tempDescriptionForm.invalid || this.tempDescriptionForm.pristine;
	}

	constructor(public formBuilder: UntypedFormBuilder) {
	}

	ngOnInit(): void {
		this.tempDescriptionForm = this.formBuilder.group({
			description: [''],
		});
	}

	public onChangeVisible(isShow: boolean) {
		if (!isShow) {
			this.clearForm();
		}
	}

	public setDescription() {
		this.eventDescriptionControl.setValue(this.tempDescriptionControl.value);
		this.parentForm.markAsDirty();

		this.displayDescriptionSidebar = false;
		this.clearForm();
	}

	public onDescriptionClick() {
		this.tempDescriptionControl.setValue(this.eventDescriptionControl.value);
		this.displayDescriptionSidebar = true;
	}

	private clearForm() {
		this.tempDescriptionForm.reset();
	}
}

import {Component, EventEmitter, Input, Output} from '@angular/core';

import {LazyLoadEvent} from 'primeng';

import {User} from 'src/app/interfaces/users/user';
import {ToolOptions} from 'src/app/interfaces/category/category-list-tool';
import {TableHeader} from 'src/app/interfaces/table-header';
import {SpokesPerson} from 'src/app/interfaces/spokespeople/spokespeople';
import {SelectedOrder} from 'src/app/interfaces/selected-order';
import {OrderDirection} from 'src/app/enums/order-direction-enum';
import {TableColumnValue} from 'src/app/enums/table-column-enum';
import {ToolEvent} from 'src/app/enums/tool-event-enum';
import {SpokesPersonEvent} from 'src/app/interfaces/spokespeople/spokesperson-event';
import {UserEvent} from '../../../interfaces/users/user-event';

@Component({
	selector: 'app-management-table',
	templateUrl: './management-table.component.html',
	styleUrls: ['./management-table.component.scss']
})
export class ManagementTableComponent {
	public readonly ORDER_DIRECTION = OrderDirection;
	public readonly TABLE_COLUMN_VALUE = TableColumnValue;

	public defaultAvatarSize = '30px';
	public toolEvent = ToolEvent;
	public isInit = true;
	public toolOptions: ToolOptions = {
		showDown: false,
		showUp: false,
	};

	@Input() public items: User[] | SpokesPerson[] = [];
	@Input() public tableHeaders: TableHeader[] = [];
	@Input() public selectedOrder: SelectedOrder = {} as SelectedOrder;
	@Input() public showAvatar: boolean;

	@Output() public updateOrder: EventEmitter<SelectedOrder> = new EventEmitter();
	@Output() public rowDataClickEvent: EventEmitter<SpokesPersonEvent | UserEvent> = new EventEmitter();

	public sort(event: LazyLoadEvent, isInit: boolean): void {
		if (isInit) {
			this.isInit = false;
			return;
		}

		this.selectedOrder = {
			attr: event.sortField as TableColumnValue,
			direction: event.sortOrder
		};

		this.updateOrder.emit(this.selectedOrder);
	}

	public toolClickEvent(toolEvent: ToolEvent, item: SpokesPerson | User) {
		this.rowDataClickEvent.emit({toolEvent, item});
	}
}

import {AddTitleComponent} from './add-title/add-title.component';
import {AppAvatarComponent} from './app-avatar/app-avatar.component';
import {AppCalendarComponent} from './app-calendar/app-calendar.component';
import {AppCalendarPeriodComponent} from './app-calendar/app-calendar-period/app-calendar-period';
import {AppDropdownComponent} from './app-dropdown/app-dropdown.component';
import {AppHeaderComponent} from './app-header/app-header.component';
import {AppInputComponent} from './app-input/app-input.component';
import {AppOverlayLoaderComponent} from './app-overlay-loader/app-overlay-loader.component';
import {AppRadiogroupComponent} from './app-radiogroup/app-radiogroup.component';
import {AppSelectComponent} from './app-select/app-select.component';
import {AppSelectPreviewComponent} from './app-select/app-select-preview/app-select-preview.component';
import {AppSidebarComponent} from './app-sidebars/app-sidebar/app-sidebar.component';
import {AppTextareaComponent} from './app-textarea/app-textarea.component';
import {CalendarSwitchersComponent} from './app-header/calendar-switchers/calendar-switchers.component';
import {CategorySelectGroupComponent} from './category-select-group/category-select-group.component';
import {DeleteSidebarComponent} from './app-sidebars/delete-sidebar/delete-sidebar.component';
import {DetailSidebarComponent} from './app-sidebars/detail-sidebar/detail-sidebar.component';
import {FormSidebarComponent} from './app-sidebars/form-sidebar/form-sidebar.component';
import {FormatHeaderDatePipe} from 'src/app/pipes/format-header-date.pipe';
import {NoSearchContentComponent} from './no-search-content/no-search-content.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {QuarterCalendarComponent} from './app-calendar/quarter-calendar/quarter-calendar.component';
import {QuickLinksListComponent} from './app-header/quick-links-list/quick-links-list.component';
import {UnsavedChangesSidebarComponent} from './app-sidebars/unsaved-changes-sidebar/unsaved-changes-sidebar.component';

export const components = [
	AddTitleComponent,
	AppAvatarComponent,
	AppCalendarComponent,
	AppCalendarPeriodComponent,
	AppDropdownComponent,
	AppHeaderComponent,
	AppInputComponent,
	AppOverlayLoaderComponent,
	AppRadiogroupComponent,
	AppSelectComponent,
	AppSelectPreviewComponent,
	AppSidebarComponent,
	AppTextareaComponent,
	CalendarSwitchersComponent,
	CategorySelectGroupComponent,
	DeleteSidebarComponent,
	DetailSidebarComponent,
	FormSidebarComponent,
	FormatHeaderDatePipe,
	NoSearchContentComponent,
	NotFoundComponent,
	QuarterCalendarComponent,
	QuickLinksListComponent,
	UnsavedChangesSidebarComponent,
]

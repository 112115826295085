import {Component, OnDestroy, OnInit} from '@angular/core';

import {debounceTime} from 'rxjs/operators';
import {forkJoin, Observable, Subscription} from 'rxjs';

import {Region} from 'src/app/interfaces/region';
import {ToolEvent} from 'src/app/enums/tool-event-enum';
import {DataStatus} from 'src/app/enums/data-status-enum';
import {TableHeader} from 'src/app/interfaces/table-header';
import {SidebarMode} from 'src/app/enums/sidebar-mode-enum';
import {SpokesPerson} from 'src/app/interfaces/spokespeople/spokespeople';
import {CategoryName} from 'src/app/enums/api/сategory-name-enum';
import {SelectedOrder} from 'src/app/interfaces/selected-order';
import {OrderDirection} from 'src/app/enums/order-direction-enum';
import {CategoryService} from 'src/app/services/management/category.service';
import {ManagementService} from 'src/app/services/management.service';
import {SpokesPersonEvent} from 'src/app/interfaces/spokespeople/spokesperson-event';
import {SpokesPeopleService} from 'src/app/services/management/spokesperson.service';
import {UnsavedChangesService} from 'src/app/services/unsaved-changes.service';
import {TableColumnTitle, TableColumnValue} from 'src/app/enums/table-column-enum';
import {ErrorApi} from '../../../interfaces/api/error-api';
import {SpokepeopleListCommon} from '../../../interfaces/users/spokepeople-list-common';

@Component({
	selector: 'app-spokespeople',
	templateUrl: './spokespeople.component.html',
	styleUrls: ['./spokespeople.component.scss']
})
export class SpokespeopleComponent implements OnInit, OnDestroy {
	public spokesPeople: SpokesPerson[] = [];
	public regions: Region[] = [];

	public selectedOrder: SelectedOrder = {
		attr: TableColumnValue.Name,
		direction: OrderDirection.Asc,
	};
	public userTableHeaders: TableHeader[] = [
		{value: TableColumnValue.Name, title: TableColumnTitle.Name, isSortable: true},
		{value: TableColumnValue.Position, title: TableColumnTitle.Role, isSortable: true},
		{value: TableColumnValue.Region, title: TableColumnTitle.Region, isSortable: true},
	];

	public readonly sidebarMode = SidebarMode;
	public readonly sidebarStatus = DataStatus;

	public showLoader = true;

	public currentSpokesPerson: SpokesPerson;
	public showSpokesPersonDeleteSidebar: boolean;
	public currentDataStatus: DataStatus = DataStatus.Loading;
	public spokesPeopleSideMode: SidebarMode = SidebarMode.Closed;

	public addSpokesPeopleSub: Subscription;
	public closeAllSub: Subscription;
	public searchSub: Subscription;

	public isUnsavedData: boolean;
	public searchValue: string;
	public spokespeopleError: ErrorApi;

	public pageNumber = 1;
	private rowsAmount = 12;
	public totalPagesAmount: number;

	public showPaginator: boolean;

	constructor(
		private spokesPeopleService: SpokesPeopleService,
		private categoryService: CategoryService,
		private unsavedChangesService: UnsavedChangesService,
		private managementService: ManagementService) {
	}

	public get sideBarTitle(): string {
		return this.spokesPeopleSideMode === SidebarMode.Add ? `Add New Spokesperson` : null;
	}

	public get showSpokesPeopleSidebar(): boolean {
		return this.spokesPeopleSideMode !== SidebarMode.Closed;
	}

	ngOnInit(): void {
		this.searchSub = this.managementService.searchValue$
			.pipe(debounceTime(300))
			.subscribe((value: string) => this.handleSearch(value));
		this.closeAllSidebars();
		this.addSpokesPeopleSub = this.managementService.categoryItemEvent$.subscribe(() => this.addSpokeperson());
	}

	ngOnDestroy(): void {
		this.addSpokesPeopleSub.unsubscribe();
		this.closeAllSub.unsubscribe();
	}

	private closeAllSidebars() {
		this.closeAllSub = this.unsavedChangesService.closeAll().subscribe(() => {
			this.spokesPeopleSideMode = SidebarMode.Closed;
			this.clearSideData();
		});
	}

	private handleSearch(searchValue) {
		this.searchValue = searchValue;
		this.getSpokesPeople();
	}

	private getSpokesPeople(): void {
		this.spokesPeopleService.getSpokesPeople(this.selectedOrder, this.pageNumber, this.rowsAmount, this.searchValue)
			.subscribe(res => this.updateSpokesPeopleList(res));
	}

	private updateSpokesPeopleList(data: SpokepeopleListCommon) {
		this.spokesPeople = data.result;
		this.totalPagesAmount = Math.ceil(data.totalRecords / this.rowsAmount);
		this.showPaginator = data.totalRecords > this.rowsAmount;
		this.showLoader = false;
	}

	public handleUpdateOrder(selectedOrder: SelectedOrder): void {
		this.selectedOrder = selectedOrder;

		this.getSpokesPeople();
	}

	public handleUpdatePageNumber(pageNumber: number) {
		this.pageNumber = pageNumber;

		this.getSpokesPeople();
	}

	public addSpokeperson(regionId?: number) {
		this.spokesPeopleSideMode = SidebarMode.Add;
		this.currentDataStatus = DataStatus.Loading;

		this.categoryService.getCategories(CategoryName.Region)
			.subscribe((data: Region[]) => {
				this.regions = data;
				this.currentSpokesPerson = {regionId} as SpokesPerson;
				this.currentDataStatus = DataStatus.Ready;
			});
	}

	public handleToolsClickEvent(event: SpokesPersonEvent) {
		const {id} = event.item;
		switch (event.toolEvent) {
			case ToolEvent.Delete:
				this.currentSpokesPerson = event.item;
				this.openSpokesPersonDeleteSidebar();
				break;
			case ToolEvent.Edit:
				this.openSpokesPeopleSideBar(id);
				break;
		}
	}

	private openSpokesPeopleSideBar(id: number) {
		this.spokesPeopleSideMode = SidebarMode.Edit;
		this.currentDataStatus = DataStatus.Loading;

		forkJoin([
			this.spokesPeopleService.getSpokesPersonById(id),
			this.categoryService.getCategories(CategoryName.Region)
		])
			.subscribe((data: [SpokesPerson, Region[]]) => {
				const [spokesPerson, regions] = data;

				this.currentSpokesPerson = spokesPerson;
				this.regions = regions;
				this.currentDataStatus = DataStatus.Ready;
			});
	}

	public closeSidebar(isUnsavedData?: boolean): void {
		if (isUnsavedData) {
			return;
		}

		this.spokesPeopleSideMode = SidebarMode.Closed;
		this.clearSideData();
	}

	public handleSaveClick(newSpokesPerson: SpokesPerson) {
		this.currentDataStatus = DataStatus.Saving;

		this.saveSpokesPerson(newSpokesPerson).subscribe(() => {
				this.getSpokesPeople();
				this.closeSidebar();
			},
			(error) => {
				this.handleSpokespeopleError(error);
			});
	}

	public openSpokesPersonDeleteSidebar() {
		this.showSpokesPersonDeleteSidebar = true;
		this.currentDataStatus = DataStatus.Loading;
	}

	public closeSpokesPersonDeleteSidebar() {
		this.showSpokesPersonDeleteSidebar = false;
		this.currentDataStatus = DataStatus.Ready;
	}

	public deleteSpokesPerson(id: number) {
		this.currentDataStatus = DataStatus.Saving;
		this.showLoader = true;
		this.spokesPeopleService.deleteSpokesPerson(id)
			.subscribe(() => {
				this.getSpokesPeople();
				this.spokesPeopleSideMode = SidebarMode.Closed;
				this.showSpokesPersonDeleteSidebar = false;
				this.clearSideData();
			});
	}

	private saveSpokesPerson(newSpokesPerson: SpokesPerson): Observable<SpokesPerson> {
		this.showLoader = true;

		if (newSpokesPerson.id) {
			const body: SpokesPerson = {
				firstName: newSpokesPerson.firstName,
				lastName: newSpokesPerson.lastName,
				position: newSpokesPerson.position,
				regionId: newSpokesPerson.regionId,
				avatar: newSpokesPerson.avatar,
			};

			return this.updateSpokesPerson(newSpokesPerson.id, body);
		} else {
			return this.createSpokesPerson(newSpokesPerson);
		}
	}

	private updateSpokesPerson(id: number, body: SpokesPerson): Observable<SpokesPerson> {
		return this.spokesPeopleService.updateSpokesPerson(id, body);
	}

	private createSpokesPerson(newSpokesPerson: SpokesPerson): Observable<SpokesPerson> {
		return this.spokesPeopleService.addSpokesPerson(newSpokesPerson);
	}

	private clearSideData(): void {
		this.currentDataStatus = DataStatus.Loading;
		this.currentSpokesPerson = null;
	}

	private handleSpokespeopleError(error: ErrorApi) {
		this.showLoader = false;
		this.currentDataStatus = DataStatus.Ready;
		this.spokespeopleError = error;
	}

	public resetSpokespeopleError() {
		this.spokespeopleError = null;
	}
}

import {Injectable} from '@angular/core';
import {SelectItem} from 'primeng';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import {FilterEvent} from 'src/app/enums/event-filter-enum';
import {FilterCategory, SelectFilterCategory} from 'src/app/interfaces/filter-category';
import {CalendarEventPriority} from 'src/app/enums/calendar/calendar-event-priority-enum';
import {FilterCategoryLabel} from 'src/app/enums/filter/filter-category-enum';
import {FilterAction} from 'src/app/enums/filter/filter-action-enum';

export type GroupedCategoryByType = {
	[P in string]: FilterCategory;
}

export interface SelectedFilters {
	filterCategories: SelectFilterCategory[],
	selectedFiltersAmount?: number,
}

@Injectable({
	providedIn: 'root'
})
export class FilterService {
	public defaultFilterCategories: SelectFilterCategory[] = [
		{
			label: FilterCategoryLabel.Priority,
			items: [],
			selected: false,
		},
		{
			label: FilterCategoryLabel.Spokesperson,
			items: [],
			selected: false,
		},
		{
			label: FilterCategoryLabel.Content,
			items: [],
			selected: false,
		},
		{
			label: FilterCategoryLabel.Channels,
			items: [],
			selected: false,
		},
		{
			label: FilterCategoryLabel.Region,
			items: [],
			selected: false,
		},
		{
			label: FilterCategoryLabel.Audience,
			items: [],
			selected: false,
		}
	];

	private filterEvent$: BehaviorSubject<FilterEvent> = new BehaviorSubject(FilterEvent.Close);
	private filterAction$: BehaviorSubject<FilterAction> = new BehaviorSubject(null);
	private selectedFilters$: BehaviorSubject<SelectedFilters> = new BehaviorSubject({ filterCategories: this.defaultFilterCategories });

	public get defaultsEventPriority(): Observable<SelectItem[]> {
		return of([
			{value: CalendarEventPriority.High, label: CalendarEventPriority.High},
			{value: CalendarEventPriority.Medium, label: CalendarEventPriority.Medium},
		]);
	}

	public setFilterEvent(value: FilterEvent) {
		this.filterEvent$.next(value);
	}

	public getFilterEvent(): BehaviorSubject<FilterEvent> {
		return this.filterEvent$;
	}

	public setSelectedFilters(value: SelectedFilters) {
		this.selectedFilters$.next(value);
	}

	public getSelectedFilters(): BehaviorSubject<SelectedFilters> {
		return this.selectedFilters$;
	}

	public setFilterAction(value: FilterAction): void {
		this.filterAction$.next(value);

		if (value === FilterAction.ClearAll) {
			this.clearAllFilters();
		}
	}

	public getFilterAction(): Subject<FilterAction> {
		return this.filterAction$;
	}

	public groupCategoryItemsByType(items: FilterCategory[]): GroupedCategoryByType {
		return [...items].reduce((acc, item) => {
			acc[item.type.title] = acc[item.type.title] || [];
			acc[item.type.title].push(item);

			return acc;
		}, {})
	}

	private clearAllFilters(): void {
		this.setSelectedFilters({ filterCategories: this.defaultFilterCategories, selectedFiltersAmount: 0 });
	}

	public getFiltersApiQuery(filterCategories: SelectFilterCategory[]): string {
		let filter = `?`

		filterCategories.forEach(category => {
			const {items, label} = category;

			if (!items.length) {
				return;
			}

			switch (label) {
				case (FilterCategoryLabel.Priority): return filter += `priorities=${items.join(',').toUpperCase()}&`;
				case (FilterCategoryLabel.Spokesperson): return filter += `spokespersonIds=${this.idsToString(items)}&`;
				case (FilterCategoryLabel.Content): return filter += `contentIds=${this.idsToString(items)}&`;
				case (FilterCategoryLabel.Channels): return filter += `channelIds=${this.idsToString(items)}&`;
				case (FilterCategoryLabel.Region): return filter += `regionIds=${this.idsToString(items)}&`;
				case (FilterCategoryLabel.Audience): return filter += `audienceIds=${this.idsToString(items)}&`;
			}

		})

		return filter;
	}

	private idsToString(items: any): string {
		return items.map(item => item).join(',');
	}
}

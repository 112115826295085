import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {SidebarMode} from '../../../enums/sidebar-mode-enum';
import {DataStatus} from '../../../enums/data-status-enum';
import {Keyword} from '../../../interfaces/keywords/keyword';
import {KeywordsService} from '../../../services/management/keywords.service';
import {ManagementService} from '../../../services/management.service';
import {ToolEvent} from '../../../enums/tool-event-enum';
import {KeywordListEvent} from '../../../interfaces/keywords/keyword-list-event';
import {UnsavedChangesService} from 'src/app/services/unsaved-changes.service';
import {ErrorApi} from '../../../interfaces/api/error-api';

@Component({
	selector: 'quick-search-keywords',
	templateUrl: './quick-search-keywords.component.html',
	styleUrls: ['./quick-search-keywords.component.scss']
})
export class QuickSearchKeywordsComponent implements OnInit, OnDestroy {
	public readonly sidebarMode = SidebarMode;
	public readonly sidebarStatus = DataStatus;

	public keywords: Keyword[];
	public currentKeyword: Keyword;
	private draggedItem: Keyword;
	public showKeywordDeleteSidebar: boolean;
	public currentDataStatus: DataStatus = DataStatus.Loading;
	public keywordSideMode: SidebarMode = SidebarMode.Closed;
	public showLoader = true;
	public keywordError: ErrorApi;

	private keywordsSubscription: Subscription;
	private addKeywordItemSub: Subscription;
	public closeAllSub: Subscription;

	get keywordSideTitle(): string {
		return this.keywordSideMode === SidebarMode.Edit ? ('Edit Quick Search Keyword: ' + (this.currentKeyword?.text || '')) : 'Add Quick Search Keyword';
	}

	get showKeywordSidebar(): boolean {
		return this.keywordSideMode !== SidebarMode.Closed;
	}

	constructor(
		private keywordsService: KeywordsService,
		private unsavedChangesService: UnsavedChangesService,
		private managementService: ManagementService) {
	}

	ngOnInit() {
		this.addKeywordItemSub = this.managementService.categoryItemEvent$.subscribe(() => this.addKeyword());
		this.getKeywords();
		this.closeAllSidebars();
	}

	ngOnDestroy() {
		this.keywordsSubscription.unsubscribe();
		this.addKeywordItemSub.unsubscribe();
		this.closeAllSub.unsubscribe();
	}

	setDraggedItem(item: Keyword) {
		this.draggedItem = item;
	}

	onCategoryItemDrop(droppedItem: Keyword) {
		this.showLoader = true;
		const newItem = {...this.draggedItem, position: droppedItem.position};
		this.updateKeyword(newItem);
	}

	private closeAllSidebars() {
		this.closeAllSub = this.unsavedChangesService.closeAll().subscribe(() => {
			this.keywordSideMode = SidebarMode.Closed;
			this.clearSideData();
		});
	}

	public addKeyword() {
		this.keywordSideMode = SidebarMode.Add;
		this.currentDataStatus = DataStatus.Ready;
		this.currentKeyword = {} as Keyword;
	}

	private getKeywords() {
		this.showLoader = true;

		this.keywordsSubscription = this.keywordsService.getKeywords()
			.subscribe(res => {
				this.keywords = res;
				this.showLoader = false;
				this.managementService.showAddKeywordButton(res.length < 10);
			});
	}

	public closeKeywordSidebar(isUnsavedData?: boolean) {
		if (isUnsavedData) {
			return;
		}

		this.keywordSideMode = SidebarMode.Closed;
		this.clearSideData();
	}

	private clearSideData() {
		this.currentDataStatus = DataStatus.Loading;
		this.currentKeyword = null;
	}

	public saveCategoryType(newKeyword: Keyword) {
		this.currentDataStatus = DataStatus.Saving;

		if (newKeyword.id) {
			this.updateKeyword(newKeyword);
		} else {
			this.saveKeyword(newKeyword.text);
		}
	}

	public typeToolsClickEvent(keywordEvent: KeywordListEvent) {
		const {id} = keywordEvent.keyword;
		switch (keywordEvent.toolEvent) {
			case ToolEvent.Delete:
				this.currentKeyword = keywordEvent.keyword;
				this.deleteKeyword(id);
				break;
			case ToolEvent.Edit:
				this.openKeyword(id);
				break;
		}
	}

	public deleteKeyword(keywordId: number) {
		this.currentDataStatus = DataStatus.Saving;
		this.keywordsService.deleteKeyword(keywordId)
			.subscribe(() => {
				this.getKeywords();
				this.keywordSideMode = SidebarMode.Closed;
				this.clearSideData();
			});
	}

	private openKeyword(typeId: number) {
		this.keywordSideMode = SidebarMode.Edit;
		this.currentDataStatus = DataStatus.Loading;
		this.keywordsService.getKeywoardById(typeId)
			.subscribe((res: Keyword) => {
				this.currentKeyword = res;
				this.currentDataStatus = DataStatus.Ready;
			});
	}

	private saveKeyword(text: string) {
		this.keywordsService.addKeyword(text)
			.subscribe(() => {
					this.getKeywords();
					this.keywordSideMode = SidebarMode.Closed;
				},
				(error) => {
					this.handleKeywordError(error);
				});
	}

	private updateKeyword(keyword: Keyword) {
		this.keywordsService.updateKeyword(keyword)
			.subscribe(() => {
					this.getKeywords();
					this.keywordSideMode = SidebarMode.Closed;
				},
				(error) => {
					this.handleKeywordError(error);
				});
	}

	private handleKeywordError(error: ErrorApi) {
		this.currentDataStatus = DataStatus.Ready;
		this.keywordError = error;
	}

	public resetKeywordError() {
		this.keywordError = null;
	}
}

import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {SelectItem} from 'primeng';
import {UntypedFormGroup} from '@angular/forms';
import {UserType} from '../../../enums/api/user-type-enum';

@Component({
	selector: 'app-user-types',
	templateUrl: './user-types.component.html',
	styleUrls: ['./user-types.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UserTypesComponent {
	@Input() userTypes: SelectItem[];
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() disabled: boolean;

	@Output() changeType = new EventEmitter<UserType>();

	public onChangeType(model: { originalEvent: PointerEvent; value: UserType }) {
		this.changeType.emit(model.value);
	}
}

import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {UnsavedChangesService} from 'src/app/services/unsaved-changes.service';

@Component({
	selector: 'app-unsaved-changes-sidebar',
	templateUrl: './unsaved-changes-sidebar.component.html',
	styleUrls: [
		'../app-sidebar/app-sidebar.component.scss',
		'../form-sidebar/form-sidebar.component.scss',
		'./unsaved-changes-sidebar.component.scss'
	]
})
export class UnsavedChangesSidebarComponent implements OnInit {
	public show$: Subject<boolean>;

	constructor(private unsavedChangesService: UnsavedChangesService) {}

	ngOnInit() {
		this.show$ = this.unsavedChangesService.showUnsavedChangesSidebar();
	}

	public backEvent(): void {
		this.unsavedChangesService.setIsUnsavedChanges(false);
	}

	public discardEvent(): void {
		this.unsavedChangesService.setIsUnsavedChanges(false);
		this.unsavedChangesService.setCloseAll(true);
	}
}

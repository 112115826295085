import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';

@Injectable({
	providedIn: 'root'
})
export class AppMessageService {
	constructor(private messageService: MessageService) {
	}

	/**
	 * handles a success message from the server
	 *
	 * @param message is the message received from the http call
	 */
	public handleSuccess(message: object) {
		this.messageService.add(message);
	}
}

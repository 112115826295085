import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UnsavedChangesService} from 'src/app/services/unsaved-changes.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './app-sidebar.component.html',
	styleUrls: ['./app-sidebar.component.scss']
})
export class AppSidebarComponent {
	@Input() public title: string;
	@Input() public visible: boolean;
	@Input() public isUnsavedData: boolean;
	@Input() public showCloseIcon = true;

	@Output() public closeSidebar = new EventEmitter();

	constructor(
		private unsavedChangesService: UnsavedChangesService
	) {}

	public onChangeVisible(showed: boolean) {
		if (!showed) {
			this.unsavedChangesService.cancelClick$.next();
			this.closeSidebar.emit();
		}
	}
}

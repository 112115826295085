import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToolOptions} from '../../../interfaces/category/category-list-tool';
import {ToolEvent} from '../../../enums/tool-event-enum';

const defaultOptions: ToolOptions = {
	showDown: true,
	showUp: true,
	showEdit: true,
	showTrash: true,
};

@Component({
	selector: 'app-category-list-tool',
	templateUrl: './category-list-tool.component.html',
	styleUrls: ['./category-list-tool.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryListToolComponent implements OnInit {
	@Input() toolOptions: ToolOptions;
	@Input() itemId: number;
	@Output() toolClick: EventEmitter<ToolEvent> = new EventEmitter();

	readonly toolEvent = ToolEvent;
	options: ToolOptions;

	ngOnInit() {
		this.options = {
			...defaultOptions,
			...this.toolOptions
		};
	}

	public toolClickEvent(event, tool: ToolEvent) {
		this.toolClick.emit(tool);
		event.stopPropagation();
	}
}

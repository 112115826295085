import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {User} from '../interfaces/users/user';
import {UserRole} from '../enums/api/user-role-enum';
import {GuestPath} from '../enums/guest-path-enum';
import {UserIdService} from '../services/user-id.service';
import {UsersService} from '../services/management/users.service';
import {ManagementPath} from '../enums/management-path-enum';
import {AuthSessionService} from '../services/auth-session.service';

/**
 * This guard is used across all app routes that require live data,
 * determining if the auth token is valid before allowing navigation to occur
 */
@Injectable()
export class AuthenticationGuard implements CanActivate {

	/** Creates an instance of the AuthenticationGuard
	 *
	 * @param router used for navigation actions
	 * @param idService user id service
	 * @param sessionService auth session service
	 * @param usersService users service
	 */
	constructor(
		private router: Router,
		private idService: UserIdService,
		private sessionService: AuthSessionService,
		private usersService: UsersService,
	) {
	}

	/** the actual guard for routing. Uses the current token information to determine if the user needs to go through the login flow again
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not
	 */
	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		// Store state url in sessionStorage for later redirect by callback component

		localStorage['redirectURL'] = state.url;
		return await this.checkLogin(route);
	}

	/** Function used to determine if the current token is valid. Checks token expiration against the current timestamp
	 * @returns whether or not the token is expired
	 */
	isTokenExpired(): boolean {
		const epoch = Math.trunc(new Date().getTime() / 1000);
		let expEpoch = null;

		if (localStorage.getItem('tokenExp') && localStorage.getItem('tokenExp') !== 'null') {
			expEpoch = parseInt(localStorage.getItem('tokenExp'), 10);
			return (epoch >= expEpoch);
		} else {
			return true;
		}
	}

	async getCurrentUser(): Promise<User> {
		return await this.usersService.getCurrentUser().toPromise();
	}

	/** Uses isTokenExpired() to determine if the user credentials should be cleared and the user forwarded to the login component
	 * @returns for whether the user is "logged in" or not
	 */
	async checkLogin(route: ActivatedRouteSnapshot): Promise<boolean> {
		// Check if token is expired (or null)
		if (this.isTokenExpired()) {
			this.sessionService.clearSession();

			// Navigate to the login page with extras
			this.router.navigate(['/login']);
			this.sessionService.isAuthenticate.next(false);
			return false; // guard complete and then router redirects to /login
		}
		const currentUser: User = await this.getCurrentUser();
		if (!currentUser) {
			this.router.navigate([GuestPath.Main]);

			return false;
		}

		if (route.routeConfig.path === ManagementPath.Main && currentUser.role !== UserRole.Admin) {
			this.router.navigate(['']);

			return false; // guard complete and then router redirects to /guest
		} else
			this.sessionService.isAuthenticate.next(true);

		return true;
	}
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SelectItem} from 'primeng';
import * as moment from 'moment';

import {Subject} from 'rxjs';

import {FormSidebarComponent} from '../../../../shared/app-sidebars/form-sidebar/form-sidebar.component';
import {CategoryType} from '../../../../../interfaces/category/category-type';
import {CalendarEvent} from '../../../../../interfaces/events/calendar-event';
import {EventsService} from '../../../../../services/events/events.service';
import {EventSelect} from '../../../../../interfaces/events/event-select';
import {DATE_API_FORMAT} from '../../../../../constants/calendar.constants';
import {TypeEvent} from '../../../../../enums/api/type-event-enum';
import {CategoryItem} from '../../../../../interfaces/category/category-item';
import {CategoryName} from '../../../../../enums/api/сategory-name-enum';
import {PriorityEvent} from '../../../../../enums/api/priority-event-enum';
import {LinkEvent} from '../../../../../interfaces/events/link-event';
import {SpokesPerson} from '../../../../../interfaces/spokespeople/spokespeople';

@Component({
	selector: 'app-event-form',
	templateUrl: './event-form.component.html',
	styleUrls: ['../../../../shared/app-sidebars/form-sidebar/form-sidebar.component.scss'],
})
export class EventFormComponent extends FormSidebarComponent implements OnInit {
	@Input() categoryTypes: CategoryType[];
	@Input() spokesPeople: SpokesPerson[];
	@Input() eventTypes: SelectItem[];

	@Input() set event(value: CalendarEvent) {
		this._event = value;

		if (value?.communicationsLead) {
			this.communicationsLeadControl.setValue(value?.communicationsLead);
		}

		// when event was received
		if (value?.id) {
			this.sideForm.patchValue({
				title: value.title,
				eventType: value.type,
				priority: value.priority || PriorityEvent.Medium,
				eventDescription: value.description || null,
				eventLocation: value.location || null,
				linkList: value.links || [],
			})

			if (value.spokesperson) {
				this.spokepersonControl.setValue(value.spokesperson.id);
				this.selectedPerson = value.spokesperson;
			}

			this.startDateControl.setValue(moment(value?.startDate, DATE_API_FORMAT).toDate());

			if (value.endDate && value.startDate !== value.endDate) {
				this.endDateControl.setValue(moment(value.endDate, DATE_API_FORMAT).toDate());
				this.isMultipleEventControl.setValue(true);
			}

			if (value.audiences) {
				this.secondaryAudiencesControl.setValue(value.audiences.filter(audience => {
					if (!audience.primary) {
						return true;
					}

					this.selectedPrimary = audience;
					this.primaryAudienceControl.setValue(audience.id);
					return false;
				}));
			}
		}
	}

	get event(): CalendarEvent {
		return this._event;
	}

	private _event: CalendarEvent;

	@Input() set categories(values: CategoryItem[]) {
		this._categories = values;

		if (values) {
			this.selectCategories = this.initSelectCategories(values);
		}
	}

	get categories(): CategoryItem[] {
		return this._categories;
	}

	private _categories: CategoryItem[];

	@Output() deleteClick = new EventEmitter<number>();
	@Output() saveClick = new EventEmitter<CalendarEvent>();

	public selectCategories: EventSelect[] = [
		{
			name: CategoryName.Channel,
			title: 'Channel',
			info: 'Select the primary channel that you will use to deliver your news or engagement.',
			selected: {value: null},
			items: [],
		},
		{
			name: CategoryName.Content,
			title: 'Content',
			info: 'Select the primary content theme of your news or engagement.',
			selected: {value: null},
			items: [],
		},
		{
			name: CategoryName.Region,
			title: 'Region',
			selected: {value: null},
			items: [],
		},
	];

	public priorities: SelectItem[];

	public selectedPerson: SpokesPerson;

	public selectedPrimary: CategoryItem;

	public dateForm: UntypedFormGroup;

	public eventsSubject: Subject<void> = new Subject<void>();

	get startDateControl(): AbstractControl {
		return this.dateForm?.get('startDate');
	}

	get endDateControl(): AbstractControl {
		return this.dateForm?.get('endDate');
	}

	get isMultipleEventControl(): AbstractControl {
		return this.dateForm?.get('isMultipleEvent');
	}

	get priorityControl(): AbstractControl {
		return this.sideForm?.get('priority');
	}

	get primaryAudienceControl(): AbstractControl {
		return this.sideForm?.get('primaryAudience');
	}

	get channelControl(): AbstractControl {
		return this.sideForm?.get(CategoryName.Channel);
	}

	get contentControl(): AbstractControl {
		return this.sideForm?.get(CategoryName.Content);
	}

	get regionControl(): AbstractControl {
		return this.sideForm?.get(CategoryName.Region);
	}

	get descriptionControl(): AbstractControl {
		return this.sideForm?.get('eventDescription');
	}

	get locationControl(): AbstractControl {
		return this.sideForm?.get('eventLocation');
	}

	get secondaryAudiencesControl(): AbstractControl {
		return this.sideForm?.get('secondaryAudiences');
	}

	get eventTypeControl(): AbstractControl {
		return this.sideForm?.get('eventType');
	}

	get isFordEvent(): boolean {
		return this.eventTypeControl.value === TypeEvent.Ford;
	}

	get linkListControl(): AbstractControl {
		return this.sideForm?.get('linkList');
	}

	get spokepersonControl(): AbstractControl {
		return this.sideForm?.get('spokeperson');
	}

	get communicationsLeadControl(): AbstractControl {
		return this.sideForm?.get('communicationsLead');
	}

	ngOnInit() {
		this.priorities = EventsService.getPriorities();

		this.dateForm = this.formBuilder.group({
			startDate: [moment().toDate()],
			endDate: [null],
			isMultipleEvent: [false],
		});

		this.sideForm = this.formBuilder.group({
			title: ['', Validators.required],
			eventType: [TypeEvent.Ford],
			priority: [PriorityEvent.Medium],
			spokeperson: [null],
			[CategoryName.Channel]: [null],
			[CategoryName.Content]: [null],
			[CategoryName.Region]: [null],
			primaryAudience: [null],
			secondaryAudiences: [[]],
			linkList: [[]],
			eventDescription: [null],
			eventLocation: [''],
			eventDates: this.dateForm,
			communicationsLead: [''],
		});
	}

	public onScroll() {
		this.eventsSubject.next();
	}

	public onChangeSpokesPerson(person: SpokesPerson) {
		this.selectCategories[2].selected = person?.region ? {label: person?.region.title, value: person?.region.id} : {value: null};
		this.regionControl.setValue(person?.region?.id || null);
	}

	public addSecondaryAudience(newSecondaryAudience: CategoryItem): void {
		const currentSecondaryAudiences = this.secondaryAudiencesControl.value;
		this.secondaryAudiencesControl.setValue([...currentSecondaryAudiences, newSecondaryAudience]);

		this.sideForm.markAsDirty();
	}

	public changeSecondaryAudience(changeModel: { item: CategoryItem; orderId: number }): void {
		const currentSecondaryAudiences = this.secondaryAudiencesControl.value;

		if (currentSecondaryAudiences.length) {
			currentSecondaryAudiences[changeModel.orderId] = changeModel.item;
			this.secondaryAudiencesControl.setValue([...currentSecondaryAudiences]);
		}

		this.sideForm.markAsDirty();
	}

	public onDeleteSecondaryAudience(orderId: number) {
		const currentSecondaryList: CategoryItem[] = this.secondaryAudiencesControl.value?.filter((l, i) => i !== orderId);

		this.secondaryAudiencesControl.setValue([...currentSecondaryList]);
		this.sideForm.markAsDirty();
	}

	public onChangeSelectedItem(item: SelectItem, name: CategoryName) {
		console.log(item, name);
	}

	public saveEvent() {
		this.event.title = this.titleControl.value;
		this.event.startDate = moment(this.startDateControl.value).format(DATE_API_FORMAT);
		this.event.endDate = this.endDateControl.value ? moment(this.endDateControl.value).format(DATE_API_FORMAT) : null;
		this.event.type = this.eventTypeControl.value;

		this.eventTypeControl.value === TypeEvent.External ? this.setExternalEventValues() : this.setFordEventValues();

		this.event.description = this.descriptionControl.value;
		this.event.location = this.locationControl.value;

		this.event.links = this.linkListControl.value;

		this.event.communicationsLead = this.communicationsLeadControl.value;

		console.log('this.event', this.event);
		this.saveClick.emit(this.event);
	}

	public deleteButtonEvent() {
		this.deleteClick.emit(this.event.id);
	}

	public onChangeType(item: SelectItem) {
		if (item.value === TypeEvent.External) {
			this.priorityControl.setValue(PriorityEvent.Medium);
			this.channelControl.setValue(null);
			this.contentControl.setValue(null);
			this.regionControl.setValue(null);
			this.primaryAudienceControl.setValue(null);
		}
	}

	private initSelectCategories(categories: CategoryItem[]): EventSelect[] {
		return this.selectCategories.map(eventSelect => {

			switch (eventSelect.name) {
				case CategoryName.Channel:
					if (this.event?.channel) {
						eventSelect.selected = {label: this.event.channel.title, value: this.event.channel.id};
						this.channelControl.setValue(this.event.channel.id);
					}
					break;
				case CategoryName.Content:
					if (this.event?.content) {
						eventSelect.selected = {label: this.event.content.title, value: this.event.content.id};
						this.contentControl.setValue(this.event.content.id);
					}
					break;
				case CategoryName.Region:
					if (this.event?.region) {
						eventSelect.selected = {label: this.event.region.title, value: this.event.region.id};
						this.regionControl.setValue(this.event.region.id);
					}
					break;
			}

			const items = categories.filter(cat => cat.name === eventSelect.name).map(cat => {
				return {label: cat.title, value: cat.id};
			});
			return {...eventSelect, items};
		});
	}

	public onDeleteLink(orderId: number) {
		const currentLinkList: LinkEvent[] = this.linkListControl.value?.filter((l, i) => i !== orderId);

		this.linkListControl.setValue([...currentLinkList]);
		this.sideForm.markAsDirty();
	}

	public onAddLink(link: LinkEvent) {
		const currentLinkList = this.linkListControl.value;
		this.linkListControl.setValue([...currentLinkList, link]);
		this.sideForm.markAsDirty();
	}

	public onChangeLink(linkModel: { linkEvent: LinkEvent, orderId: number }) {
		const currentLinkList = this.linkListControl.value;

		if (linkModel.linkEvent) {
			currentLinkList[linkModel.orderId] = linkModel.linkEvent;
			this.linkListControl.setValue([...currentLinkList]);
		}

		this.sideForm.markAsDirty();
	}

	private setExternalEventValues() {
		this.event.priority = PriorityEvent.Low;

		this.event.spokespersonId = null;
		this.event.spokesperson = null;

		this.event.audiences = [];

		this.event.channelId = null;
		this.event.contentId = null;
		this.event.regionId = null;

		this.event.channel = null;
		this.event.content = null;
		this.event.region = null;
	}

	private setFordEventValues() {
		this.event.priority = this.priorityControl.value;
		this.event.spokespersonId = this.spokepersonControl.value;

		if (this.primaryAudienceControl.value) {
			const primaryAudience = {
				id: this.primaryAudienceControl.value,
				primary: true,
			} as CategoryItem;
			this.event.audiences = [primaryAudience];
		}

		if (this.secondaryAudiencesControl.value.length) {
			const secondaryAudiences = this.secondaryAudiencesControl.value?.map((secondaryAudience: CategoryItem) => {
				return {
					id: secondaryAudience.id,
					primary: false,
				} as CategoryItem;
			})

			this.event.audiences = this.event.audiences?.length ? [...this.event.audiences, ...secondaryAudiences] : secondaryAudiences;
		}

		this.event.channelId = this.channelControl.value;
		this.event.contentId = this.contentControl.value;
		this.event.regionId = this.regionControl.value;
	}

}

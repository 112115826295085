import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {CategoryItem} from '../../../interfaces/category/category-item';
import {CategoryTypeSelect} from '../../../interfaces/category/category-type-select';

@Component({
	selector: 'app-category-select-group',
	templateUrl: './category-select-group.component.html',
	styleUrls: ['./category-select-group.component.scss']
})
export class CategorySelectGroupComponent {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() categoryTypes: CategoryTypeSelect[];
	@Input() showNone: boolean;

	@Output() selectItemChange = new EventEmitter<CategoryItem>();

	onChangeSelectItem(item: CategoryItem = null) {
		this.selectItemChange.emit(item);
	}

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {User} from 'src/app/interfaces/users/user';
import {TableHeader} from 'src/app/interfaces/table-header';
import {SelectedOrder} from 'src/app/interfaces/selected-order';
import {UserListCommon} from 'src/app/interfaces/users/user-list-common';
import {OrderDirection} from 'src/app/enums/order-direction-enum';
import {UsersService} from 'src/app/services/management/users.service';
import {TableColumnTitle, TableColumnValue} from 'src/app/enums/table-column-enum';
import {SidebarMode} from '../../../enums/sidebar-mode-enum';
import {DataStatus} from '../../../enums/data-status-enum';
import {ManagementService} from '../../../services/management.service';
import {UserEvent} from '../../../interfaces/users/user-event';
import {ToolEvent} from '../../../enums/tool-event-enum';
import {UnsavedChangesService} from 'src/app/services/unsaved-changes.service';
import {ErrorApi} from '../../../interfaces/api/error-api';

@Component({
	selector: 'users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
	public readonly sidebarMode = SidebarMode;

	public users: User[];
	public currentUser: User;
	public isUnsavedData: boolean;

	public selectedOrder: SelectedOrder = {
		attr: TableColumnValue.Name,
		direction: OrderDirection.Asc,
	};
	public userTableHeaders: TableHeader[] = [
		{value: TableColumnValue.Name, title: TableColumnTitle.Name, isSortable: true},
		{value: TableColumnValue.Role, title: TableColumnTitle.Permissions, isSortable: true},
		{value: TableColumnValue.Email, title: TableColumnTitle.Email, isSortable: false},
	];

	public pageNumber = 1;
	private rowsAmount = 12;
	public totalPagesAmount: number;

	public showPaginator: boolean;

	public userSideMode: SidebarMode = SidebarMode.Closed;
	public currentDataStatus: DataStatus = DataStatus.Loading;
	public showUserDeleteSidebar: boolean;
	public searchValue: string;
	public userError: ErrorApi;

	public showLoader = true;

	public addUserSub: Subscription;
	public searchSub: Subscription;
	public closeAllSub: Subscription;

	get userSideTitle(): string {
		return this.userSideMode === SidebarMode.Add ? `Add New User` : null;
	}

	get showUserSidebar(): boolean {
		return this.userSideMode !== SidebarMode.Closed;
	}

	constructor(
		private usersService: UsersService,
		private unsavedChangesService: UnsavedChangesService,
		private managementService: ManagementService) {
	}

	ngOnInit(): void {
		this.searchSub = this.managementService.searchValue$
			.pipe(debounceTime(300))
			.subscribe((value: string) => this.handleSearch(value));
		this.closeAllSidebars();

		this.addUserSub = this.managementService.categoryItemEvent$.subscribe(() => this.addUser());
	}

	ngOnDestroy(): void {
		this.addUserSub.unsubscribe();
		this.searchSub.unsubscribe();
		this.closeAllSub.unsubscribe();
	}

	private closeAllSidebars() {
		this.closeAllSub = this.unsavedChangesService.closeAll().subscribe(() => {
			this.userSideMode = SidebarMode.Closed;
			this.clearSideData();
		});
	}

	private handleSearch(searchValue) {
		this.searchValue = searchValue;
		this.getUsers();
	}

	public addUser() {
		this.userSideMode = SidebarMode.Add;
		this.currentDataStatus = DataStatus.Ready;
		this.currentUser = {} as User;
	}

	public handleUpdateOrder(selectedOrder: SelectedOrder): void {
		this.selectedOrder = selectedOrder;

		this.getUsers();
	}

	public handleUpdatePageNumber(pageNumber: number) {
		this.pageNumber = pageNumber;

		this.getUsers();
	}

	public handleUnsavedChangesSidebar() {
		this.isUnsavedData = false;
	}

	public closeUserSidebar(isUnsavedData?: boolean) {
		if (isUnsavedData) {
			return;
		}

		this.userSideMode = SidebarMode.Closed;
		this.clearSideData();
	}

	public deleteUser(id: number) {
		this.currentDataStatus = DataStatus.Saving;
		this.showLoader = true;
		this.usersService.deleteUser(id)
			.subscribe(() => {
				this.getUsers();
				this.userSideMode = SidebarMode.Closed;
				this.showUserDeleteSidebar = false;
				this.clearSideData();
			});
	}

	public saveUser(user: User) {
		this.currentDataStatus = DataStatus.Saving;

		if (user.id) {
			this.usersService.updateUser(user)
				.subscribe((a) => {
						console.log('user',a);
						this.getUsers();
						this.closeUserSidebar();
					},
					(error) => {
						this.handleUserError(error);
					});
		} else {
			this.usersService.addUser(user)
				.subscribe(() => {
						this.getUsers();
						this.closeUserSidebar();
					},
					(error) => {
						this.handleUserError(error);
					});
		}
	}

	public closeSidebar(isUnsavedData?: boolean): void {
		if (isUnsavedData) {
			this.isUnsavedData = true;
			return;
		}
		this.userSideMode = SidebarMode.Closed;
		this.clearSideData();
	}

	public handleToolsClickEvent(event: UserEvent) {
		const {id} = event.item;
		switch (event.toolEvent) {
			case ToolEvent.Delete:
				this.currentUser = event.item;
				this.deleteUser(id);
				break;
			case ToolEvent.Edit:
				this.openUser(id);
				break;
		}
	}

	public closeUserDeleteSidebar() {
		this.showUserDeleteSidebar = false;
		this.currentDataStatus = DataStatus.Ready;
	}

	private clearSideData() {
		this.currentDataStatus = DataStatus.Loading;
		this.currentUser = null;
	}

	private getUsers(): void {
		this.usersService.getUsers(this.selectedOrder, this.pageNumber, this.rowsAmount, this.searchValue)
			.subscribe(res => this.updateUserList(res));
	}

	private updateUserList(data: UserListCommon) {
		this.users = data.result;
		this.totalPagesAmount = Math.ceil(data.totalRecords / this.rowsAmount);
		this.showPaginator = data.totalRecords > this.rowsAmount;
		this.showLoader = false;
	}

	private openUser(userId: number) {
		this.userSideMode = SidebarMode.Edit;
		this.currentDataStatus = DataStatus.Loading;

		this.usersService.getUserById(userId)
			.subscribe((res) => {
				this.currentUser = res;
				this.currentDataStatus = DataStatus.Ready;
			});
	}

	private handleUserError(error: ErrorApi) {
		this.currentDataStatus = DataStatus.Ready;
		this.userError = error;
	}

	public resetUserError() {
		this.userError = null;
	}
}

import {ElementRef, Injectable} from '@angular/core';

import domtoimage from 'dom-to-image';
import * as moment from 'moment';

import {EMPTY} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';

import {CalendarParams} from '../../interfaces/calendar/calendar-params';
import {DateRangeValue} from '../../enums/api/date-range-enum';
import {AppHeaderService} from '../general/app-header.service';
import {DATE_MONTH, DATE_YEAR} from '../../constants/calendar.constants';
import {CalendarHeaderComponent} from 'src/app/components/general/calendar-header/calendar-header.component';
import {CalendarPriorityComponent} from '../../components/general/calendar-priority/calendar-priority.component';
import {AppOverlayLoaderComponent} from 'src/app/components/shared/app-overlay-loader/app-overlay-loader.component';

interface DomToPngConfig {
	width?: number;
	height?: number;
	style: Partial<CSSStyleDeclaration>
}

@Injectable({
	providedIn: 'root'
})
export class ExportPngService {
	private currentViewConfig = {
		categoryType: '',
		dateRange: '',
	}
	private printableArea: ElementRef<HTMLElement> = null;
	private headerComponent: CalendarHeaderComponent = null;
	private priorityComponent: CalendarPriorityComponent = null;
	private calendarContent: ElementRef<HTMLElement> = null;
	private loader: AppOverlayLoaderComponent = null;

	private pngHeight: number;
	private calendarHeaderHeight = 60;
	private pngConfig: DomToPngConfig = {
		width: 1645,
		height: 925,
		style: {
			maxWidth: '1645px',
			minWidth: '1645px',
		},
	}

	constructor(
		private appHeaderService: AppHeaderService
	) {
		this.appHeaderService.getCalendarParams()
			.pipe(
				switchMap((params: CalendarParams) => {
					if (params.period === DateRangeValue.Week) {
						this.currentViewConfig.dateRange = this.setConfigDateRange(params);

						return EMPTY;
					}

					return this.appHeaderService.getDateRangeValue().pipe(take(1));
				}),
			)
			.subscribe((value: string) => {
				if (!value) {
					return;
				}
				this.currentViewConfig.dateRange = value;
			})
	}

	populatePrintableArea({
		printableArea,
		headerComponent,
		priorityComponent,
		calendarContent,
		loader,
	}: {
		printableArea?: ElementRef<HTMLElement>,
		headerComponent?: CalendarHeaderComponent,
		priorityComponent?: CalendarPriorityComponent,
		calendarContent?: ElementRef<HTMLElement>,
		loader?: AppOverlayLoaderComponent,
	}) {
		this.printableArea = printableArea;
		this.headerComponent = headerComponent;
		this.priorityComponent = priorityComponent;
		this.calendarContent = calendarContent;
		this.loader = loader;
	}

	// can accept current view config if this info will be available in app-header
	exportPng() {
		const area = this.printableArea?.nativeElement;

		if (!area) {
			return console.log('error finding printable area tag');
		}
		this.toggleViewStylesForPrint(area, true);

		this.pngConfig.height = this.calendarContent?.nativeElement.scrollHeight + this.calendarHeaderHeight;

		domtoimage.toPng(area, this.pngConfig)
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.download = this.generateFilename();
				link.href = dataUrl;
				link.click();
			})
			.catch((error) => {
				console.error('error while creating png: ', error);
			})
			.finally(() => this.toggleViewStylesForPrint(area, false));
	}

	private toggleViewStylesForPrint(element: HTMLElement, toggle: boolean) {
		Object.keys(this.pngConfig.style).forEach((key) => {
			element.style[key] = toggle ? this.pngConfig.style[key] : null;
		});
		const confidentialityDialog: HTMLDivElement = document.querySelector('.ui-dialog-mask');
		const overlayPanels: NodeListOf<HTMLDivElement> = document.querySelectorAll('.ui-overlaypanel');

		if (confidentialityDialog) {
			confidentialityDialog.style.display = toggle ? 'none' : 'flex';
		}
		if (overlayPanels && overlayPanels.length) {
			const arrayOverlayPanels: HTMLDivElement[] = Array.from(overlayPanels);

			arrayOverlayPanels.forEach((item) => {
				item.style.display = toggle ? 'none' : 'block';
			})
		}

		this.loader.invokedByPngDownload = toggle;
		this.headerComponent?.setReverseColorsForPrint(toggle);
		this.priorityComponent?.setReverseColorsForPrint(toggle);
	}

	private setConfigDateRange(params: CalendarParams): string {
		const selectedWeek = moment(params.selectedDate || params.currentDate).add(params.range, 'week');
		const firstDay = moment(selectedWeek).startOf('isoWeek').date();
		const lastDay = moment(selectedWeek).endOf('isoWeek').date();
		const firstMonth = moment(selectedWeek).startOf('isoWeek').format(DATE_MONTH);
		const lastMonth = moment(selectedWeek).endOf('isoWeek').format(DATE_MONTH);
		const firstYear = moment(selectedWeek).startOf('isoWeek').format(DATE_YEAR);
		const lastYear = moment(selectedWeek).endOf('isoWeek').format(DATE_MONTH);

		if (firstYear !== lastYear) {
			return `${firstMonth} ${firstDay} ${firstYear} - ${lastMonth} ${lastDay} ${lastYear}`;
		} else if (firstMonth !== lastMonth) {
			return `${firstMonth} ${firstDay} - ${lastMonth} ${lastDay} ${firstYear}`;
		} else {
			return `${firstMonth} ${firstDay} - ${lastDay} ${firstYear}`;
		}
	}

	private generateFilename() {
		const item = this.headerComponent.selectedMenuItem?.name || this.headerComponent.menuItems[0]?.label;
		this.currentViewConfig.categoryType = item;
		return `${this.currentViewConfig.categoryType} ${this.currentViewConfig.dateRange}.png`
	}
}

import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {map, switchMap} from 'rxjs/operators';
import {Observable, Subject, Subscription} from 'rxjs';

import {SelectItem} from 'primeng';

import * as moment from 'moment';

import {DeviceDetectorService} from 'ngx-device-detector';

import {HeaderMode} from 'src/app/enums/header-mode-enum';
import {ScrollData} from '../../interfaces/scroll.data';
import {FilterEvent} from 'src/app/enums/event-filter-enum';
import DataService from '../../services/data.service';
import {CalendarData} from 'src/app/interfaces/calendar/calendar-data';
import {CategoryType} from 'src/app/interfaces/category/category-type';
import {CategoryItem} from 'src/app/interfaces/category/category-item';
import {FilterAction} from 'src/app/enums/filter/filter-action-enum';
import {FilterService} from 'src/app/services/filter/filter.service';
import {ScrollService} from '../../services/scroll/scroll.service';
import {EventsService} from 'src/app/services/events/events.service';
import {CalendarParams} from 'src/app/interfaces/calendar/calendar-params';
import {AppHeaderService} from 'src/app/services/general/app-header.service';
import {ExportPngService} from 'src/app/services/exportPng/export-png.service';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import {HeaderMenuSelectedItem} from 'src/app/interfaces/header-menu-item';
import {CalendarHeaderComponent} from 'src/app/components/general/calendar-header/calendar-header.component';
import {GeneralCalendarComponent} from 'src/app/components/general/calendar/general-calendar.component';
import {CalendarPriorityComponent} from 'src/app/components/general/calendar-priority/calendar-priority.component';
import {AppOverlayLoaderComponent} from 'src/app/components/shared/app-overlay-loader/app-overlay-loader.component';
import {CATEGORY_MENU_ITEM, CATEGORY_NAME} from 'src/app/constants/category.constants';
import {CategoryHeaderMenuItem, HeaderCategory} from 'src/app/enums/api/category-header-enum';

@Component({
	selector: 'general-view',
	templateUrl: './general-view.component.html',
	styleUrls: ['./general-view.component.scss']
})
export class GeneralViewComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('printableArea') printableArea: ElementRef<HTMLElement>;
	@ViewChild('headerArea') headerArea: CalendarHeaderComponent;
	@ViewChild('priority') priorityComponent: CalendarPriorityComponent;
	@ViewChild('calendar') calendarComponent: GeneralCalendarComponent;
	@ViewChild('calendarContent') calendarContent: ElementRef<HTMLElement>;
	@ViewChild('loader') loader: AppOverlayLoaderComponent;

	public menuItems: SelectItem[];
	public displayConfidentialityDialog = false;
	public calendarParams: CalendarParams;
	public categoryTypeItems: CategoryType[] = [];
	public categoryItems: CategoryItem[] = [];
	public calendarData: CalendarData;
	public formattedCurrentDate: string;
	public selectedMenuItem: CategoryHeaderMenuItem;
	public alternateCategoryItems: CategoryItem[] = [];
	public filterEvent: FilterEvent = FilterEvent.Close;
	public filterAction: FilterAction;
	public filterSideTitle = 'Filter Events';
	public scrollData: ScrollData = {isVisible: false, width: 0};
	public showLoader = true;
	public searchValue: string;
	public backendVersion: string;
	public frontendVersion: string;
	public scrollEventSubject: Subject<void> = new Subject<void>();

	public readonly mode = HeaderMode;

	public calendarParamsSub: Subscription;
	public filterEventSub: Subscription;
	public filterActionSub: Subscription;

	constructor(
		private dataService: DataService,
		private eventsService: EventsService,
		private exportPngService: ExportPngService,
		private filterService: FilterService,
		private appHeaderService: AppHeaderService,
		private localStorageService: LocalStorageService,
		private deviceService: DeviceDetectorService,
		private scrollService: ScrollService,
		private cdr: ChangeDetectorRef,
	) {
	}

	ngOnInit() {
		this.appHeaderService.getCalendarParams().subscribe((params: CalendarParams) => {
			this.calendarParams = params;
			this.selectedMenuItem = CATEGORY_MENU_ITEM[params.groupBy];
		});

		this.getMenuItems();
		this.getCalendarParams();
		this.getFilterEvent();
		this.getFilterAction();
		this.getAppVersion();
		this.getConfidentiality();
	}

	ngAfterViewInit() {
		this.exportPngService.populatePrintableArea({
			printableArea: this.printableArea,
			headerComponent: this.headerArea,
			priorityComponent: this.priorityComponent,
			calendarContent: this.calendarContent,
			loader: this.loader,
		});
	}

	ngOnDestroy() {
		this.calendarParamsSub.unsubscribe();
		this.filterEventSub.unsubscribe();
		this.filterActionSub.unsubscribe();
	}

	public get isDesktop(): boolean {
		return this.deviceService.isDesktop();
	}

	public get showFilterSidebar(): boolean {
		return this.filterEvent === FilterEvent.Open;
	}

	public get showAvatar(): boolean {
		return this.calendarParams.groupBy === HeaderCategory.Spokesperson;
	}

	private getAppVersion() {
		this.dataService.getAppVersion().subscribe((data: { frontendVersion: string; backendVersion: string }) => {
			this.backendVersion = data.backendVersion;
			this.frontendVersion = data.frontendVersion;
		});
	}

	private getFilterEvent(): void {
		this.filterEventSub = this.filterService.getFilterEvent()
			.subscribe((event: FilterEvent) => {
				this.filterEvent = event;
			});
	}

	private getFilterAction(): void {
		this.filterActionSub = this.filterService.getFilterAction()
			.subscribe((action: FilterAction) => {
				this.showLoader = true;
				this.filterAction = action;

				if (action === FilterAction.ClearAll || action === FilterAction.Applied) {
					this.getCalendarParams();
				}
			});
	}

	public handleScroll(): void {
		this.priorityComponent.closeOverlayPanel();
		this.scrollEventSubject.next();
		this.calendarComponent.closeOverlayPanelMoreEvents();
	}

	public handleFilterSidebarClose(): void {
		this.filterService.setFilterEvent(FilterEvent.Close);
	}

	public closeConfidentialityDialog(): void {
		this.localStorageService.setConfidentiality(true);
	}

	private getConfidentiality(): void {
		this.displayConfidentialityDialog = !this.localStorageService.getConfidentiality();
	}

	private getMenuItems(): void {
		this.dataService.getTypes()
			.pipe(
				map((res: string[]) => {
					return res.map<SelectItem>((t, i) => {
						return {label: t, value: {id: i, name: t}};
					});
				})
			).subscribe((items: SelectItem[]) => {
			this.menuItems = items;
		});
	}

	private getCalendarParams(): void {
		this.calendarParamsSub = this.appHeaderService.getCalendarParams()
			.pipe(
				switchMap((data: CalendarParams) => {
					this.showLoader = true;
					this.calendarParams = data;

					if (this.filterAction === FilterAction.Applied) {
						return this.getFilteredCalendarData();
					} else {
						return this.getCalendarData();
					}
				})
			)
			.subscribe((data: CalendarData) => {
				if (!data) {
					return;
				}

				const selectedDate = data.timeslots.find((item) => item.current);
				this.formattedCurrentDate = moment(selectedDate?.timeRange).format('MMM YYYY');
				this.calendarData = data;
				this.calendarData.period = this.calendarParams.period; // It is for update calendar-header block after calendarData updated
				this.showLoader = false;

				this.cdr.detectChanges();

				this.scrollData.isVisible = this.scrollService.checkScrollExisting(this.calendarContent);
				this.scrollData.width = this.scrollService.getScrollWidth(this.calendarContent);
			});
	}

	private getCalendarData(): Observable<CalendarData> {
		return this.eventsService.getEvents(this.calendarParams);
	}

	private getFilteredCalendarData(): Observable<CalendarData> {
		return this.eventsService.getFilteredEvents(this.calendarParams);
	}

	public handleChangeEvents(): void {
		this.showLoader = true;
		this.getCalendarParams();
	}

	public handleMenuItemChange(event: HeaderMenuSelectedItem): void {
		this.calendarParams.groupBy = CATEGORY_NAME[event.name];
		this.appHeaderService.setCalendarParams(this.calendarParams);
	}
}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UnsavedChangesService {
	public cancelClick$: Subject<boolean> = new Subject();

	private show$: Subject<boolean> = new Subject();
	private closeAll$: Subject<boolean> = new Subject();

	constructor() { }

	public setIsUnsavedChanges(value: boolean): void {
		this.show$.next(value);
	}

	public showUnsavedChangesSidebar(): Subject<boolean> {
		return this.show$;
	}

	public setCloseAll(value: boolean) {
		this.closeAll$.next(value);
	}

	public closeAll(): Subject<boolean> {
		return this.closeAll$;
	}
}

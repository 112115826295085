import {Injectable} from '@angular/core';

import {User} from '../../interfaces/users/user';
import {LocalStorageKey} from '../../enums/local-storage-key-enum';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	private setLocalStorage(key: string, value: any): void {
		localStorage.setItem(key, value);
	}

	private getLocalStorage(key: string): any {
		return localStorage.getItem(key);
	}

	public setConfidentiality(value: boolean): void {
		this.setLocalStorage(LocalStorageKey.ConfidentialityIsRead, value);
	}

	public getConfidentiality(): boolean {
		return this.getLocalStorage(LocalStorageKey.ConfidentialityIsRead);
	}

	public setCurrentUser(value: User): void {
		this.setLocalStorage(LocalStorageKey.CurrentUser, JSON.stringify(value));
	}

	public getCurrentUser(): User {
		return JSON.parse(this.getLocalStorage(LocalStorageKey.CurrentUser));
	}
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CategoryType} from '../../../interfaces/category/category-type';
import {Keyword} from '../../../interfaces/keywords/keyword';

@Component({
	selector: 'app-add-panel',
	templateUrl: './app-add-panel.component.html',
	styleUrls: ['./app-add-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAddPanelComponent {
	@Input() label: string;
	@Input() item: CategoryType;
	@Input() showDnDText: boolean;
	@Output() addPanelDropEvent: EventEmitter<CategoryType | Keyword> = new EventEmitter();
	@Output() addClick: EventEmitter<number> = new EventEmitter();

	onAddClick() {
		this.addClick.emit(this.item?.id || null);
	}

	onDrop() {
		this.addPanelDropEvent.emit(this.item);
	}
}

import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import {UserIdService} from '../services/user-id.service';
import {AuthSessionService} from '../services/auth-session.service';

/** This guard is used in the oauth callback flow, consuming the URL before the Angular router can.
 * The URL built by the auth provider is technically invalid (no ? for the queryParams), so this guard
 * needs to consume the URL before Angular's router (which would fail to parse it).
 */
@Injectable()
export class OauthTokenGuard implements CanActivate {

	/** Creates an instance of the OauthTokenGuard
	 *
	 * @param router route instance for current routing params
	 * @param idService the angular service for handling user ID
	 * @param sessionService auth session service
	 */
	constructor(private router: Router, private idService: UserIdService, private sessionService: AuthSessionService) {
	}

	/** the actual guard function. Parses the queryString and stores the params in sessionStorage.
	 * Redirects the user to the default route, or to the route that was stored before the auth redirect.
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const queryParamsObj = this.parseParamString(route.fragment);
		console.log('queryParamsObj', queryParamsObj);

		if (queryParamsObj && queryParamsObj['access_token'] != null) {
			//token is part 2 of a JWT (index 1)
			const accessToken = atob(queryParamsObj['access_token'].split('.')[1]);
			const jsonToken = JSON.parse(accessToken);
			console.log('jsonToken', jsonToken);
			console.log('accessToken', accessToken);
			console.log('queryParamsObj[\'access_token\']', queryParamsObj['access_token']);
			//store off token information in the appropriate places
			this.sessionService.storeTokenInformation(accessToken, queryParamsObj['access_token'], jsonToken);
			const urlSegments = this.extractRedirectUrlSegments(localStorage.getItem('redirectURL'));
			this.router.navigateByUrl(urlSegments.path, urlSegments.queryParams);
			return true;
		} else {
			console.error('Invalid Token');
		}

		return false;
	}

	/** This function return a parsed segment object given a valid url with and without query parameter
	 *
	 * @param url url for redirection
	 * @returns segments object that contains properties used for navigateByUrl call
	 */
	extractRedirectUrlSegments(url: string) {
		const segments = {
			path: '/',
			queryParams: {}
		};
		if (url) {
			if (url.indexOf('?') > -1) {
				const [path, queryString] = url.split('?');
				segments.path = path.substring(path.lastIndexOf('/') + 1);
				segments.queryParams = this.getRedirectQueryParams(queryString);
			} else {
				segments.path = url;
			}
		}
		return segments;
	}

	/** This function return list of params object with valid query and return empty object with undefined query
	 *
	 * @param query query string from the url to parse parameters from
	 * @returns object containing the parsed query string params from the given url
	 */
	getRedirectQueryParams(query: string) {
		const queryObj = {};
		if (query) {
			const queryArgs = query.split('&');
			queryArgs.forEach(item => {
				const pair = item.split('=');
				queryObj[pair[0]] = pair[1];
			});
			return queryObj;
		}
		return queryObj;
	}

	/** Parses the technically malformed queryString to pick off the token and associated properties.
	 * @param paramString the param string from the url
	 * @returns The queryString params in Object format, or null if the string was invalid.
	 */
	parseParamString(paramString: string) {
		if (paramString.indexOf('access_token') === 0) {
			//URLSearchParams should be the solution here. it's not working. so we did it manually
			const paramArray = paramString.split('&');
			const queryParamsObj = {};
			for (const param of paramArray) {
				//we can't use a simple split() call here as base64 allows for = padding
				const i = param.indexOf('=');
				const splitArray = [param.slice(0, i), param.slice(i + 1)];
				queryParamsObj[splitArray[0]] = splitArray[1];
			}
			return queryParamsObj;
		} else {
			return null;
		}
	}
}

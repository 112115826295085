import {Routes} from '@angular/router';

import {ManagementMenu} from '../interfaces/managment-menu';
import {AudienceComponent} from '../views/management/audience/audience.component';
import {SpokespeopleComponent} from '../views/management/spokespeople/spokespeople.component';
import {AlternateCategoriesComponent} from '../views/management/alternate-categories/alternate-categories.component';
import {UsersComponent} from '../views/management/users/users.component';
import {QuickSearchKeywordsComponent} from '../views/management/quick-search-keywords/quick-search-keywords.component';
import {ManagementPath} from '../enums/management-path-enum';
import {CategoryName} from '../enums/api/сategory-name-enum';
import {CategoryComponent} from '../views/management/category/category.component';
import {CATEGORY_LABELS} from './management.constants';

const CATEGORIES_MENU: ManagementMenu = {
	title: 'Categories',
	items: [
		{
			label: 'Audience',
			routerLink: ManagementPath.Audience,
		},
		{
			label: 'Channels',
			routerLink: ManagementPath.Channels,
		},
		{
			label: 'Content',
			routerLink: ManagementPath.Content,
		},
		{
			label: 'Regions',
			routerLink: ManagementPath.Regions,
		},
		{
			label: 'Spokespeople',
			routerLink: ManagementPath.SpokesPeople,
		},
		{
			label: 'Alternate Categories',
			routerLink: ManagementPath.AlternateCategories,
		}
	]
}

const SYSTEM_MENU: ManagementMenu = {
	title: 'system',
	items: [
		{
			label: 'User',
			routerLink: ManagementPath.Users,
		},
		{
			label: 'Quick Search Keywords',
			routerLink: ManagementPath.QuickSearch,
		}
	]
}

export const MANAGEMENT_ROUTES: Routes = [
	{path: ManagementPath.Audience, component: AudienceComponent},
	{
		path: ManagementPath.Channels,
		component: CategoryComponent,
		data: {
			CATEGORY: CATEGORY_LABELS[ManagementPath.Channels].valueOf(),
			CATEGORY_NAME: CategoryName.Channel,
		},
	},
	{
		path: ManagementPath.Content,
		component: CategoryComponent,
		data: {
			CATEGORY: CATEGORY_LABELS[ManagementPath.Content].valueOf(),
			CATEGORY_NAME: CategoryName.Content,
		},
	},
	{
		path: ManagementPath.Regions,
		component: CategoryComponent,
		data: {
			CATEGORY: CATEGORY_LABELS[ManagementPath.Regions].valueOf(),
			CATEGORY_NAME: CategoryName.Region,
		},
	},
	{path: ManagementPath.SpokesPeople, component: SpokespeopleComponent},
	{path: ManagementPath.AlternateCategories, component: AlternateCategoriesComponent},
	{path: ManagementPath.Users, component: UsersComponent},
	{path: ManagementPath.QuickSearch, component: QuickSearchKeywordsComponent},
];

export const MANAGEMENT_MENU_LIST: ManagementMenu[] = [CATEGORIES_MENU, SYSTEM_MENU]

export enum CategoryHeaderMenuItem {
	Audiende = 'Audience',
	Channels = 'Channels',
	Content = 'Content',
	Regions = 'Regions',
	Spokesperson = 'Spokesperson',
}

export enum HeaderCategory {
	Audience = 'AUDIENCE',
	Channel = 'CHANNEL',
	Region = 'REGION',
	Content = 'CONTENT',
	Spokesperson = 'SPOKESPERSON',
}

import {Message} from 'primeng/api';

export interface MessageEvent extends Message {
	description: string;
}

export class HttpStatusEnum {
	static time = 10000;

	static readonly GET_FAILURE_MESSAGE: Message = {
		severity: 'error',
		summary: 'Failed to retrieve',
		sticky: true
	};

	static readonly POST_FAILURE_MESSAGE: Message = {
		severity: 'error',
		summary: 'Failed to post',
		sticky: true
	};

	static readonly PUT_FAILURE_MESSAGE: Message = {
		severity: 'error',
		summary: 'Failed to put',
		sticky: true
	};

	static readonly DELETE_FAILURE_MESSAGE: Message = {
		severity: 'error',
		summary: 'Failed to delete',
		sticky: true
	};

	static DELETE_SUCCESS_MESSAGE(summary: string, title): Message {
		return {
			severity: 'success',
			summary: summary + ' Deleted:',
			life: this.time,
			detail: title,
		};
	}

	static CREATE_SUCCESS_MESSAGE(summary: string, title: string): Message {
		return {
			severity: 'success',
			summary: `New ${summary} Added`,
			life: this.time,
			detail: title,
		};
	}

	static CREATE_SUCCESS_EVENT_MESSAGE(title: string, description: string): MessageEvent {
		return {
			key: 'event',
			severity: 'success',
			summary: 'Event Created',
			life: this.time,
			detail: title,
			description
		};
	}

	static DELETE_SUCCESS_EVENT_MESSAGE(title: string, description: string): MessageEvent {
		return {
			key: 'event',
			severity: 'success',
			summary: 'Event Deleted',
			life: this.time,
			detail: title,
			description
		};
	}

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CategoryType} from '../../../../interfaces/category/category-type';
import {FormSidebarComponent} from '../../../shared/app-sidebars/form-sidebar/form-sidebar.component';
import {Validators} from '@angular/forms';
import {CommsCalendarError} from '../../../../interfaces/comms-calendar-error';
import {COMMS_CALENDAR_ERROR} from '../../../../constants/error-codes';

@Component({
	selector: 'app-category-type-form',
	templateUrl: './category-type-form.component.html',
	styleUrls: ['../../../shared/app-sidebars/form-sidebar/form-sidebar.component.scss'],
})
export class CategoryTypeFormComponent extends FormSidebarComponent implements OnInit {
	@Input() set categoryType(value: CategoryType) {
		this._categoryType = value;

		// when category type was received
		if (value) {
			this.titleControl.setValue(value?.title);
		}
	}

	get categoryType(): CategoryType {
		return this._categoryType;
	}
	private _categoryType: CategoryType;

	@Output() deleteClick = new EventEmitter<number>();
	@Output() saveClick = new EventEmitter<CategoryType>();

	private handledError: CommsCalendarError = COMMS_CALENDAR_ERROR._202;

	public categoryTypeFormError(formControl: string) {
		this.handledError.message = `Audience Type already exists in system`;
		return this.requiredError(formControl, this.handledError);
	}

	ngOnInit() {
		this.sideForm = this.formBuilder.group({
			title: ['', [Validators.required, this.uniqueValueValidator(this.handledError)]],
		});
	}

	public saveEvent() {
		this.categoryType.title = this.titleControl.value;
		this.saveClick.emit(this.categoryType);
	}

	public deleteButtonEvent() {
		this.deleteClick.emit(this.categoryType.id);
	}
}

import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {SelectItem} from 'primeng';

import {map, switchMap, take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

import * as moment from 'moment';

import {GeneralPath} from 'src/app/enums/general-path-enum';
import DataService from '../data.service';
import {CalendarData, CalendarTimeslot} from 'src/app/interfaces/calendar/calendar-data';
import {CalendarEvent} from 'src/app/interfaces/events/calendar-event';
import {PriorityEvent} from '../../enums/api/priority-event-enum';
import {FilterCategory} from 'src/app/interfaces/filter-category';
import {HttpStatusEnum} from '../../enums/http-status.enum';
import {CalendarParams} from 'src/app/interfaces/calendar/calendar-params';
import {AppMessageService} from '../app-message.service';
import {CalendarEventApi} from '../../interfaces/api/calendar-event-api';
import {FilterCategoryName} from 'src/app/enums/filter/filter-category-enum';
import {CalendarEventPriority} from '../../enums/calendar/calendar-event-priority-enum';
import {FilterService, SelectedFilters} from '../filter/filter.service';
import {TypeEvent} from '../../enums/api/type-event-enum';
import {EventApi} from '@fullcalendar/core';
import {AppHeaderService} from '../general/app-header.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DATE_MONTH, DATE_YEAR} from '../../constants/calendar.constants';

const EVENTS: GeneralPath = GeneralPath.Events;
const EVENT_FILTERS: GeneralPath = GeneralPath.EventFilters;
const EVENTS_BY_DATES: GeneralPath = GeneralPath.EventsByDates;

export type FilterCategoriesData = {
	[P in FilterCategoryName]: FilterCategory[];
};

@Injectable({
	providedIn: 'root'
})
export class EventsService {

	constructor(
		private dataService: DataService,
		private filterService: FilterService,
		private messageService: AppMessageService,
		private appHeaderService: AppHeaderService,
		private deviceService: DeviceDetectorService
	) {
	}
	public static getPriorities(): SelectItem[] {
		return [
			{value: PriorityEvent.High, label: CalendarEventPriority.High},
			{value: PriorityEvent.Medium, label: CalendarEventPriority.Medium},
			{value: PriorityEvent.Low, label: CalendarEventPriority.Low},
		];
	}

	private static getMessageEventDescription(event: CalendarEvent): string {
		const startDate = moment(event.startDate);
		const endDate = moment(event.endDate);
		let description: string;

		if (startDate.format(DATE_MONTH) === endDate.format(DATE_MONTH) && startDate.format(DATE_YEAR) === endDate.format(DATE_YEAR)) {
			if (startDate.format('D') === endDate.format('D')) {
				description = `${startDate.format(DATE_MONTH)} ${startDate.format('D')}, ${endDate.year()}`;
			} else {
				description = `${startDate.format(DATE_MONTH)} ${startDate.format('D')} - ${endDate.format('D')}, ${endDate.year()}`;
			}
		} else {
			description = `${startDate.format(DATE_MONTH)} ${startDate.format('D')} - ${endDate.format(DATE_MONTH)} ${endDate.format('D')}, ${endDate.year()}`;
		}

		if (event.location) {
			description += `, ${event.location}`;
		}
		return description;
	}

	public getEventTypes(): SelectItem[] {
		return [
			{value: TypeEvent.Ford, label: 'Ford Event'},
			{value: TypeEvent.External, label: this.deviceService.isDesktop() ? 'External Environment' : 'External'},
		];
	}

	public getEventById(id: number): Observable<CalendarEvent> {
		return this.dataService.getData(EVENTS + '/' + id).pipe(map((data: CalendarEventApi) => data.result));
	}

	public getEventByTitle(title: string): Observable<CalendarEvent[]> {
		return this.dataService.getData(EVENTS + '?title=' + title + '&limit=10').pipe(map((data: CalendarEventApi) => data.result));
	}

	public getEventsFilters(): Observable<FilterCategoriesData> {
		return this.dataService.getData(`${EVENT_FILTERS}`)
			.pipe(
				map((data: { result: FilterCategoriesData, error: HttpErrorResponse }) => {
					const mappedSpokespersons: FilterCategory[] = data.result.spokespersons.map((spokesPerson: FilterCategory) => {
						return {
							...spokesPerson,
							label: `${spokesPerson.firstName.split('')[0]} ${spokesPerson.lastName}`,
							value: spokesPerson.id
						};
					});
					data.result.spokespersons = [...mappedSpokespersons];

					return {...data.result};
				})
			);
	}

	public getEvents(calendarParams: CalendarParams, query = '?'): Observable<CalendarData> {
		const currentDateQuery = `currentDate=${calendarParams.currentDate}`;
		const groupByQuery = `&groupBy=${calendarParams.groupBy}`;
		const periodQuery = `&period=${calendarParams.period}`;
		const rangeQuery = `&range=${calendarParams.range}`;
		const selectedDate = calendarParams.selectedDate ? `&selectedDate=${calendarParams.selectedDate}` : '';
		const yearMonthQuery = calendarParams.yearMonth ? `&yearMonth=${calendarParams.yearMonth}` : '';
		const yearQuarterQuery = calendarParams.yearQuarter ? `&yearQuarter=${calendarParams.yearQuarter}` : '';
		const searchQuery = calendarParams.searchValue ? `&title=${calendarParams.searchValue}` : '';

		return this.dataService.getData(
			`${EVENTS_BY_DATES}${query}${currentDateQuery}${groupByQuery}${periodQuery}${rangeQuery}${selectedDate}${yearMonthQuery}${yearQuarterQuery}${searchQuery}`
		)
			.pipe(
				map((data: { result: CalendarData, error: HttpErrorResponse }) => {
					const isCurrentDate: number = data.result.timeslots.filter((item: CalendarTimeslot) => item.current)?.length;
					this.appHeaderService.setTodayButtonActive(!isCurrentDate);
					this.appHeaderService.setDateRangeValue(data.result.dateRangeValues);

					return data.result;
				})
			);
	}

	public getFilteredEvents(calendarParams: CalendarParams): Observable<CalendarData> {
		return this.filterService.getSelectedFilters()
			.pipe(
				switchMap((data: SelectedFilters) => {
					const filterApiQuery = this.filterService.getFiltersApiQuery(data.filterCategories);

					return this.getEvents(calendarParams, filterApiQuery);
				}),
				take(1)
			);
	}

	public createEvent(event: CalendarEvent): Observable<EventApi> {
		return this.dataService.postData(EVENTS, {
			title: event.title,
			type: event.type,
			priority: event.priority,
			spokespersonId: event.spokespersonId,
			startDate: event.startDate,
			endDate: event.endDate,
			channelId: event.channelId,
			contentId: event.contentId,
			regionId: event.regionId,
			audiences: event.audiences,
			description: event.description,
			location: event.location,
			links: event.links,
			communicationsLead: event.communicationsLead,
		} as CalendarEvent).pipe(
			tap((res: CalendarEventApi) => {
				return this.messageService.handleSuccess(
					HttpStatusEnum.CREATE_SUCCESS_EVENT_MESSAGE(res.result.title, EventsService.getMessageEventDescription(res.result)));
			})
		);
	}

	public updateEvent(event: CalendarEvent): Observable<CalendarEventApi> {
		return this.dataService.putData(EVENTS + '/' + event.id, {
			title: event.title,
			type: event.type,
			priority: event.priority,
			spokespersonId: event.spokespersonId,
			startDate: event.startDate,
			endDate: event.endDate,
			channelId: event.channelId,
			contentId: event.contentId,
			regionId: event.regionId,
			audiences: event.audiences,
			description: event.description,
			location: event.location,
			links: event.links,
			communicationsLead: event.communicationsLead,
		} as CalendarEvent);
	}

	deleteEvent(id: number): Observable<CalendarEventApi> {
		return this.dataService.deleteData(EVENTS + '/' + id).pipe(
			tap((res: CalendarEventApi) => {
				return this.messageService.handleSuccess(
					HttpStatusEnum.DELETE_SUCCESS_EVENT_MESSAGE(res.result.title, EventsService.getMessageEventDescription(res.result)));
			})
		);
	}

}

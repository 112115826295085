import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {SelectItem} from 'primeng';

@Component({
	selector: 'app-filter-priority',
	templateUrl: './filter-priority.component.html',
	styleUrls: ['./filter-priority.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FilterPriorityComponent {
	@Input() public selectedPriorities: string[] = [];
	@Input() public priorities: SelectItem[];

	@Output() public selectPriority: EventEmitter<string[]> = new EventEmitter();

	public handleModelChange(value: string[]): void {
		this.selectedPriorities = value;
		this.selectPriority.emit(this.selectedPriorities);
	}

}

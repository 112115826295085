import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OverlayPanel} from 'primeng';

import {Subscription} from 'rxjs';

import {CalendarParams} from '../../../interfaces/calendar/calendar-params';
import {AppHeaderService} from '../../../services/general/app-header.service';
import {ExportPngService} from '../../../services/exportPng/export-png.service';
import {CATEGORY_MENU_ITEM} from '../../../constants/category.constants';
import {CategoryHeaderMenuItem} from '../../../enums/api/category-header-enum';

@Component({
	selector: 'app-calendar-priority',
	templateUrl: './calendar-priority.component.html',
	styleUrls: ['./calendar-priority.component.scss']
})
export class CalendarPriorityComponent implements OnInit, OnDestroy {
	@ViewChild('overlayPanel', {static: false}) overlayPanel: OverlayPanel;

	public selectedCategory: CategoryHeaderMenuItem;
	public isReversedViewForPrint = false;

	private appHeaderServiceSub: Subscription;

	constructor(
		private exportPngService: ExportPngService,
		private appHeaderService: AppHeaderService,
		private cdr: ChangeDetectorRef,
	) {	}

	ngOnInit() {
		this.getSelectedCategory();
	}

	ngOnDestroy() {
		this.appHeaderServiceSub.unsubscribe();
	}

	private getSelectedCategory(): void {
		this.appHeaderServiceSub = this.appHeaderService.getCalendarParams().subscribe((params: CalendarParams) => {
			this.selectedCategory = CATEGORY_MENU_ITEM[params.groupBy];
		})
	}

	public closeOverlayPanel(): void {
		this.overlayPanel?.hide();
	}

	public setReverseColorsForPrint(toggle: boolean): void {
		this.isReversedViewForPrint = toggle;
		if (toggle) {
			this.cdr.detectChanges();
		}
	}
}

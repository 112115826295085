import {ElementRef, Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ScrollService {
	public checkScrollExisting(element: ElementRef<HTMLElement>): boolean {
		const scrollHeight = element.nativeElement?.scrollHeight;
		const clientHeight = element.nativeElement?.clientHeight;

		return scrollHeight > clientHeight;
	}

	public getScrollWidth(element: ElementRef<HTMLElement>): number {
		return element?.nativeElement?.offsetWidth - element?.nativeElement?.clientWidth;
	}
}

import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {ToolOptions} from '../../../interfaces/category/category-list-tool';
import {ToolEvent} from '../../../enums/tool-event-enum';
import {CategoryItem} from '../../../interfaces/category/category-item';
import {Keyword} from '../../../interfaces/keywords/keyword';
import {CategoryName} from '../../../enums/api/сategory-name-enum';

@Component({
	selector: 'app-category-list-item',
	templateUrl: './category-list-item.component.html',
	styleUrls: ['./category-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryListItemComponent {
	@Input() title: string;
	@Input() description: string;
	@Input() item: CategoryItem | Keyword;
	@Input() currentCategoryName: CategoryName;
	@Output() itemClick: EventEmitter<ToolEvent> = new EventEmitter();
	@Output() dragEnterEvent: EventEmitter<CategoryItem | Keyword> = new EventEmitter();
	@Output() dragStartEvent: EventEmitter<CategoryItem | Keyword> = new EventEmitter();
	public alternateCategoryName: CategoryName = CategoryName.AlternateCategory;

	public toolEvent = ToolEvent;

	toolOptions: ToolOptions = {
		showDown: false,
		showUp: false,
	};

	@HostListener('click') onItemClick() {
		this.itemClick.emit(ToolEvent.Edit);
	}

	@HostListener('dragenter') onDragEnter() {
		this.dragEnterEvent.emit(this.item);
	}

	@HostListener('dragstart') onDragStart() {
		this.dragStartEvent.emit(this.item);
	}

	toolClickEvent(toolEvent: ToolEvent) {
		this.itemClick.emit(toolEvent);
	}
}

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import {ToolEvent} from '../../../enums/tool-event-enum';
import {Keyword} from '../../../interfaces/keywords/keyword';
import {KeywordListEvent} from '../../../interfaces/keywords/keyword-list-event';

@Component({
	selector: 'app-keyword-list',
	templateUrl: './keyword-list.component.html',
	styleUrls: ['./keyword-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeywordListComponent {
	@Input() list: Keyword[] = [];
	@Input() keyword: Keyword;
	@Input() droppedItem: Keyword;
	@Output() keywordClick: EventEmitter<KeywordListEvent> = new EventEmitter();
	@Output() keywordAddClick: EventEmitter<any> = new EventEmitter();

	@Output() categoryItemDropEvent: EventEmitter<Keyword> = new EventEmitter();
	@Output() setDraggedItemEvent: EventEmitter<Keyword> = new EventEmitter();

	public onAddKeywordClick() {
		this.keywordAddClick.emit();
	}

	public keywordClickEvent(toolEvent: ToolEvent, keyword: Keyword) {
		this.keywordClick.emit({toolEvent, keyword})
	}

	setDroppedItem(item: Keyword) {
		this.droppedItem = item;
	}

	setDraggedItem(item: Keyword) {
		this.setDraggedItemEvent.emit(item);
	}

	onCategoryItemDropEvent() {
		this.categoryItemDropEvent.emit(this.droppedItem);
	}
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {ButtonModule, MegaMenuModule, InputTextModule} from 'primeng';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {SidebarModule} from 'primeng/sidebar';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {GeneralModule} from './views/general/general.module';
import {ManagementModule} from './views/management/management.module';
import {SharedModule} from './components/shared/shared.module';
import {HttpAuthInterceptor} from './services/http-auth-interceptor';
import {OauthTokenGuard} from './oauth/oauth-token.guard';
import {AuthenticationGuard} from './oauth/authentication.guard';
import {GuestScreenModule} from './views/guest-screen/guest-screen.module';
import {HttpErrorInterceptor} from './services/http-error.interceptor';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		MenubarModule,
		PanelMenuModule,
		SidebarModule,
		ToastModule,
		MegaMenuModule,
		GeneralModule,
		ManagementModule,
		ButtonModule,
		SharedModule,
		InputTextModule,
		HttpClientModule,
		GuestScreenModule,
	],
	providers: [
		OauthTokenGuard,
		AuthenticationGuard,
		MessageService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpAuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */
export const environment = {
	production: false,
	url: 'https://api.d01e.gcp.ford.com/commscals/',
	baseUrl: 'https://api.d01e.gcp.ford.com/commscals/api/v1/',
	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/',
	openidClientId: 'urn:comms_calendar:clientid:web_commscalendar:dev',
	openidResource: 'urn:comms_calendar:resource:web_commscalendar:dev',
	openidRedirectUrl: 'https://wwwdev.commscalendar.ford.com/oauth'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

import {CalendarView} from '../../../../enums/calendar/calendar-view-enum';
import {AppCalendarComponent} from '../app-calendar.component';

@Component({
	selector: 'app-calendar-period',
	templateUrl: './app-calendar-period.html',
	styleUrls: ['./app-calendar-period.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCalendarPeriodComponent extends AppCalendarComponent {
	@Input() public currentTime: string;
	@Input() public view: CalendarView;

	public readonly calendarView = CalendarView;

	@Output() public calendarSelect: EventEmitter<Date> = new EventEmitter();

	constructor() {super()}

	public handleCalendarSelect(event: Date): void {
		this.calendarSelect.emit(event);
	}
}

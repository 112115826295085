import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';

import {ManagementPath} from '../../../enums/management-path-enum';
import {CATEGORIES_NOTES, CATEGORY_LABELS} from '../../../constants/management.constants';
import {ManagementService} from '../../../services/management.service';

@Component({
	selector: 'app-management-search-container',
	templateUrl: './management-search-container.component.html',
	styleUrls: ['./management-search-container.component.scss']
})
export class ManagementSearchContainerComponent implements OnChanges, OnInit, OnDestroy {
	@Input() currentUrl: string;
	public searchValue: string;
	readonly categoriesNotes = CATEGORIES_NOTES;
	readonly ManagementPath = ManagementPath;
	readonly categoryLabels = CATEGORY_LABELS;
	private showAddButton = true;
	private showAddButtonSub: Subscription;

	constructor(private managementService: ManagementService) {
	}

	ngOnInit() {
		this.showAddButtonSub = this.managementService.showAddKeywordButton$.subscribe((res: boolean) => this.showAddButton = res);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.currentUrl && this.searchValue) {
			this.clearSearchClick();
		}
	}

	ngOnDestroy() {
		this.showAddButtonSub.unsubscribe();
	}

	get label(): string {
		return 'Add ' + this.categoryLabels[this.currentUrl];
	}

	get note(): string {
		return this.categoriesNotes[this.currentUrl];
	}

	get withNote(): boolean {
		return this.currentUrl === ManagementPath.AlternateCategories
			|| this.currentUrl === ManagementPath.QuickSearch;
	}

	get withSearch(): boolean {
		return this.currentUrl === ManagementPath.Channels
			|| this.currentUrl === ManagementPath.Content
			|| this.currentUrl === ManagementPath.Regions
			|| this.currentUrl === ManagementPath.SpokesPeople
			|| this.currentUrl === ManagementPath.Users
			|| this.currentUrl === ManagementPath.Audience;
	}

	get withButtons(): boolean {
		return this.withSearch
			|| (this.currentUrl === ManagementPath.QuickSearch && this.showAddButton);
	}

	get isAudience(): boolean {
		return this.currentUrl === ManagementPath.Audience;
	}

	clearSearchClick() {
		this.searchValue = null;
		this.managementService.searchEvent(this.searchValue);
	}

	onSearchUpdate(searchValue: string) {
		this.managementService.searchEvent(searchValue.trim());
	}

	addItemClick(event) {
		this.managementService.addCategoryItemEvent(event);
	}

	addAudienceTypeClick(event) {
		this.managementService.addAudienceTypeEvent(event);
	}
}

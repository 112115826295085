import {Injectable} from '@angular/core';
import {NOT_LOGGED_MESSAGE} from '../constants/user.constants';
import {UserIdService} from './user-id.service';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthSessionService {
	public isAuthenticate: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(private idService: UserIdService) {
	}

	public ADFSLogin(): void {
		const endPoint = environment.openidUrl + 'authorize' +
			'?client_id=' + environment.openidClientId +
			'&resource=' + environment.openidResource +
			'&response_type=token' +
			'&redirect_uri=' + encodeURIComponent(environment.openidRedirectUrl);
		window.location.replace(endPoint);
	}

	public ADFSLogout(): void {
		window.location.replace(environment.openidUrl + 'logout' + '?client_id=' + environment.openidClientId);
	}

	/** This function set values in sessionStorage from the given parameters
	 *
	 * @param strAccessToken string representation of the access token
	 * @param encodedAccessToken encoded JWT representation of the access token as sent on the url
	 * @param jsonToken parsed representation of the access token
	 */
	storeTokenInformation(strAccessToken, encodedAccessToken, jsonToken) {
		localStorage.setItem('strAccessToken', strAccessToken);
		localStorage.setItem('encodedAccessToken', encodedAccessToken);
		localStorage.setItem('tokenIssue', jsonToken.iat);
		localStorage.setItem('tokenExp', jsonToken.exp);
		localStorage.setItem('userId', jsonToken.CommonName);
		localStorage.setItem('username', `${jsonToken.givenname} ${jsonToken.surname}`);

		this.idService.setUserID(jsonToken.CommonName);
		this.idService.setUserName(`${jsonToken.givenname} ${jsonToken.surname}`);
	}

	/** Clear oauth session items
	 *
	 */
	clearSession() {
		localStorage.removeItem('tokenExp');
		localStorage.removeItem('tokenIssue');
		localStorage.removeItem('strAccessToken');
		localStorage.removeItem('encodedAccessToken');
		localStorage.removeItem('username');
		localStorage.removeItem('userId');

		this.idService.setUserID(NOT_LOGGED_MESSAGE);
	}

}

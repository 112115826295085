import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
	selector: 'app-priority-info',
	templateUrl: './priority-info.component.html',
	styleUrls: ['./priority-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriorityInfoComponent{
}

import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {MANAGEMENT_MENU_LIST} from '../../constants/management-routes';
import {ManagementMenu} from '../../interfaces/managment-menu';
import {PageService} from '../../services/page.service';

@Component({
	selector: 'management-view',
	templateUrl: './management-view.component.html',
	styleUrls: ['./management-view.component.scss']
})
export class ManagementViewComponent implements OnDestroy {
	readonly menuList: ManagementMenu[] = MANAGEMENT_MENU_LIST
	pageSubscription: Subscription;
	currentUrl: string;

	constructor(private pageService: PageService) {
		this.pageSubscription = this.pageService.currentUrl$.subscribe((url) => {
			this.currentUrl = url
		})
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe()
	}
}

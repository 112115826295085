import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

import DataService from '../data.service';
import {SpokesPerson} from 'src/app/interfaces/spokespeople/spokespeople';
import {ManagementPath} from 'src/app/enums/management-path-enum';
import {SelectedOrder} from 'src/app/interfaces/selected-order';
import {OrderDirection} from 'src/app/enums/order-direction-enum';
import {TableColumnValue} from 'src/app/enums/table-column-enum';
import {AppMessageService} from '../app-message.service';
import {HttpStatusEnum} from 'src/app/enums/http-status.enum';
import {SpokePersonListApi} from '../../interfaces/api/spokeperson-list-api';
import {SpokepeopleListCommon} from '../../interfaces/users/spokepeople-list-common';

const SPOKESPEOPLE: ManagementPath = ManagementPath.SpokesPeople;

const DEFAULT_SELECTED_ORDER: SelectedOrder = {
	attr: TableColumnValue.Name,
	direction: OrderDirection.Asc,
};

@Injectable({
	providedIn: 'root'
})
export class SpokesPeopleService {

	constructor(
		private dataService: DataService,
		private messageService: AppMessageService,
	) {}

	public getSpokesPeople(
		selectedOrder: SelectedOrder = DEFAULT_SELECTED_ORDER,
		pageNumber: number = 0,
		rowsAmount: number = 99999999,
		searchValue: string = ''
	): Observable<SpokepeopleListCommon> {
		const orderDirection: string = selectedOrder.direction === OrderDirection.Asc ? 'asc' : 'desc';
		const isSortByName: boolean = selectedOrder.attr === TableColumnValue.Name;
		const sortByNameQuery = `sort=lastName,firstName,${orderDirection}`;
		const sortByOtherFieldQuery = `sort=${selectedOrder.attr},${orderDirection}`;
		const sortQuery: string = isSortByName ? sortByNameQuery : sortByOtherFieldQuery;
		const searchQuery = searchValue?.length ? `&name=${searchValue}` : '';

		return this.dataService.getData(`${SPOKESPEOPLE}?${sortQuery}&page=${pageNumber - 1}&size=${rowsAmount}${searchQuery}`, {observe: 'response'})
			.pipe(
				map((data: HttpResponse<SpokePersonListApi>) => {
					const totalRecords: number = +data.headers.get('x-total-count');

					const result: SpokesPerson[] = data.body.result.map((item: SpokesPerson) => {
						return {
							...item,
							name: `${item.firstName} ${item.lastName}`,
							regionTitle: item.region?.title
						};
					});

					return {
						result,
						error: data.body.error,
						totalRecords,
					};
				})
			);
	}

	public addSpokesPerson(body: SpokesPerson): Observable<SpokesPerson> {
		return this.dataService.postData(`${SPOKESPEOPLE}`, body)
			.pipe(
				map((data: { result: SpokesPerson, error: HttpErrorResponse }) => data.result),
				tap((res: SpokesPerson) => {
					return this.messageService.handleSuccess(
						HttpStatusEnum.CREATE_SUCCESS_MESSAGE('Spokesperson', `${res.firstName} ${res.lastName}`));
				})
			);
	}

	public updateSpokesPerson(id: number, body: SpokesPerson): Observable<SpokesPerson> {
		return this.dataService.putData(`${SPOKESPEOPLE}/${id}`, body)
			.pipe(
				map((data: { result: SpokesPerson, error: HttpErrorResponse }) => data.result),
			);
	}

	public deleteSpokesPerson(id: number): Observable<SpokesPerson> {
		return this.dataService.deleteData(`${SPOKESPEOPLE}/${id}`)
			.pipe(
				map((data: { result: SpokesPerson, error: HttpErrorResponse }) => data.result),
				tap((res: SpokesPerson) => {
					return this.messageService.handleSuccess(
						HttpStatusEnum.DELETE_SUCCESS_MESSAGE('Spokesperson',`${res.firstName} ${res.lastName}`));
				})
			);
	}

	public getSpokesPersonById(id: number): Observable<SpokesPerson> {
		return this.dataService.getData(`${SPOKESPEOPLE}/${id}`)
			.pipe(
				map((data: { result: SpokesPerson, error: HttpErrorResponse }) => {
					return {
						...data.result,
						name: `${data.result.firstName} ${data.result.lastName}`,
						regionId: data.result.region?.id,
						regionTitle: data.result.region?.title,
					};
				}),
			);
	}
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, Validators} from '@angular/forms';
import {SelectItem} from 'primeng';

import {FormSidebarComponent} from '../../../shared/app-sidebars/form-sidebar/form-sidebar.component';
import {MAIL_ERR_FIELD, USER_NOT_EXIST} from '../../../../constants/field-errors';
import {UsersService} from '../../../../services/management/users.service';
import {UserRole} from '../../../../enums/api/user-role-enum';
import {User} from '../../../../interfaces/users/user';
import {CommsCalendarError} from '../../../../interfaces/comms-calendar-error';
import {COMMS_CALENDAR_ERROR} from '../../../../constants/error-codes';
import {UserType} from '../../../../enums/api/user-type-enum';
import {SidebarMode} from '../../../../enums/sidebar-mode-enum';

const uniqueError: CommsCalendarError = COMMS_CALENDAR_ERROR._602;
const emailExistError: CommsCalendarError = COMMS_CALENDAR_ERROR._603;

@Component({
	selector: 'app-user-form',
	templateUrl: './user-form.component.html',
	styleUrls: ['../../../shared/app-sidebars/form-sidebar/form-sidebar.component.scss'],
})
export class UserFormComponent extends FormSidebarComponent implements OnInit {
	private _user: User;
	@Input() set user(value: User) {
		this._user = value;

		if (value?.email) {
			this.sideForm.patchValue({
				email: value.email
			});
		}
		if (value?.role) {
			this.sideForm.patchValue({
				permission: value.role
			});
		}

		if (value?.type) {
			this.sideForm.patchValue({
				userType: value.type
			});

			if (value.type === UserType.Covisint) {
				this.sideForm.patchValue({
					userType: value.type,
					firstName: value.firstName,
					lastName: value.lastName,
					covisintLogin: value.externalId
				});
			}

		}
	}

	get user(): User {
		return this._user;
	}

	@Output() deleteClick = new EventEmitter<number>();
	@Output() saveClick = new EventEmitter<User>();

	get permissionControl(): AbstractControl {
		return this.sideForm?.get('permission');
	}

	get userTypeControl(): AbstractControl {
		return this.sideForm?.get('userType');
	}

	get firstNameControl(): AbstractControl {
		return this.sideForm?.get('firstName');
	}

	get lastNameControl(): AbstractControl {
		return this.sideForm?.get('lastName');
	}

	get covisintLoginControl(): AbstractControl {
		return this.sideForm?.get('covisintLogin');
	}

	get isCovisint(): boolean {
		return this.userTypeControl.value === UserType.Covisint;
	}

	public readonly sidebarMode = SidebarMode;
	public permissions: SelectItem[];
	public userTypes: SelectItem[];

	public userFormError(formControl: string) {
		uniqueError.message = `User already exists in system`;
		return this.requiredError(formControl, uniqueError);
	}

	ngOnInit() {
		this.permissions = UsersService.getPermissions();
		this.userTypes = UsersService.getTypes();

		this.sideForm = this.formBuilder.group({
			email: ['', [
				Validators.required,
				Validators.email,
				Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
				this.uniqueValueValidator(uniqueError),
				this.emailExistValidator(emailExistError),
			]],
			permission: [UserRole.User],
			userType: [UserType.SSO],
			firstName: [''],
			lastName: [''],
			covisintLogin: ['']
		});
	}

	public onChangeType(userType: UserType) {
		(userType === UserType.Covisint) ? this.enableRequired() : this.disableRequired();
		this.updateRequired();
	}

	public emailError(formControl: string) {
		if (this.formControls[formControl].errors?.email && this.formControls[formControl].touched) {
			return MAIL_ERR_FIELD;
		}
		if (this.formControls[formControl].errors?.notExist) {
			return USER_NOT_EXIST;
		}
	}

	public saveEvent() {
		this.user.email = this.emailControl.value;
		this.user.role = this.permissionControl.value;

		if (this.userTypeControl.value === UserType.SSO) {
			this.firstNameControl.setValue('');
			this.lastNameControl.setValue('');
			this.covisintLoginControl.setValue('');
			this.user.type = this.userTypeControl.value;
		} else {
			this.user.firstName = this.firstNameControl.value;
			this.user.lastName = this.lastNameControl.value;
			this.user.externalId = this.covisintLoginControl.value;
		}

		this.user.type = this.userTypeControl.value;
		this.saveClick.emit(this.user);
	}

	public deleteButtonEvent() {
		this.deleteClick.emit(this.user.id);
	}

	private enableRequired() {
		this.firstNameControl.setValidators([Validators.required]);
		this.lastNameControl.setValidators([Validators.required]);
		this.covisintLoginControl.setValidators([Validators.required]);
	}

	private disableRequired() {
		this.firstNameControl.clearValidators();
		this.lastNameControl.clearValidators();
		this.covisintLoginControl.clearValidators();
	}

	private updateRequired() {
		this.firstNameControl.updateValueAndValidity();
		this.lastNameControl.updateValueAndValidity();
		this.covisintLoginControl.updateValueAndValidity();
	}
}

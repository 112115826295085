import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
	selector: 'app-user-header',
	templateUrl: './user-header.component.html',
	styleUrls: ['./user-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserHeaderComponent{
	@Input() primaryTitle: string;
	@Input() secondaryTitle: string;
	@Input() avatar: string;
	@Input() headerTitle: string;
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {NOT_LOGGED_MESSAGE} from '../constants/user.constants';

/** This class is a service to handle holding and distributing user ID to be displayed
 */
@Injectable({
	providedIn: 'root'
})
export class UserIdService {

	/** The User ID to be stored from authentication and distributed to other components in the app */
	userID: BehaviorSubject<string> = new BehaviorSubject<string>(NOT_LOGGED_MESSAGE);
	userName: BehaviorSubject<string> = new BehaviorSubject<string>(NOT_LOGGED_MESSAGE);

	/** creates a new instance of the UserIdService
	 */
	constructor() {
		if (localStorage.getItem('userId')) {
			this.setUserID(localStorage.getItem('userId'));
		}
		if (localStorage.getItem('username')) {
			this.setUserName(localStorage.getItem('username'));
		}
		console.log(localStorage.getItem('username'));
	}

	/** Stores user id
	 *
	 * @param userId the user ID to be stored on in the userID property of this service
	 */
	setUserID(userId: string) {
		this.userID.next(userId);
	}

	/** gets the user ID stored in this service
	 *
	 * @returns the userID cast as an observable to be subscribed to by other components
	 */
	getUserID(): Observable<string> {
		return this.userID.asObservable();
	}

	setUserName(userName: string) {
		this.userName.next(userName);
	}

	getUserName(): Observable<string> {
		return this.userName.asObservable();
	}
}

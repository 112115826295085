import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

import {EMTY_REQ_FIELD} from '../../../../constants/field-errors';
import {DataStatus} from '../../../../enums/data-status-enum';

@Component({
	selector: 'app-delete-sidebar',
	templateUrl: './delete-sidebar.component.html',
	styleUrls: ['../form-sidebar/form-sidebar.component.scss'],
})
export class DeleteSidebarComponent {
	@Input() status: DataStatus;
	@Input() fastMode: boolean;
	@Output() back = new EventEmitter();
	@Output() delete = new EventEmitter();

	public deleteForm: UntypedFormGroup;
	public readonly DELETE_WORD = 'DELETE';
	public readonly dataStatus = DataStatus;

	get formControls() {
		return this.deleteForm?.controls;
	}

	get deleteError(): string {
		if (this.formControls['delete'].touched) {
			if (this.formControls['delete'].errors?.required) {
				return EMTY_REQ_FIELD;
			} else if (this.formControls['delete'].errors?.wordError) {
				return 'Please enter text exactly as displayed to confirm.'
			}
			return null;
		}
	}

	constructor(public formBuilder: UntypedFormBuilder) {
		this.deleteForm = this.formBuilder.group({
			delete: ['', this.fastMode ? [] : [Validators.required, this.wordValidator()]],
		});
	}

	public backEvent() {
		this.back.emit();
	}

	public deleteEvent() {
		this.delete.emit();
	}

	private wordValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			return control.value === this.DELETE_WORD ? null : ({wordError: {value: control.value}});
		};
	}

}

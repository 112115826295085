import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {SelectItem} from 'primeng';
import {UntypedFormGroup} from '@angular/forms';

@Component({
	selector: 'app-event-type',
	templateUrl: './event-type.component.html',
	styleUrls: ['./event-type.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class EventTypeComponent {
	@Input() eventTypes: SelectItem[];
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;

	@Output() changeType = new EventEmitter<SelectItem>();

	public onChangeType(item: SelectItem) {
		this.changeType.emit(item);
	}
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
	selector: 'app-input',
	templateUrl: './app-input.component.html',
	styleUrls: ['./app-input.component.scss']
})
export class AppInputComponent {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() title: string;
	@Input() placeholder: string;
	@Input() optional: boolean;
	@Input() type?: string;
	@Input() error: string;
	@Input() maxLength?: number = null;
	@Output() modelChangeEvent: EventEmitter<string> = new EventEmitter();

	public handleModelChange(event): void {
		this.modelChangeEvent.emit(event);
	}
}

import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
	selector: 'app-guest-screen',
	templateUrl: './guest-screen.component.html',
	styleUrls: ['./guest-screen.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuestScreenComponent{
	public email = 'jenoch@ford.com';
}

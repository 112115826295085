
export enum DateRangeLabel {
	Week = 'Week',
	Month = 'Month',
	Quarter = 'Quarter',
}

export enum DateRangeValue {
	Week = 'WEEK',
	Month = 'MONTH',
	Quarter = 'QUARTER',
}

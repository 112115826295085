import {Component, Input} from '@angular/core';
import {SelectItem} from 'primeng';
import {UntypedFormGroup} from '@angular/forms';

@Component({
	selector: 'app-user-permissions',
	templateUrl: './user-permissions.component.html',
	styleUrls: ['./user-permissions.component.scss'],
})
export class UserPermissionsComponent {
	@Input() permissions: SelectItem[];
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, Validators} from '@angular/forms';
import {SelectItem} from 'primeng';

import {Region} from 'src/app/interfaces/region';
import {SpokesPerson} from 'src/app/interfaces/spokespeople/spokespeople';
import {FormSidebarComponent} from '../../../shared/app-sidebars/form-sidebar/form-sidebar.component';
import {CommsCalendarError} from '../../../../interfaces/comms-calendar-error';
import {COMMS_CALENDAR_ERROR} from '../../../../constants/error-codes';

const uniqueError: CommsCalendarError = COMMS_CALENDAR_ERROR._508;

@Component({
	selector: 'app-spokespeople-sidebar-form',
	templateUrl: './spokespeople-sidebar-form.component.html',
	styleUrls: ['../../../shared/app-sidebars/form-sidebar/form-sidebar.component.scss']
})
export class SpokespeopleSidebarFormComponent extends FormSidebarComponent implements OnInit {
	private _spokesperson: SpokesPerson;
	public regionList: SelectItem[];
	public selectedItem: SelectItem;

	@Input()
	public set spokesperson(value: SpokesPerson) {
		this._spokesperson = value;

		if (value?.id) {
			this.sideForm.patchValue({
				firstName: value.firstName,
				lastName: value.lastName,
				position: value.position,
				region: value.regionId,
				avatar: value.avatar,
			});
		}
	}

	public get spokesperson(): SpokesPerson {
		return this._spokesperson;
	}

	@Input()
	public set regions(value: Region[]) {
		this.regionList = value.map((region: Region) => {
			return {label: region.title, value: region.id};
		});

		if (this.spokesperson && this.spokesperson.regionId) {
			this.selectedItem = this.regionList.find(item => item.value === this.spokesperson.regionId);
			this.regionControl.setValue(this.selectedItem.value);
		}
	}

	@Output() public deleteClick: EventEmitter<number> = new EventEmitter<number>();
	@Output() public saveClick: EventEmitter<SpokesPerson> = new EventEmitter<SpokesPerson>();

	public get firstnameControl(): AbstractControl {
		return this.sideForm?.get('firstName');
	}

	public get lastNameControl(): AbstractControl {
		return this.sideForm?.get('lastName');
	}

	public get roleControl(): AbstractControl {
		return this.sideForm?.get('position');
	}

	public get regionControl(): AbstractControl {
		return this.sideForm?.get('region');
	}

	public get avatarControl(): AbstractControl {
		return this.sideForm?.get('avatar');
	}

	public get popupHeaderTittle(): string {
		return 'Edit Spokesperson: ' + this.spokesperson.name;
	}

	public spokespeopleFormError(formControl: string) {
		return this.requiredError(formControl, uniqueError);
	}

	ngOnInit(): void {
		this.sideForm = this.formBuilder.group({
			firstName: ['', [Validators.required, this.uniqueValueValidator(uniqueError)]],
			lastName: ['', Validators.required],
			position: [''],
			region: [null],
			avatar: ['',],
		});
	}

	public onChangeSelectedItem(item: SelectItem): void {
		this.spokesperson.regionId = item.value;
	}

	public saveEvent(): void {
		this.spokesperson.firstName = this.firstnameControl.value;
		this.spokesperson.lastName = this.lastNameControl.value;
		this.spokesperson.position = this.roleControl.value;
		this.spokesperson.avatar = this.avatarControl.value;

		this.saveClick.emit(this.spokesperson);
	}

	public deleteButtonEvent(): void {
		this.deleteClick.emit(this.spokesperson.id);
	}

}

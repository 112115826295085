import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';

import {LocationsService} from '../../../../../../services/events/locations.service';
import {LocationSelectItem} from '../../../../../../interfaces/events/location-select-item';
import {DataStatus} from '../../../../../../enums/data-status-enum';

@Component({
	selector: 'app-event-location',
	templateUrl: './event-location.component.html',
	styleUrls: ['../../detail-form.scss', './event-location.component.scss']
})
export class EventLocationComponent implements OnInit {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() disabled: boolean;

	public readonly maxLength = 255;

	public tempLocationForm: UntypedFormGroup;
	public displayLocationSidebar = false;

	get eventLocationControl(): AbstractControl {
		return this.parentForm?.get(this.controlName);
	}

	get tempLocationControl(): AbstractControl {
		return this.tempLocationForm?.get('location');
	}

	get isDisableActions(): boolean {
		return this.tempLocationForm.invalid || this.tempLocationForm.pristine;
	}

	constructor(public formBuilder: UntypedFormBuilder) {
	}

	ngOnInit() {
		this.tempLocationForm = this.formBuilder.group({
			location: [''],
		});
	}

	public onChangeVisible(isShow: boolean) {
		if (!isShow) {
			this.clearForm();
		}
	}

	public setLocation() {
		this.eventLocationControl.setValue(this.tempLocationControl.value);
		this.parentForm.markAsDirty();

		this.displayLocationSidebar = false;
		this.clearForm();
	}

	public onLocationClick() {
		this.tempLocationControl.setValue(this.eventLocationControl.value);
		this.displayLocationSidebar = true;
	}

	private clearForm() {
		this.tempLocationForm.reset();
	}
}

export enum ManagementPath {
	Main = 'management',
	Audience = 'audience',
	Channels = 'channels',
	Content = 'content',
	Regions = 'regions',
	SpokesPeople = 'spokespersons',
	AlternateCategories = 'alternate-categories',
	Users = 'users',
	Account = 'account',
	QuickSearch = 'quick-search-keywords',
}

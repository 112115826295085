import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-paginator',
	templateUrl: './paginator.component.html',
	styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
	@Output() public updatedPageNumber: EventEmitter<number> = new EventEmitter();

	@Input() public pageNumber: number;
	@Input() public totalPagesAmount: number;

	public isVisibleDots = true;

	public setArrayTotalPages(): number[] {
		return Array.from({length: this.totalPagesAmount}, (x: number, i: number) =>  i + 1);
	}

	public visiblePages(): number[] {
		const totalPagesArray: number[] = this.setArrayTotalPages();
		const THREE_NUMBER = 3;
		const FOUR_NUMBER = 4;
		const FIVE_NUMBER = 5;

		this.isVisibleDots = this.totalPagesAmount > 5 && (this.pageNumber < this.totalPagesAmount - 2);

		const leftSidePageNumbers: number[] = totalPagesArray.filter((item: number) => {
			return (item >= this.pageNumber - 2) && (item < this.pageNumber);
		})

		const rightSidePageNumbers: number[] = totalPagesArray.filter((item: number) => {
			return (item <= this.pageNumber + 2) && (item >= this.pageNumber);
		})

		if (!leftSidePageNumbers.length && (this.totalPagesAmount > FOUR_NUMBER)) {
			rightSidePageNumbers.push(rightSidePageNumbers.length + 1);
			rightSidePageNumbers.push(rightSidePageNumbers.length + 1);
		}

		if (!leftSidePageNumbers.length && (this.totalPagesAmount === FOUR_NUMBER)) {
			rightSidePageNumbers.push(rightSidePageNumbers.length + 1);
		}

		if (leftSidePageNumbers.length === 1 && (this.totalPagesAmount > FOUR_NUMBER)) {
			rightSidePageNumbers.push(rightSidePageNumbers.length + 2);
		}

		if ((this.pageNumber === this.totalPagesAmount) && (this.totalPagesAmount >= FIVE_NUMBER)) {
			leftSidePageNumbers.unshift(this.pageNumber - FOUR_NUMBER, this.pageNumber - THREE_NUMBER);
		}

		if ((this.pageNumber === this.totalPagesAmount - 1) && (this.totalPagesAmount >= FIVE_NUMBER)) {
			leftSidePageNumbers.unshift(this.pageNumber - THREE_NUMBER);
		}

		return leftSidePageNumbers.concat(rightSidePageNumbers);
	}

	public get firstPage(): boolean {
		return this.pageNumber === 1;
	}

	public get lastPage(): boolean {
		return this.pageNumber === this.totalPagesAmount;
	}

	public selectPageNumber(pageNumber: number): void {
		this.pageNumber = pageNumber;

		this.updatedPageNumber.emit(this.pageNumber);
	}

	public decrementPage(): void {
		this.pageNumber--;

		this.updatedPageNumber.emit(this.pageNumber);
	}

	public incrementPage(): void {
		this.pageNumber++;

		this.updatedPageNumber.emit(this.pageNumber);
	}
}

import {Injectable} from '@angular/core';
import * as moment from 'moment';

import {BehaviorSubject, Subject} from 'rxjs';

import {HeaderCategory} from 'src/app/enums/api/category-header-enum';
import {DateRangeValue} from 'src/app/enums/api/date-range-enum';
import {CalendarParams} from 'src/app/interfaces/calendar/calendar-params';
import {CalendarSearchItem} from '../../interfaces/calendar/calendar-search-item';

export const DEFAULT_CALENDAR_PARAMS: CalendarParams = {
	currentDate: moment().format('YYYY-MM-DD'),
	groupBy: HeaderCategory.Audience,
	period: DateRangeValue.Week,
	range: 0,
};

@Injectable({
	providedIn: 'root'
})
export class AppHeaderService {
	public addEvent$: Subject<void> = new Subject();
	private todayButtonActive$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	private calendarParams$: BehaviorSubject<CalendarParams> = new BehaviorSubject(DEFAULT_CALENDAR_PARAMS);
	private dateRangeValue$: Subject<string> = new Subject();
	private calendarSearchItem$: BehaviorSubject<CalendarSearchItem> = new BehaviorSubject(null);
	private headerSearchValue$ = new BehaviorSubject('');

	public addEvent(): void {
		this.addEvent$.next();
	}

	public clearSearchValues() {
		this.setCalendarSearchItem(null);
		this.setHeaderSearchValue(null);
	}

	public setTodayButtonActive(value: boolean): void {
		this.todayButtonActive$.next(value);
	}

	public todayButtonActive(): BehaviorSubject<boolean> {
		return this.todayButtonActive$;
	}

	public setCalendarParams(value: CalendarParams): void {
		this.calendarParams$.next(value);
	}

	public getCalendarParams(): BehaviorSubject<CalendarParams> {
		return this.calendarParams$;
	}

	public setCalendarSearchItem(value: CalendarSearchItem): void {
		this.calendarSearchItem$.next(value);
	}

	public getCalendarSearchItem(): BehaviorSubject<CalendarSearchItem> {
		return this.calendarSearchItem$;
	}

	public setHeaderSearchValue(value: string): void {
		this.headerSearchValue$.next(value);
	}

	public getHeaderSearchValue(): BehaviorSubject<string> {
		return this.headerSearchValue$;
	}

	public setDateRangeValue(value: string): void {
		this.dateRangeValue$.next(value);
	}

	public getDateRangeValue(): Subject<string> {
		return this.dateRangeValue$;
	}
}

import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';

import {filter, map, switchMap} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';

import {User} from './interfaces/users/user';
import {UserRole} from './enums/api/user-role-enum';
import {GuestPath} from './enums/guest-path-enum';
import {HeaderMode} from './enums/header-mode-enum';
import {environment} from '../environments/environment';
import {PageService} from './services/page.service';
import {UserIdService} from './services/user-id.service';
import {ManagementPath} from './enums/management-path-enum';
import {AuthSessionService} from './services/auth-session.service';
import {NOT_LOGGED_MESSAGE} from './constants/user.constants';
import {LocalStorageService} from './services/local-storage/local-storage.service';
import {DeviceDetectorService} from 'ngx-device-detector';

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	/** whether we are production or not */
	envProd = environment.production;
	/** are we on the management page */
	headerMode: HeaderMode;
	menuItems: MenuItem[];
	contentManagementItem: MenuItem = {
		label: 'Admin View',
		command: () => this.navigateTo(ManagementPath.Main + '/' + ManagementPath.Audience)
	};
	signOutItem: MenuItem = {label: 'Sign Out', command: () => this.logOut()};

	public currentUser: User;
	public userName: Observable<string>;
	public isUserAuth = false;

	pageSubscription: Subscription;
	usernameSubscription: Subscription;

	public get isDesktop(): boolean {
		return this.deviceService.isDesktop();
	}

	/** constructor for setting up DI in this component */
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private readonly router: Router,
		private userIdService: UserIdService,
		private sessionService: AuthSessionService,
		private pageService: PageService,
		private authSessionService: AuthSessionService,
		private deviceService: DeviceDetectorService,
		private localStorageService: LocalStorageService,
	) {	}

	/** this class requires this method to implement the OnInit interface */
	ngOnInit() {
		const body = document.getElementsByTagName('body')[0];
		if (body) {
			this.deviceService.isDesktop() ? body.classList.add('desktop-mode') : body.classList.add('mobile-mode');
		}

		this.menuItems = [{
			label: NOT_LOGGED_MESSAGE,
			items: []
		}];
		this.userName = this.userIdService.getUserName();
		this.usernameSubscription = this.userName.subscribe((name: string) => {
			this.menuItems[0].label = name;
		});

		this.sessionService.isAuthenticate
			.pipe(
				map((value: boolean) => {
					this.isUserAuth = value;

					return value;
				}),
				filter((value: boolean) => value),
				switchMap(() => this.pageService.fullUrl$)
			)
			.subscribe((url: string) => {
				if (this.deviceService.isDesktop()) {
					this.headerMode = url.includes(ManagementPath.Main) ? HeaderMode.Management
						: url.includes(GuestPath.Main) ? HeaderMode.Guest : HeaderMode.General;
				} else {
					this.headerMode = HeaderMode.Mobile;
				}
				this.buildMenu(this.headerMode);
			});
	}

	ngOnDestroy() {
		this.pageSubscription.unsubscribe();
		this.usernameSubscription.unsubscribe();
	}

	logOut() {
		this.sessionService.clearSession();
		this.authSessionService.ADFSLogout();
	}

	navigateTo(link: string) {
		this.router.navigate([link]);
	}

	private buildMenu(headerMode: HeaderMode) {
		if (headerMode === HeaderMode.General) {
			this.menuItems[0].items = [this.contentManagementItem, this.signOutItem];
			const currentUser = this.localStorageService.getCurrentUser();

			if (currentUser.role !== UserRole.Admin) {
				this.menuItems[0].items = this.menuItems[0].items.filter((item: MenuItem) => {
					return item.label !== this.contentManagementItem.label;
				});
			}
		} else {
			this.menuItems[0].items = [this.signOutItem];
		}
	}

}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
	selector: 'app-avatar',
	templateUrl: './app-avatar.component.html',
	styleUrls: ['./app-avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAvatarComponent {
	public readonly defaultAvatarPath = 'assets/image/default-avatar.svg';

	@Input() public avatar: string;
	@Input() public size: string;
}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OverlayPanel, SelectItem} from 'primeng';
import {Observable, Subscription} from 'rxjs';

@Component({
	selector: 'app-select-preview',
	templateUrl: './app-select-preview.component.html',
	styleUrls: ['./app-select-preview.component.scss']
})
export class AppSelectPreviewComponent implements OnInit {
	@ViewChild('overlayInfoPanel', {static: false}) overlayInfoPanel: OverlayPanel;

	@Input() title: string;
	@Input() selectedItem: SelectItem;
	@Input() info: string;
	@Input() noneItem: string;
	@Input() disabled: boolean;
	@Input() error: string;
	@Input() events: Observable<void>;

	@Output() clickPreview = new EventEmitter();

	private eventsSubscription: Subscription;

	ngOnInit() {
		if (this.events) {
			this.eventsSubscription = this.events.subscribe(() => this.closeInfo());
		}
	}

	public onClickPreview() {
		this.clickPreview.emit();
	}

	public showInfo(event: Event) {
		this.overlayInfoPanel?.show(event);
	}

	public closeInfo() {
		this.overlayInfoPanel?.hide();
	}

}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PageService {
	fullUrl$ = new BehaviorSubject('');
	currentUrl$ = new BehaviorSubject('');
	urlSubscription: Subscription;

	constructor(private router: Router) {
		this.urlSubscription = router.events.subscribe(() => {
			this.fullUrl$.next(router.url)
			this.currentUrl$.next(router.url.split('/').pop())
		})
	}
}

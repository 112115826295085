import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GuestScreenComponent} from './guest-screen.component';

@NgModule({
	declarations: [GuestScreenComponent],
	imports: [
		CommonModule,
	]
})
export class GuestScreenModule {
}

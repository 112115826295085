export enum FilterCategoryLabel {
	Priority = 'priority',
	Spokesperson = 'spokesperson',
	Content = 'content',
	Channels = 'channels',
	Region = 'region',
	Audience = 'audience',
}

export enum FilterCategoryName {
	Priorities = 'priorities',
	Spokespersons = 'spokespersons',
	Contents = 'contents',
	Channels = 'channels',
	Regions = 'regions',
	Audiences = 'audiences',
}

import {Component, Input} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import * as moment from 'moment';

import {DATE_VIEW_FORMAT, MOBILE_DATE_VIEW_FORMAT} from '../../../../../../constants/calendar.constants';

@Component({
	selector: 'app-event-datepicker',
	templateUrl: './event-datepicker.component.html',
	styleUrls: ['./event-datepicker.component.scss']
})
export class EventDatepickerComponent {
	@Input() dateForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() isDesktop: boolean;
	@Input() disabled: boolean;
	public isEndDay: boolean;
	public displayDatepickerSidebar = false;

	get startDateControl(): AbstractControl {
		return this.dateForm.get('startDate');
	}

	get endDateControl(): AbstractControl {
		return this.dateForm.get('endDate');
	}

	get isMultipleEventControl(): AbstractControl {
		return this.dateForm?.get('isMultipleEvent');
	}

	get startViewDate(): string {
		return moment(this.startDateControl?.value).format(this.isDesktop ? DATE_VIEW_FORMAT : MOBILE_DATE_VIEW_FORMAT);
	}

	get endViewDate(): string {
		return moment(this.endDateControl?.value).format(this.isDesktop ? DATE_VIEW_FORMAT : MOBILE_DATE_VIEW_FORMAT);
	}

	public onDisplay(isShow: boolean) {
		this.displayDatepickerSidebar = isShow;
	}

	public onChangeStartDate(startDate: Date) {
		if ((startDate > this.endDateControl.value) && this.isMultipleEventControl.value) {
			this.endDateControl.setValue(startDate);
		}

		this.onDisplay(false);
	}

	public openCalendar(isEndDay = false) {
		this.isEndDay = isEndDay;
		this.displayDatepickerSidebar = true;
	}

	public multipleEventChange({checked}: { checked: boolean }) {
		this.endDateControl.setValue(checked ? this.startDateControl.value : null);
	}

}

import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
	selector: 'app-no-search-content',
	templateUrl: './no-search-content.component.html',
	styleUrls: ['./no-search-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoSearchContentComponent {
}

import {ManagementPath} from '../enums/management-path-enum';

const ALTERNATE_CATEGORIES_NOTE = 'Note: These categories are hard coded and cannot be edited.'
const SEARCH_KEYWORDS_NOTE = 'Note: A Maximum of 10 Keywords can be added.'

export const CATEGORIES_NOTES = {
	[ManagementPath.AlternateCategories]: ALTERNATE_CATEGORIES_NOTE,
	[ManagementPath.QuickSearch]: SEARCH_KEYWORDS_NOTE
}

export const CATEGORY_LABELS = {
	[ManagementPath.Audience]: 'Audience',
	[ManagementPath.Channels]: 'Channel',
	[ManagementPath.Content]: 'Content',
	[ManagementPath.Regions]: 'Region',
	[ManagementPath.SpokesPeople]: 'Spokesperson',
	[ManagementPath.Users]: 'User',
	[ManagementPath.QuickSearch]: 'Search Keyword'
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ManagementService {
	public searchValue$ = new BehaviorSubject('');
	public categoryItemEvent$ = new Subject();
	public audienceTypeEvent$ = new Subject();
	public showAddKeywordButton$ = new Subject();

	searchEvent(value: string) {
		this.searchValue$.next(value);
	}

	addCategoryItemEvent(event) {
		this.categoryItemEvent$.next(event);
	}

	addAudienceTypeEvent(event) {
		this.audienceTypeEvent$.next(event);
	}

	showAddKeywordButton(event) {
		this.showAddKeywordButton$.next(event);
	}
}

import {NgModule} from '@angular/core';

import {AlternateCategoriesComponent} from './alternate-categories/alternate-categories.component';
import {AppAddPanelComponent} from '../../components/management/app-add-panel/app-add-panel.component';
import {AppRoutingModule} from '../../app-routing.module';
import {AudienceCategoryFormComponent} from '../../components/management/sidebar-forms/audience-category-form/audience-category-form.component';
import {AudienceComponent} from './audience/audience.component';
import {CategoryComponent} from './category/category.component';
import {CategoryFormComponent} from '../../components/management/sidebar-forms/category-form/category-form.component';
import {CategoryListComponent} from '../../components/management/category-list/category-list.component';
import {CategoryListItemComponent} from '../../components/management/category-list-item/category-list-item.component';
import {CategoryListTableComponent} from '../../components/management/category-list-table/category-list-table.component';
import {CategoryListToolComponent} from '../../components/management/category-list-icon/category-list-tool.component';
import {CategoryTypeFormComponent} from '../../components/management/sidebar-forms/category-type-form/category-type-form.component';
import {KeywordFormComponent} from '../../components/management/sidebar-forms/keyword-form/keyword-form.component';
import {KeywordListComponent} from '../../components/management/keyword-list/keyword-list.component';
import {ManagementMenuComponent} from '../../components/management/management-menu/management-menu.component';
import {ManagementSearchContainerComponent} from '../../components/management/management-search-container/management-search-container.component';
import {ManagementTableComponent} from './management-table/management-table.component';
import {ManagementViewComponent} from './management-view.component';
import {PaginatorComponent} from '../../components/management/paginator/paginator.component';
import {QuickSearchKeywordsComponent} from './quick-search-keywords/quick-search-keywords.component';
import {SharedModule} from '../../components/shared/shared.module';
import {SpokespeopleComponent} from './spokespeople/spokespeople.component';
import {SpokespeopleSidebarFormComponent} from '../../components/management/sidebar-forms/spokespeople-sidebar-form/spokespeople-sidebar-form.component';
import {UserFormComponent} from '../../components/management/sidebar-forms/user-form/user-form.component';
import {UserHeaderComponent} from '../../components/management/user-header/user-header.component';
import {UserPermissionsComponent} from '../../components/management/user-permissions/user-permissions.component';
import {UserTypesComponent} from '../../components/management/user-types/user-types.component';
import {UsersComponent} from './users/users.component';

@NgModule({
	declarations: [
		AlternateCategoriesComponent,
		AppAddPanelComponent,
		AudienceCategoryFormComponent,
		AudienceComponent,
		CategoryComponent,
		CategoryFormComponent,
		CategoryListComponent,
		CategoryListItemComponent,
		CategoryListTableComponent,
		CategoryListToolComponent,
		CategoryTypeFormComponent,
		KeywordFormComponent,
		KeywordListComponent,
		ManagementMenuComponent,
		ManagementSearchContainerComponent,
		ManagementTableComponent,
		ManagementViewComponent,
		PaginatorComponent,
		QuickSearchKeywordsComponent,
		SpokespeopleComponent,
		SpokespeopleSidebarFormComponent,
		UserFormComponent,
		UserHeaderComponent,
		UserPermissionsComponent,
		UserTypesComponent,
		UsersComponent,
	],
	imports: [
		SharedModule,
		AppRoutingModule,
	]
})
export class ManagementModule {
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {OverlayPanel, SelectItem} from 'primeng';
import {forkJoin, Observable, Subscription} from 'rxjs';

import {DataStatus} from '../../../enums/data-status-enum';
import {SidebarMode} from '../../../enums/sidebar-mode-enum';
import {CategoryType} from 'src/app/interfaces/category/category-type';
import {CategoryItem} from 'src/app/interfaces/category/category-item';
import {CalendarEvent} from 'src/app/interfaces/events/calendar-event';
import {EventsService} from 'src/app/services/events/events.service';
import {DateRangeValue} from '../../../enums/api/date-range-enum';
import {CategoryService} from '../../../services/management/category.service';
import {AppHeaderService} from '../../../services/general/app-header.service';
import {UnsavedChangesService} from '../../../services/unsaved-changes.service';
import {CalendarCategory, CalendarData, CalendarTimeslot, CategoryTimeslot} from 'src/app/interfaces/calendar/calendar-data';
import {SpokesPeopleService} from '../../../services/management/spokesperson.service';
import {SpokesPerson} from '../../../interfaces/spokespeople/spokespeople';
import {DeviceDetectorService} from 'ngx-device-detector';
import {User} from '../../../interfaces/users/user';
import {UsersService} from '../../../services/management/users.service';
import {SpokepeopleListCommon} from '../../../interfaces/users/spokepeople-list-common';

@Component({
	selector: 'app-general-calendar',
	templateUrl: './general-calendar.component.html',
	styleUrls: ['./general-calendar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class GeneralCalendarComponent implements OnInit, OnDestroy {
	@ViewChild('overlayPanelMoreEvents', {static: false}) overlayPanelMoreEvents: OverlayPanel;

	@Input() public calendarData: CalendarData;
	@Input() public dateRangeValue: DateRangeValue;
	@Input() public showAvatar: boolean;
	@Input() public scrollEvent: Observable<void>;

	@Output() public changeEvents: EventEmitter<boolean> = new EventEmitter();

	public readonly sidebarMode = SidebarMode;
	public readonly dateRange = DateRangeValue;

	public selectedCategory: CalendarCategory;
	public selectedCategoryTooltipText: string;
	public categoryTimeslot: CategoryTimeslot;
	public selectedCalendarTimeslot: CalendarTimeslot;
	public currentEvent: CalendarEvent = {} as CalendarEvent;
	public isUnsavedData: boolean;
	public showEventDeleteSidebar: boolean;
	public showMobileGuideSidebar: boolean;

	public eventSideMode: SidebarMode = SidebarMode.Closed;
	public currentDataStatus: DataStatus = DataStatus.Loading;

	public addEventSub: Subscription;
	public closeAllSub: Subscription;

	public categoryTypes: CategoryType[];
	public spokesPeople: SpokesPerson[];
	public categoryList: CategoryItem[];
	public eventTypes: SelectItem[];

	get showEventSidebar(): boolean {
		return this.eventSideMode !== SidebarMode.Closed;
	}

	get eventSideTitle(): string {
		return this.eventSideMode === SidebarMode.Add ? `Add New Event` : this.currentEvent?.title;
	}

	get isDesktop(): boolean {
		return this.deviceService.isDesktop();
	}

	constructor(
		private categoryService: CategoryService,
		private eventService: EventsService,
		private unsavedChangesService: UnsavedChangesService,
		private spokesPeopleService: SpokesPeopleService,
		private appHeaderService: AppHeaderService,
		private deviceService: DeviceDetectorService,
		private usersService: UsersService) {
	}

	static getFullName(firstName: string, lastName: string): string {
		return (firstName ? firstName + ' ' : null) + lastName;
	}

	ngOnInit() {
		this.eventTypes = this.eventService.getEventTypes();
		this.closeAllSub = this.unsavedChangesService.closeAll().subscribe(() => {
			this.closeSidebar();
			this.clearSideData();
		});
		this.addEventSub = this.appHeaderService.addEvent$.subscribe(() => this.addEvent());
	}

	ngOnDestroy() {
		this.closeAllSub.unsubscribe();
		this.addEventSub.unsubscribe();
	}

	public handleMoreEventsClick(data: { event: Event; categoryTimeslot: CategoryTimeslot }): void {
		this.selectedCalendarTimeslot = this.calendarData.timeslots.find((calendarTimeslot: CalendarTimeslot) => {
			return calendarTimeslot.index === data.categoryTimeslot.timeslotIndex;
		});
		this.categoryTimeslot = data.categoryTimeslot;
		this.overlayPanelMoreEvents.show(data.event);
	}

	public closeOverlayPanelMoreEvents(): void {
		this.overlayPanelMoreEvents?.hide();
	}

	public addEvent() {
		this.eventSideMode = SidebarMode.Add;
		this.currentDataStatus = DataStatus.Loading;

		forkJoin([
			this.categoryService.getAudienceTypes(),
			this.spokesPeopleService.getSpokesPeople(),
			this.categoryService.getCategories(),
		])
			.subscribe(async (data: [CategoryType[], SpokepeopleListCommon, CategoryItem[]]) => {
				const [categoryTypes, spokesPeople, categoryList] = data;

				this.categoryTypes = categoryTypes;
				this.spokesPeople = spokesPeople.result;
				this.categoryList = categoryList;

				const currentUser: User = await this.getCurrentUser();

				console.log('currentUser', currentUser);

				this.currentEvent = {
					communicationsLead: GeneralCalendarComponent.getFullName(currentUser.firstName, currentUser.lastName)
				} as CalendarEvent;
				this.currentDataStatus = DataStatus.Ready;
			});
	}

	public saveEvent(calendarEvent: CalendarEvent) {
		this.currentDataStatus = DataStatus.Saving;

		if (calendarEvent.id) {
			this.eventService.updateEvent(calendarEvent).subscribe((res) => {
				console.log('updateEventResponce', res);
				this.changeEvents.emit(true);
				this.closeSidebar();
			});
		} else {
			this.eventService.createEvent(calendarEvent).subscribe((res) => {
				console.log('createEventResponce', res);
				this.changeEvents.emit(true);
				this.closeSidebar();
			});
		}

	}

	public deleteEvent(id: number) {
		this.currentDataStatus = DataStatus.Saving;
		this.eventService.deleteEvent(id)
			.subscribe(() => {
				this.changeEvents.emit(true);
				this.eventSideMode = SidebarMode.Closed;
				this.showEventDeleteSidebar = false;
				this.clearSideData();
			});
	}

	public closeSidebar(isUnsavedData?: boolean): void {
		if (isUnsavedData) {
			this.isUnsavedData = true;
			return;
		}
		this.eventSideMode = SidebarMode.Closed;
		this.clearSideData();
	}

	public closeEventDeleteSidebar() {
		this.showEventDeleteSidebar = false;
		this.currentDataStatus = DataStatus.Ready;
	}

	public changeMobileGuideSidebar(isShow: boolean) {
		this.showMobileGuideSidebar = isShow;
	}

	public openEventDeleteSidebar() {
		this.showEventDeleteSidebar = true;
		this.currentDataStatus = DataStatus.Loading;
	}

	public openEvent(id: number) {
		this.eventSideMode = SidebarMode.Edit;
		this.currentDataStatus = DataStatus.Loading;

		forkJoin([
			this.categoryService.getAudienceTypes(),
			this.categoryService.getCategories(),
			this.spokesPeopleService.getSpokesPeople(),
			this.eventService.getEventById(id),
		])
			.subscribe((data: [CategoryType[], CategoryItem[], SpokepeopleListCommon, CalendarEvent]) => {
				const [categoryTypes, categoryList, spokesPeople, calendarEvent] = data;

				this.categoryTypes = categoryTypes;
				this.categoryList = categoryList;
				this.spokesPeople = spokesPeople.result;
				this.currentEvent = calendarEvent;
				this.currentDataStatus = DataStatus.Ready;
			});
	}

	private async getCurrentUser(): Promise<User> {
		return await this.usersService.getCurrentUser().toPromise();
	}

	private clearSideData() {
		this.currentDataStatus = DataStatus.Loading;
		this.currentEvent = null;
		this.categoryTypes = null;
		this.categoryList = null;
	}

}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import * as moment from 'moment';

import {QuarterData} from '../../../../interfaces/calendar/quarter-data';

@Component({
	selector: 'app-quarter-calendar',
	templateUrl: './quarter-calendar.component.html',
	styleUrls: ['./quarter-calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCalendarComponent implements OnInit {
	public quarters: QuarterData[] = [
		{
			label: 'Q1',
			value: 1,
			firstMonth: 0,
		},
		{
			label: 'Q2',
			value: 2,
			firstMonth: 3,
		},
		{
			label: 'Q3',
			value: 3,
			firstMonth: 6,
		},
		{
			label: 'Q4',
			value: 4,
			firstMonth: 9,
		}
	];

	@Input() public selectedDateRange: string;
	@Input() public currentTime: string;
	@Input() public minDate: Date;
	@Input() public maxDate: Date;

	public currentYear: number = moment().year();
	public selectedYear: number;
	public selectedYears: [number, number];

	@Output() public selectedDate: EventEmitter<Date> = new EventEmitter();

	ngOnInit(): void {
		this.selectedYear = moment(this.currentTime).year();
		this.selectedYears = (this.currentYear - this.selectedYear) % 2
							? [this.selectedYear - 1, this.selectedYear]
							: [this.selectedYear, this.selectedYear + 1];
	}

	public handlePrevClick(): void {
		const num = (this.currentYear - this.selectedYear) % 2 ? 3 : 2;
		this.selectedYear = this.selectedYear - num;
		this.selectedYears = [this.selectedYear, this.selectedYear + 1];
	}
	public handleNextClick(): void {
		const num = (this.currentYear - this.selectedYear) % 2 ? 1 : 2;
		this.selectedYear = this.selectedYear + num;
		this.selectedYears = [this.selectedYear, this.selectedYear + 1];
	}

	public getActiveQuarter(quarter: string, year: number): boolean {
		return this.selectedDateRange === `${quarter} ${year}`;
	}

	public checkValidDates(quarter: QuarterData, year: number): boolean {
		return this.checkValidMinDates(quarter, year) || this.checkValidMaxDates(quarter, year);
	}

	public handleQuarterClick(quarter: number, year: number): void {
		const date: Date = moment().set({ year, quarter }).toDate();

		this.selectedDate.emit(date);
	}
	private checkValidMinDates(quarter: QuarterData, year: number): boolean {
		const date: Date = moment().set({
			year,
			quarter: quarter.value
		}).toDate();
		const diffMinDate = moment(date).diff(this.minDate);

		return diffMinDate < 0;
	}

	private checkValidMaxDates(quarter: QuarterData, year: number): boolean {
		const date: Date = moment().set({
			year,
			quarter: quarter.value,
			month: quarter.firstMonth,
			date: 1,
			hour: 0,
			minute: 0,
			second: 0,
			millisecond: 0,
		}).toDate();
		const diffMaxDate = moment(this.maxDate).diff(date);

		return diffMaxDate < 0;
	}

}

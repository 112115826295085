import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import * as moment from 'moment';

import {DatesRange} from '../../interfaces/calendar/dates-range';
import DataService from '../data.service';
import {GeneralPath} from 'src/app/enums/general-path-enum';
import {DATE_API_FORMAT} from '../../constants/calendar.constants';

const DATES: GeneralPath = GeneralPath.Dates;

@Injectable({
	providedIn: 'root'
})
export class DatesService {
	private currentDate: string = moment().format(DATE_API_FORMAT);

	constructor(
		private dataService: DataService,
	) { }

	public getDates(): Observable<DatesRange> {
		return this.dataService.getData(`${DATES}/range?currentDate=${this.currentDate}`)
			.pipe(
				map((data: { result: DatesRange; error: HttpErrorResponse }) => {
					return data.result;
				})
			)
	}
}

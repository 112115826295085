import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {CategoryType} from '../../../../../../interfaces/category/category-type';
import {CategoryItem} from '../../../../../../interfaces/category/category-item';
import {CategoryTypeSelect} from '../../../../../../interfaces/category/category-type-select';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
	selector: 'app-audience-list',
	templateUrl: './audience-list.component.html',
	styleUrls: ['./audience-list.component.scss']
})
export class AudienceListComponent implements OnInit {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() selectedPrimary: CategoryItem;
	@Input() categoryTypes: CategoryType[];
	@Input() disabled: boolean;

	@Output() deleteSecondaryAudience = new EventEmitter<number>();
	@Output() addAudience = new EventEmitter<CategoryItem>();
	@Output() changeAudience = new EventEmitter<{ item: CategoryItem; orderId: number }>();

	public categoryTypesSelect: CategoryTypeSelect[];
	public selectedSecondaryAudience: CategoryItem = null;
	public orderId: number = null;
	public displayAudienceSidebar = false;
	public isPrimary = true;
	public isAddMode = true;
	public tempSecondaryAudienceForm: UntypedFormGroup;

	get secondaryAudiencesControl(): AbstractControl {
		return this.parentForm?.get('secondaryAudiences');
	}

	get tempSecondaryAudienceControl(): AbstractControl {
		return this.tempSecondaryAudienceForm?.get('tempSecondaryAudience');
	}

	ngOnInit() {
		this.tempSecondaryAudienceForm = new UntypedFormGroup({
			tempSecondaryAudience: new UntypedFormControl([]),
		})

		this.categoryTypesSelect = this.initCatTypeWithDisable();
	}

	public onPrimaryItemClick() {
		this.isPrimary = true;
		this.displayAudienceSidebar = true;
	}

	public onSecondaryItemClick(categoryId: number, secondaryAudience: CategoryItem, orderId: number) {
		this.tempSecondaryAudienceControl.setValue(categoryId);
		this.selectedSecondaryAudience = secondaryAudience;
		this.orderId = orderId;
		this.isPrimary = false;

		this.displayAudienceSidebar = true;
	}

	public onItemDeleteClick(id: number, orderId: number) {
		this.deleteSecondaryAudience.emit(orderId);

		return this.categoryTypesSelect = this.categoryTypes.map((ct: CategoryTypeSelect) => {
			ct.categories = ct.categories.map(cat => {
				if (cat.id === id) {
					cat.disabled = false;
				}
				return cat;
			});
			return ct;
		})
	}

	public addSecondaryAudience() {
		this.tempSecondaryAudienceControl.setValue(null);
		this.isPrimary = false;
		this.isAddMode = true;
		this.displayAudienceSidebar = true;
	}

	public onChangePrimaryAudience(item: CategoryItem) {
		this.categoryTypesSelect = this.getCatTypeWithDisable(item, this.selectedPrimary?.id, this.isAddMode);
		this.selectedPrimary = item;
		this.closeAudienceSidebar();
	}

	public onChangeSecondaryAudience(item: CategoryItem) {
		this.categoryTypesSelect = this.getCatTypeWithDisable(item, this.selectedSecondaryAudience?.id, this.isAddMode);
		this.selectedSecondaryAudience = null;

		this.isAddMode
			? this.addAudience.emit(item)
			: this.changeAudience.emit({item, orderId: this.orderId});

		this.closeAudienceSidebar();
		this.tempSecondaryAudienceControl.setValue(null);
		this.orderId = null;
		this.isAddMode = false;
	}

	private getCatTypeWithDisable(catItem: CategoryItem, selectedAudienceId: number, isAddMode: boolean): CategoryTypeSelect[] {
		return this.categoryTypesSelect.map(ct => {
			ct.categories = ct.categories.map(cat => {
					if (cat.id === catItem?.id) {
						cat.disabled = true;
					} else if (!isAddMode && cat.id === selectedAudienceId) {
						cat.disabled = false;
					}

					return cat;
				}
			);
			return ct;
		})
	}

	private initCatTypeWithDisable(): CategoryTypeSelect[] {
		const selectedIds = {};

		if (this.selectedPrimary) {
			selectedIds[this.selectedPrimary.id] = true;
		}

		if (this.secondaryAudiencesControl?.value) {
			this.secondaryAudiencesControl?.value.forEach((audience: CategoryItem) => {
				selectedIds[audience.id] = true
			})
		}

		return this.categoryTypes?.map((ct: CategoryTypeSelect) => {
			ct.categories.map(cat => {
				if (selectedIds[cat.id]) {
					cat.disabled = true
				}
				return cat;
			});
			return ct;
		})
	}

	private closeAudienceSidebar() {
		this.displayAudienceSidebar = false;
		this.parentForm?.get(this.controlName)?.markAsTouched();
	}
}

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';

import {CategoryItem} from '../../../interfaces/category/category-item';
import {ToolEvent} from '../../../enums/tool-event-enum';
import {CategoryListEvent} from '../../../interfaces/category/category-list-event';
import {CategoryType} from '../../../interfaces/category/category-type';
import {CategoryTypeEvent} from '../../../interfaces/category/category-type-event';
import {ToolOptions} from '../../../interfaces/category/category-list-tool';

@Component({
	selector: 'app-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryListComponent implements OnInit {
	@Input() currentIndex: number;
	@Input() lastIndex: number;
	@Input() list: CategoryItem[] = [];
	@Input() categoryType: CategoryType;
	@Output() categoryClick: EventEmitter<CategoryListEvent> = new EventEmitter();
	@Output() categoryTypeClick: EventEmitter<CategoryTypeEvent> = new EventEmitter();
	@Output() categoryItemDropEvent: EventEmitter<CategoryItem> = new EventEmitter();
	@Output() addPanelDropEvent: EventEmitter<CategoryType> = new EventEmitter();
	@Output() setDraggedItemEvent: EventEmitter<CategoryItem> = new EventEmitter();
	@Output() addAudienceClickEvent: EventEmitter<number> = new EventEmitter();
	private droppedItem: CategoryItem;
	toolOptions: ToolOptions;

	ngOnInit() {
		this.setToolOptions();
	}

	private setToolOptions() {
		this.toolOptions = {
			showDown: this.currentIndex < this.lastIndex,
			showUp: this.currentIndex > 0
		}
	}

	onAddAudienceClick(event: number) {
		this.addAudienceClickEvent.emit(event);
	}

	categoryClickEvent(toolEvent: ToolEvent, categoryItem: CategoryItem) {
		this.categoryClick.emit({toolEvent, categoryItem})
	}

	categoryTypeClickEvent(toolEvent: ToolEvent, categoryType: CategoryType) {
		this.categoryTypeClick.emit({toolEvent, categoryType});
	}

	setDroppedItem(item: CategoryItem) {
		this.droppedItem = item;
	}

	setDraggedItem(item: CategoryItem) {
		this.setDraggedItemEvent.emit(item);
	}

	onAddPanelDrop(categoryType: CategoryType) {
		this.addPanelDropEvent.emit(categoryType);
	}

	onCategoryItemDropEvent() {
		this.categoryItemDropEvent.emit(this.droppedItem);
	}
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {SelectItem} from 'primeng';

@Component({
	selector: 'app-radiogroup',
	templateUrl: './app-radiogroup.component.html',
	styleUrls: ['./app-radiogroup.component.scss']
})
export class AppRadiogroupComponent {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() noneItem: string;
	@Input() items: SelectItem[] = [];
	@Input() selectedItem: SelectItem;
	@Output() selectItemChange = new EventEmitter<SelectItem>();

	onChangeSelectItem(item: SelectItem) {
		this.selectItemChange.emit(item);
	}

}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {switchMap} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';

import {FilterAction} from 'src/app/enums/filter/filter-action-enum';
import {AppHeaderService} from '../../../services/general/app-header.service';
import {FilterService, SelectedFilters} from 'src/app/services/filter/filter.service';

@Component({
	selector: 'app-calendar-result',
	templateUrl: './calendar-result.component.html',
	styleUrls: ['./calendar-result.component.scss']
})
export class CalendarResultComponent implements OnInit, OnDestroy {
	public filterActionSub: Subscription;

	@Input() searchValue: string;
	public selectedFiltersAmount: number;
	public filterEventAction: FilterAction;

	public readonly filterAction = FilterAction;

	constructor(
		private filterService: FilterService,
		private appHeaderService: AppHeaderService,
	) {
	}

	ngOnInit(): void {
		this.filterActionSub = this.filterService.getSelectedFilters()
			.pipe(
				switchMap((value: SelectedFilters) => {
					this.selectedFiltersAmount = value.selectedFiltersAmount;

					return value.selectedFiltersAmount ? this.filterService.getFilterAction() : of(null);
				})
			)
			.subscribe((value: FilterAction) => {
				this.filterEventAction = value;
			});
	}

	ngOnDestroy(): void {
		this.filterActionSub.unsubscribe();
	}

	public handleClearFilters(): void {
		this.filterService.setFilterAction(FilterAction.ClearAll);
	}

	public handleClearSearch(): void {
		this.appHeaderService.clearSearchValues();
	}
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from 'primeng';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
	selector: 'app-select',
	templateUrl: './app-select.component.html',
	styleUrls: ['./app-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSelectComponent {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() noneItem: string;
	@Input() selectedItem: SelectItem;
	@Input() disabled: boolean;
	@Input() error: string;

	@Input() title: string;
	@Input() events: Observable<void>;
	@Input() info: string;
	@Input() parent;
	@Input() items: SelectItem[];
	@Output() selectItemChange = new EventEmitter<SelectItem>();

	public displaySelectSidebar = false;

	get typeControl(): AbstractControl {
		return this.parentForm?.get('categoryType');
	}

	public onDisplaySelect(isShow: boolean) {
		this.displaySelectSidebar = isShow;
	}

	public onChangeSelectItem(item: SelectItem) {
		this.parentForm?.get(this.controlName)?.markAsTouched();
		this.selectedItem = item;
		this.selectItemChange.emit(item);
		this.onDisplaySelect(false);
	}

	visibleChangeHandler() {
		if (!this.typeControl) {
			return;
		}

		this.typeControl.markAsTouched();
	}
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {CategoryService} from '../../../services/management/category.service';
import {CategoryItem} from '../../../interfaces/category/category-item';
import {CategoryName} from '../../../enums/api/сategory-name-enum';

@Component({
	selector: 'alternate-categories',
	templateUrl: './alternate-categories.component.html',
	styleUrls: ['./alternate-categories.component.scss']
})
export class AlternateCategoriesComponent implements OnInit, OnDestroy {
	public currentCategoryName: CategoryName = CategoryName.AlternateCategory;
	categories: CategoryItem[];
	subscription: Subscription;

	constructor(private categoryService: CategoryService) {
	}

	ngOnInit() {
		this.subscription = this.categoryService.getCategories(CategoryName.AlternateCategory)
			.subscribe((data: CategoryItem[]) => {
				this.categories = data;
			});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

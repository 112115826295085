import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

import * as moment from 'moment';

import {DATE_MONTH, DATE_MONTH_DAY_YEAR} from '../../../../constants/calendar.constants';

@Component({
	selector: 'app-calendar-event',
	templateUrl: './calendar-event.component.html',
	styleUrls: ['./calendar-event.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CalendarEventComponent {
	@Input() public event: any;

	@Output() public clickEvent: EventEmitter<boolean> = new EventEmitter();

	public handleClick(): void {
		this.clickEvent.emit(true);
	}

	public tooltipText(): string {
		const date = this.getDate(this.event.startDate, this.event.endDate);
		const location = this.event.location ? `<span></span>${this.event.location}` : '';
		const description = `${date}${location}`;
		return `<div>${this.event.title}<div><div class="tooltip-event-description">${description}</div>`;
	}

	private getDate(startDate, endDate) {
		const dayStartDate = moment(startDate).date();
		const dayEndDate = moment(endDate).date();
		const monthStartDate = moment(startDate).format(DATE_MONTH);
		const monthEndDate = moment(endDate).format(DATE_MONTH);
		const yearStartDate = moment(startDate).year();
		const yearEndDate = moment(endDate).year();

		if (dayStartDate !== dayEndDate && monthStartDate === monthEndDate) {
			return `${monthStartDate} ${dayStartDate} - ${dayEndDate}, ${yearStartDate}`;
		} else if (monthStartDate !== monthEndDate && yearStartDate === yearEndDate) {
			return `${monthStartDate} ${dayStartDate} - ${monthEndDate} ${dayEndDate}, ${yearStartDate}`
		} else if (yearStartDate !== yearEndDate) {
			return `${monthStartDate} ${dayStartDate}, ${yearStartDate} - ${monthEndDate} ${dayEndDate}, ${yearEndDate}`
		}

		return moment(startDate).format(DATE_MONTH_DAY_YEAR);
	}
}

import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

import {SidebarMode} from '../../../../enums/sidebar-mode-enum';
import {DataStatus} from '../../../../enums/data-status-enum';
import {EMTY_REQ_FIELD} from '../../../../constants/field-errors';
import {UnsavedChangesService} from 'src/app/services/unsaved-changes.service';
import {ErrorApi} from '../../../../interfaces/api/error-api';
import {CategoryConfigData} from '../../../../interfaces/category/categoty-config-data';
import {CommsCalendarError} from '../../../../interfaces/comms-calendar-error';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
	selector: 'form-sidebar',
	templateUrl: './form-sidebar.component.html',
	styleUrls: ['./form-sidebar.component.scss']
})
export class FormSidebarComponent implements OnDestroy, OnChanges {
	@Input() categoryConfig: CategoryConfigData;
	public cancelClickSub: Subscription;
	private _status;
	@Input() set status(value: DataStatus) {
		this._status = value;
		this.resetForm(this.mode);
		this.disableForm(value);
	}

	get status(): DataStatus {
		return this._status;
	}

	get isUnsavedData(): boolean {
		return this.mode === SidebarMode.Edit && this.sideForm.dirty
			|| this.mode === SidebarMode.Add && this.sideForm.dirty;
	}

	@Input() mode: SidebarMode;
	@Input() requestError: ErrorApi;

	@Output() inputChangeEvent: EventEmitter<string> = new EventEmitter();
	@Output() cancelClick = new EventEmitter();

	public sideForm: UntypedFormGroup;
	public readonly dataStatus = DataStatus;

	public get isDesktop(): boolean {
		return this.deviceService.isDesktop();
	}

	get firstnameControl(): AbstractControl {
		return this.sideForm?.get('firstName');
	}

	get titleControl(): AbstractControl {
		return this.sideForm?.get('title');
	}

	get emailControl(): AbstractControl {
		return this.sideForm?.get('email');
	}

	get showContent(): boolean {
		return this.status === DataStatus.Ready || this.status === DataStatus.Saving;
	}

	get showLoader(): boolean {
		return this.status === DataStatus.Loading;
	}

	get isDisableActions(): boolean {
		return this.status === DataStatus.Saving || this.status === DataStatus.Loading || this.sideForm.invalid || this.sideForm.pristine;
	}

	get isDisabledDelete(): boolean {
		return this.status === DataStatus.Saving || this.status === DataStatus.Loading;
	}

	get formControls() {
		return this.sideForm.controls;
	}

	public requiredError(formControl: string, requiredErrorModel?: CommsCalendarError) {
		if (this.formControls[formControl]?.errors?.notUnique) {
			if (this.requestError.errorCode === requiredErrorModel.errorCode) {
				return requiredErrorModel.message;
			}
		}
		if (this.formControls[formControl]?.errors?.required && this.formControls[formControl].touched) {
			return EMTY_REQ_FIELD;
		}
	}

	get isAddMode(): boolean {
		return this.mode === SidebarMode.Add;
	}

	constructor(
		public formBuilder: UntypedFormBuilder,
		public unsavedChangesService: UnsavedChangesService,
		private deviceService: DeviceDetectorService,
	) {
		this.sideForm = this.formBuilder.group({
			title: ['', Validators.required],
		});
		this.cancelClickSub = this.unsavedChangesService.cancelClick$.subscribe(() => this.cancelEvent());
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.requestError) {
			this.titleControl?.updateValueAndValidity();
			this.emailControl?.updateValueAndValidity();
			this.firstnameControl?.updateValueAndValidity();
		}
	}

	ngOnDestroy() {
		this.cancelClickSub.unsubscribe();
		this.inputChangeEvent.emit(null);
	}

	onInputValueChange(value) {
		this.inputChangeEvent.emit(value);
	}

	public cancelEvent() {
		this.unsavedChangesService.setIsUnsavedChanges(this.isUnsavedData);
		this.cancelClick.emit(this.isUnsavedData);
	}

	public disableInput(value: DataStatus, formControl: string) {
		if (!this.formControls[formControl]) {
			return;
		}

		value === DataStatus.Ready ? this.formControls[formControl].enable() : this.formControls[formControl].disable();
	}

	public disableForm(value: DataStatus) {
		if (!this.sideForm) {
			return;
		}

		value === DataStatus.Ready ? this.sideForm.enable() : this.sideForm.disable();
	}

	public resetForm(value: SidebarMode): void {
		if (!this.sideForm || value !== SidebarMode.Closed) {
			return;
		}

		this.sideForm.reset();
	}

	public uniqueValueValidator(error: CommsCalendarError): ValidatorFn {
		return (): ValidationErrors | null => {
			return this.requestError?.errorCode === error.errorCode ? {notUnique: true} : null;
		};
	}

	public emailExistValidator(error: CommsCalendarError): ValidatorFn {
		return (): ValidationErrors | null => {
			return this.requestError?.errorCode === error.errorCode ? {notExist: true} : null;
		};
	}
}

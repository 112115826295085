import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
	selector: 'app-add-title',
	templateUrl: './add-title.component.html',
	styleUrls: ['./add-title.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddTitleComponent {
	@Input() title: string;
	@Input() disabled: boolean;
	@Output() addClick = new EventEmitter();

	public handleAddClick() {
		this.addClick.emit();
	}
}

import {Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Dropdown} from 'primeng';
import {LocationSelectItem} from '../../../interfaces/events/location-select-item';
import {DataStatus} from '../../../enums/data-status-enum';

@Component({
	selector: 'app-dropdown',
	templateUrl: './app-dropdown.component.html',
	styleUrls: ['./app-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AppDropdownComponent {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() title: string;
	@Input() placeholder: string;
	@Input() maxlength: number;
	@Input() options: LocationSelectItem[];
	@Input() status: DataStatus;
	@Output() changeItem: EventEmitter<string> = new EventEmitter();

	@ViewChild('dropdown', {static: false}) dropdown: Dropdown;

	public readonly dataStatus = DataStatus;

	get valueControl(): string {
		return this.parentForm?.get(this.controlName)?.value;
	}

	public onChangeItem(event) {
		this.changeItem.emit(event.value);
		if (event.value) {
			this.dropdown.show();
		} else this.dropdown.hide(event);
	}

	public onFocus() {
		if (this.valueControl) {
			this.changeItem.emit(this.valueControl);
			this.dropdown.show();
		}
	}

}

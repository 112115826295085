import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
	selector: 'app-link-item',
	templateUrl: './link-item.component.html',
	styleUrls: ['./link-item.component.scss']
})
export class LinkItemComponent {
	@Input() title: string;
	@Input() disabled: boolean;
	@Input() link: string;
	@Output() itemClick = new EventEmitter();
	@Output() deleteClick = new EventEmitter();

	public displaySelectSidebar = false;

	public onItemClick() {
		this.itemClick.emit();
	}

	public onItemDelete() {
		this.deleteClick.emit();
	}
}

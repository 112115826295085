import {Component, Input} from '@angular/core';
import {HeaderMode} from 'src/app/enums/header-mode-enum';

@Component({
	selector: 'app-overlay-loader',
	templateUrl: './app-overlay-loader.component.html',
	styleUrls: ['./app-overlay-loader.component.scss']
})
export class AppOverlayLoaderComponent {
	@Input() enabled: boolean;
	@Input() view: HeaderMode = HeaderMode.Management;

	public readonly mode = HeaderMode;
	public invokedByPngDownload = false;
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import DataService from '../data.service';
import {CategoryListApi} from '../../interfaces/api/category-list-api';
import {CategoryTypeListApi} from '../../interfaces/api/category-type-list-api';
import {CategoryType} from '../../interfaces/category/category-type';
import {CategoryTypeApi} from '../../interfaces/api/category-type-api';
import {CategoryItemApi} from '../../interfaces/api/category-item-api';
import {CategoryItem} from '../../interfaces/category/category-item';
import {CategoryName} from '../../enums/api/сategory-name-enum';
import {HttpStatusEnum} from '../../enums/http-status.enum';
import {CategoryConfigData} from '../../interfaces/category/categoty-config-data';
import {AppMessageService} from '../app-message.service';

const CATEGORIES = 'categories';
const CATEGORIES_BY_TYPE = 'categories-by-types';
const CATEGORY_TYPE = 'category-types';

@Injectable({
	providedIn: 'root'
})
export class CategoryService {

	constructor(
		private dataService: DataService,
		private messageService: AppMessageService) {
	}

	getCategoryTypeById(id: number): Observable<CategoryType> {
		return this.dataService.getData(CATEGORY_TYPE + '/' + id).pipe(map((data: CategoryTypeApi) => data.result));
	}

	getCategoryById(id: number): Observable<CategoryItem> {
		return this.dataService.getData(CATEGORIES + '/' + id).pipe(map((data: CategoryItemApi) => data.result));
	}

	getCategories(catName: CategoryName = null): Observable<CategoryItem[]> {
		const catQuery = catName ? ('?name=' + catName) : '';
		return this.dataService.getData(CATEGORIES + catQuery)
			.pipe(
				map((data: CategoryListApi) => data.result)
			);
	}

	getCategoriesTypesByName(categoryName: CategoryName) {
		return this.dataService.getData(CATEGORIES_BY_TYPE + '?name=' + categoryName)
			.pipe(map((data: CategoryTypeListApi) => data.result));
	}

	searchCategoriesByTitle(catName: CategoryName, title: string): Observable<CategoryItem[]> {
		return this.dataService.getData(CATEGORIES + '?name=' + catName + '&title=' + title)
			.pipe(map((data: CategoryListApi) => data.result));
	}

	getAudienceTypes(): Observable<CategoryType[]> {
		return this.dataService.getData(CATEGORIES_BY_TYPE + '?name=' + CategoryName.Audience)
			.pipe(map((data: CategoryTypeListApi) => data.result));
	}

	searchAudienceByTitle(title: string): Observable<CategoryType[]> {
		return this.dataService.getData(CATEGORIES_BY_TYPE + '?name=' + CategoryName.Audience + '&title=' + title)
			.pipe(map((data: CategoryTypeListApi) => data.result));
	}

	addCategoryType(title: string): Observable<CategoryType> {
		return this.dataService.postData(CATEGORY_TYPE, {
			title
		} as CategoryType).pipe(
			tap((res: CategoryItemApi) => {
				return this.messageService.handleSuccess(
					HttpStatusEnum.CREATE_SUCCESS_MESSAGE('Audience Type', res.result.title));
			})
		);
	}

	updateCategoryTypeById(catType: CategoryType): Observable<CategoryType> {
		return this.dataService.putData(CATEGORY_TYPE + '/' + catType.id, {
			position: catType.position,
			title: catType.title
		} as CategoryType);
	}

	deleteAudienceType(id: number): Observable<CategoryType> {
		return this.dataService.deleteData(CATEGORY_TYPE + '/' + id).pipe(
			tap((res: CategoryTypeApi) => {
				return this.messageService.handleSuccess(
					HttpStatusEnum.DELETE_SUCCESS_MESSAGE('Audience Type', res.result.title));
			})
		);
	}

	deleteCategory(id: number, conf: CategoryConfigData): Observable<CategoryItem> {
		return this.dataService.deleteData(CATEGORIES + '/' + id).pipe(
			tap((res: CategoryItemApi) => {
				return this.messageService.handleSuccess(
					HttpStatusEnum.DELETE_SUCCESS_MESSAGE(conf.CATEGORY, res.result.title));
			})
		);
	}

	addCategory(cat: CategoryItem, conf: CategoryConfigData): Observable<CategoryItem> {
		return this.dataService.postData(CATEGORIES, {
			title: cat.title,
			description: cat.description,
			typeId: cat.typeId,
			name: conf.CATEGORY_NAME
		} as CategoryItem).pipe(
			tap((res: CategoryItemApi) => {
				return this.messageService.handleSuccess(
					HttpStatusEnum.CREATE_SUCCESS_MESSAGE(conf.CATEGORY, res.result.title));
			})
		);
	}

	updateCategory(cat: CategoryItem): Observable<CategoryItem> {
		return this.dataService.putData(CATEGORIES + '/' + cat.id, {
			title: cat.title,
			description: cat.description,
			typeId: cat.typeId,
			position: cat.position,
			name: cat.name
		} as CategoryItem);
	}
}

import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {OverlayPanel, SelectItem} from 'primeng';
import {UntypedFormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';

@Component({
	selector: 'app-event-priority',
	templateUrl: './event-priority.component.html',
	styleUrls: ['./event-priority.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class EventPriorityComponent implements OnInit {
	@ViewChild('overlayInfoPanel', {static: false}) overlayInfoPanel: OverlayPanel;

	@Input() priorities: SelectItem[];
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() disabled: boolean;
	@Input() events: Observable<void>;

	private eventsSubscription: Subscription;

	ngOnInit() {
		if (this.events) {
			this.eventsSubscription = this.events.subscribe(() => this.closeInfo());
		}
	}

	public showInfo(event: Event) {
		this.overlayInfoPanel?.show(event);
	}

	public closeInfo() {
		this.overlayInfoPanel?.hide();
	}
}

import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';

import {SelectItem} from 'primeng';

import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {User} from '../../interfaces/users/user';
import {UserApi} from '../../interfaces/api/user-api';
import {UserType} from '../../enums/api/user-type-enum';
import {UserRole} from '../../enums/api/user-role-enum';
import DataService from '../data.service';
import {UserListApi} from 'src/app/interfaces/api/user-list-api';
import {SelectedOrder} from 'src/app/interfaces/selected-order';
import {ManagementPath} from 'src/app/enums/management-path-enum';
import {OrderDirection} from 'src/app/enums/order-direction-enum';
import {UserListCommon} from 'src/app/interfaces/users/user-list-common';
import {HttpStatusEnum} from '../../enums/http-status.enum';
import {TableColumnValue} from 'src/app/enums/table-column-enum';
import {AppMessageService} from '../app-message.service';
import {LocalStorageService} from '../local-storage/local-storage.service';

const USERS: ManagementPath = ManagementPath.Users;
const ACCOUNT: ManagementPath = ManagementPath.Account;

@Injectable({
	providedIn: 'root'
})
export class UsersService {
	public static getPermissions(): SelectItem[] {
		return [
			{value: UserRole.Admin, label: UserRole.Admin},
			{value: UserRole.User, label: UserRole.User}
		];
	}

	public static getTypes(): SelectItem[] {
		return [
			{value: UserType.SSO, label: UserType.SSO},
			{value: UserType.Covisint, label: 'Covisint'}
		];
	}

	constructor(
		private dataService: DataService,
		private localStorageService: LocalStorageService,
		private messageService: AppMessageService) {
	}

	public getUserById(id: number): Observable<User> {
		return this.dataService.getData(USERS + '/' + id).pipe(map((data: UserApi) => data.result));
	}

	public getCurrentUser(): Observable<User> {
		return this.dataService.getData(ACCOUNT)
			.pipe(
				map((data: UserApi) => {
					this.localStorageService.setCurrentUser(data?.result);

					return data?.result;
				})
			);
	}

	public getUsers(
		selectedOrder: SelectedOrder,
		pageNumber: number,
		rowsAmount: number,
		searchValue: string = '',
	): Observable<UserListCommon> {

		const orderDirection: string = selectedOrder.direction === OrderDirection.Asc ? 'asc' : 'desc';
		const isSortByName: boolean = selectedOrder.attr === TableColumnValue.Name;
		const sortByNameQuery = `sort=lastName,firstName,${orderDirection}`;
		const sortByOtherFieldQuery = `sort=${selectedOrder.attr},${orderDirection}`;
		const sortQuery: string = isSortByName ? sortByNameQuery : sortByOtherFieldQuery;
		const searchQuery = searchValue?.length ? `&name=${searchValue}` : '';

		return this.dataService.getData(
			`${USERS}?${sortQuery}&page=${pageNumber - 1}&size=${rowsAmount}${searchQuery}`,
			{observe: 'response'})
			.pipe(
				map((data: HttpResponse<UserListApi>) => {
					const totalRecords: number = +data.headers.get('x-total-count');

					return {
						result: data.body.result,
						error: data.body.error,
						totalRecords,
					};
				})
			);
	}

	addUser(user: User): Observable<UserApi> {
		return this.dataService.postData(USERS, {
			email: user.email,
			name: null,
			role: user.role,
			type: user.type,
			firstName: user.firstName,
			lastName: user.lastName,
			externalId: user.externalId,
		} as User).pipe(
			tap((res: UserApi) => {
				return this.messageService.handleSuccess(
					HttpStatusEnum.CREATE_SUCCESS_MESSAGE('User', res.result.email));
			})
		);
	}

	updateUser(user: User): Observable<UserApi> {
		return this.dataService.putData(USERS + '/' + user.id, {
			email: user.email,
			name: null,
			role: user.role,
			type: user.type,
			firstName: user.firstName,
			lastName: user.lastName,
			externalId: user.externalId,
		} as User);
	}

	deleteUser(id: number): Observable<UserApi> {
		return this.dataService.deleteData(USERS + '/' + id).pipe(
			tap((res: UserApi) => {
				return this.messageService.handleSuccess(
					HttpStatusEnum.DELETE_SUCCESS_MESSAGE('User', res.result.name));
			})
		);
	}
}

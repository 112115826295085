import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';

import {CalendarEvent} from 'src/app/interfaces/events/calendar-event';
import {CalendarCategory, CalendarTimeslot, CategoryTimeslot} from 'src/app/interfaces/calendar/calendar-data';

@Component({
	selector: 'app-calendar-categories',
	templateUrl: './calendar-categories.component.html',
	styleUrls: ['../general-calendar.component.scss', './calendar-categories.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarCategoriesComponent {
	@Input() public title: string;
	@Input() public showAvatar: boolean;
	@Input() public categories: CalendarCategory[] = [];
	@Input() public showTooltip: boolean;
	@Input() public calendarTimeslots: CalendarTimeslot[];
	@Input() public scrollEvent: Observable<void>;

	@Output() public overlayMoreEventsClick: EventEmitter<{ event: Event; categoryTimeslot: CategoryTimeslot }> = new EventEmitter();
	@Output() public clickCalendarEvent: EventEmitter<number> = new EventEmitter();

	public handleClickMoreButton(event: Event, categoryTimeslot: CategoryTimeslot): void {
		this.overlayMoreEventsClick.emit({event, categoryTimeslot});
	}

	public firstThreeEvents(array: CalendarEvent[]): CalendarEvent[] {
		return array.slice(0, 3);
	}

	public moreEventsAmount(array: CalendarEvent[]): number {
		return array.slice(3, array.length).length;
	}

	public openCalendarEvent(id: number) {
		this.clickCalendarEvent.emit(id);
	}

	public checkAvatarFieldExisting(category: CalendarCategory): boolean {
		return Object.keys(category).includes('avatar');
	}
}

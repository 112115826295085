import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment';

import { DateRangeValue } from '../enums/api/date-range-enum';

@Pipe({
	name: 'formatHeaderDate'
})
export class FormatHeaderDatePipe implements PipeTransform {
	transform(value: string | number | Date, calendarPeriod: DateRangeValue): unknown {
		switch (calendarPeriod) {
			case DateRangeValue.Week:
				return moment(value).date();
			case DateRangeValue.Month:
				return value;
			case DateRangeValue.Quarter:
				return value;
			default:
				return moment(value).date();
		}
	}
}

import {CategoryHeaderMenuItem, HeaderCategory} from '../enums/api/category-header-enum';

export const CATEGORY_NAME = {
	[CategoryHeaderMenuItem.Audiende]: HeaderCategory.Audience,
	[CategoryHeaderMenuItem.Channels]: HeaderCategory.Channel,
	[CategoryHeaderMenuItem.Content]: HeaderCategory.Content,
	[CategoryHeaderMenuItem.Regions]: HeaderCategory.Region,
	[CategoryHeaderMenuItem.Spokesperson]: HeaderCategory.Spokesperson,
}

export const CATEGORY_MENU_ITEM = {
	[HeaderCategory.Audience]: CategoryHeaderMenuItem.Audiende,
	[HeaderCategory.Channel]: CategoryHeaderMenuItem.Channels,
	[HeaderCategory.Content]: CategoryHeaderMenuItem.Content,
	[HeaderCategory.Region]: CategoryHeaderMenuItem.Regions,
	[HeaderCategory.Spokesperson]: CategoryHeaderMenuItem.Spokesperson
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {SelectItem} from 'primeng';
import {Observable, Subscription} from 'rxjs';

import {FilterEvent} from 'src/app/enums/event-filter-enum';
import {FilterAction} from 'src/app/enums/filter/filter-action-enum';
import {SelectFilterCategory} from 'src/app/interfaces/filter-category';
import {FilterCategoryLabel} from 'src/app/enums/filter/filter-category-enum';
import {FilterService, SelectedFilters} from 'src/app/services/filter/filter.service';
import {EventsService, FilterCategoriesData} from 'src/app/services/events/events.service';

@Component({
	selector: 'app-filter-sidebar',
	templateUrl: './filter-sidebar.component.html',
	styleUrls: ['./filter-sidebar.component.scss'],
})
export class FilterSidebarComponent implements OnInit, OnDestroy {
	public filterEventPriorities: Observable<SelectItem[]>;
	public filterCategoriesData: FilterCategoriesData;

	public filterCategories: SelectFilterCategory[] = [];
	public appliedFilterCategories: SelectFilterCategory[];

	public readonly filterCategoryLabel = FilterCategoryLabel;

	public showLoader = true;
	public disabledApply = true;
	public disableCloseAll = true;

	public subscriptions: Subscription[] = [];

	@Input() public set showFilterSidebar (value: boolean) {
		if (!value) {
			return;
		}

		this.filterEventPriorities = this.filterService.defaultsEventPriority;

		this.getFilterCategoriesData();
	}

	@Output() public filterEvent: EventEmitter<FilterEvent> = new EventEmitter();

	constructor(
		private filterService: FilterService,
		private eventsService: EventsService,
	) {}

	ngOnInit() {
		const selectedFiltersSub = this.filterService.getSelectedFilters().subscribe((selectedFilters: SelectedFilters) => {
			this.filterCategories = selectedFilters.filterCategories.map((item) => Object.assign({}, item, { ...item.items }));
			this.appliedFilterCategories = selectedFilters.filterCategories.map((item) => Object.assign({}, item, { ...item.items }));

			this.disableCloseAll = !this.getSelectedFiltersAmount();
			this.disabledApply = true;
		})

		const filterEventSub = this.filterService.getFilterEvent().subscribe((event: FilterEvent) => {
			if (event === FilterEvent.Close) {
				this.filterService.setSelectedFilters({
					filterCategories: this.appliedFilterCategories,
					selectedFiltersAmount: this.getSelectedFiltersAmount(),
				});
			}
		})

		this.subscriptions = [ selectedFiltersSub, filterEventSub ];
	}

	ngOnDestroy() {
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	private getFilterCategoriesData(): void {
		const eventsFiltersSub = this.eventsService.getEventsFilters().subscribe((data: FilterCategoriesData) => {
			this.filterCategoriesData = data;
			this.showLoader = false;
		})

		this.subscriptions.push(eventsFiltersSub);
	}

	public clearAllEvent(): void {
		this.disabledApply = true;

		this.filterService.setFilterAction(FilterAction.ClearAll);
	}

	public applyFilter(): void {
		const selectedFiltersAmount = this.getSelectedFiltersAmount();
		this.showLoader = true;
		this.disabledApply = true;

		this.filterService.setSelectedFilters({ filterCategories: this.filterCategories, selectedFiltersAmount });
		this.filterService.setFilterEvent(FilterEvent.Apply);
		this.filterService.setFilterAction(FilterAction.Applied);
	}

	public handleSelectFilterItems(items: Array<number | string>, filterCategoryLabel: FilterCategoryLabel): void {
		const index = this.filterCategories.findIndex((item: SelectFilterCategory) => item.label === filterCategoryLabel);
		this.filterCategories[index].items = [ ...items ];

		this.disabledApply = !this.getSelectedFiltersAmount();
		this.disableCloseAll = !this.getSelectedFiltersAmount();
	}

	private getSelectedFiltersAmount(): number {
		return this.filterCategories.filter(cat => cat.items.length).length;
	}
}

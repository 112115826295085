import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LinkEvent} from '../../../../../../interfaces/events/link-event';
import {EMTY_REQ_FIELD} from '../../../../../../constants/field-errors';

@Component({
	selector: 'app-link-list',
	templateUrl: './link-list.component.html',
	styleUrls: ['../../detail-form.scss', './link-list.component.scss']
})
export class LinkListComponent implements OnInit {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() disabled: boolean;
	@Output() addLink = new EventEmitter<LinkEvent>();
	@Output() changeLink = new EventEmitter<{ linkEvent: LinkEvent, orderId: number }>();
	@Output() deleteLink = new EventEmitter<number>();

	public tempLinkForm: UntypedFormGroup;
	public displayLinkSidebar = false;
	public isAddMode = true;
	public selectedOrderId: number;

	get formControls() {
		return this.tempLinkForm?.controls;
	}

	get isDisableActions(): boolean {
		return this.tempLinkForm.invalid || this.tempLinkForm.pristine;
	}

	get tempLinkTitleControl(): AbstractControl {
		return this.tempLinkForm?.get('linkTitle');
	}

	get tempLinkUrlControl(): AbstractControl {
		return this.tempLinkForm?.get('linkUrl');
	}

	get linkListControl(): AbstractControl {
		return this.parentForm?.get(this.controlName);
	}

	constructor(public formBuilder: UntypedFormBuilder) {
	}

	ngOnInit(): void {
		this.tempLinkForm = this.formBuilder.group({
			linkTitle: [''],
			linkUrl: ['', [Validators.required]],
		});
	}

	public requiredError(formControl: string) {
		if (this.formControls[formControl].errors?.required && this.formControls[formControl].touched) {
			return EMTY_REQ_FIELD;
		}
	}

	public onAddLinkClick() {
		this.displayLinkSidebar = true;
		this.isAddMode = true;
	}

	public onItemClick(linkEvent: LinkEvent, orderId: number) {
		this.tempLinkTitleControl.setValue(linkEvent.title);
		this.tempLinkUrlControl.setValue(linkEvent.link);
		this.displayLinkSidebar = true;
		this.selectedOrderId = orderId;
		this.isAddMode = false;
	}

	public onItemDeleteClick(orderId: number) {
		console.log(orderId);
		this.deleteLink.emit(orderId);
	}

	onChangeVisible(isShow: boolean) {
		if (!isShow) {
			this.clearForm();
		}
	}

	public setLink() {
		console.log(this.tempLinkForm);

		const link = this.tempLinkUrlControl.value;
		const title = this.tempLinkTitleControl.value || link;
		const linkEvent: LinkEvent = {id: null, title, link};

		this.isAddMode
			? this.addLink.emit(linkEvent)
			: this.changeLink.emit({linkEvent, orderId: this.selectedOrderId});

		this.displayLinkSidebar = false;
		this.clearForm();
	}

	private clearForm() {
		this.tempLinkForm.reset();
		this.selectedOrderId = null;
	}

}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {SpokesPerson} from '../../../../../../../interfaces/spokespeople/spokespeople';

@Component({
	selector: 'app-spokesperson-select',
	templateUrl: './spokesperson-select.component.html',
	styleUrls: ['./spokesperson-select.component.scss']
})
export class SpokespersonSelectComponent {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() spokesPeople: SpokesPerson[];
	@Input() noneItem: string;

	@Output() changeSelect: EventEmitter<SpokesPerson> = new EventEmitter();

	public onChangeSelect(person: SpokesPerson) {
		this.changeSelect.emit(person);
	}

}

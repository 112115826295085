import {Injectable} from '@angular/core';

import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

import DataService from '../data.service';
import {KeywordsApi} from '../../interfaces/api/keywords-api';
import {Keyword} from '../../interfaces/keywords/keyword';
import {HttpStatusEnum} from '../../enums/http-status.enum';
import {AppMessageService} from '../app-message.service';
import {KeywordApi} from '../../interfaces/api/keyword-api';
import {UserApi} from '../../interfaces/api/user-api';

const KEYWORDS = 'keywords';

@Injectable({
	providedIn: 'root'
})
export class KeywordsService {

	constructor(
		private dataService: DataService,
		private messageService: AppMessageService) {
	}

	getKeywoardById(id: number): Observable<Keyword> {
		return this.dataService.getData(KEYWORDS + '/' + id).pipe(map((data: KeywordApi) => data.result));
	}

	getKeywords(): Observable<Keyword[]> {
		return this.dataService.getData(KEYWORDS)
			.pipe(
				map((data: KeywordsApi) => data.result)
			);
	}

	addKeyword(text: string): Observable<Keyword> {
		return this.dataService.postData(KEYWORDS, {
			text
		} as Keyword).pipe(
			tap((res: KeywordApi) => {
				return this.messageService.handleSuccess(
					HttpStatusEnum.CREATE_SUCCESS_MESSAGE('Add Quick Search Keyword', res.result.text));
			})
		);
	}

	updateKeyword(keyword: Keyword): Observable<UserApi> {
		return this.dataService.putData(KEYWORDS + '/' + keyword.id, {
			text: keyword.text,
			position: keyword.position
		} as Keyword);
	}

	deleteKeyword(id: number): Observable<Keyword> {
		return this.dataService.deleteData(KEYWORDS + '/' + id).pipe(
			tap((res: KeywordApi) => {
				return this.messageService.handleSuccess(
					HttpStatusEnum.DELETE_SUCCESS_MESSAGE('Quick Search Keyword', res.result.text));
			})
		);
	}
}

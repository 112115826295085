export enum TableColumnValue {
	Name = 'name',
	Position = 'position',
	Role = 'role',
	Email = 'email',
	Region = 'regionTitle',
}

export enum TableColumnTitle {
	Name = 'Name',
	Permissions = 'Permissions',
	Role = 'Title',
	Email = 'Email',
	Region = 'Region',
}

import {Component, EventEmitter, Input, Output} from '@angular/core';

import {FilterCategory} from 'src/app/interfaces/filter-category';
import {FilterService, GroupedCategoryByType} from 'src/app/services/filter/filter.service';

@Component({
	selector: 'app-filter-category',
	templateUrl: './filter-category.component.html',
	styleUrls: ['./filter-category.component.scss']
})
export class FilterCategoryComponent {
	public itemsListWithType: GroupedCategoryByType = {};

	@Input() public items: FilterCategory[] = [];
	@Input() public set itemsWithType(items: FilterCategory[]) {
		if(!items?.length) {
			return;
		}

		this.itemsListWithType = this.filterService.groupCategoryItemsByType(items);
	}
	@Input() public selectedItems: number[];

	@Output() public selectCategoryItems: EventEmitter<Array<number | string>> = new EventEmitter();

	constructor(
		private filterService: FilterService,
	) {}

	public handleModelChange(event: Array<number | string>): void {
		this.selectCategoryItems.emit(event)
	}
}

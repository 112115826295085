import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormSidebarComponent} from '../../../shared/app-sidebars/form-sidebar/form-sidebar.component';
import {Keyword} from '../../../../interfaces/keywords/keyword';
import {Validators} from '@angular/forms';
import {CommsCalendarError} from '../../../../interfaces/comms-calendar-error';
import {COMMS_CALENDAR_ERROR} from '../../../../constants/error-codes';

@Component({
	selector: 'app-keyword-form',
	templateUrl: './keyword-form.component.html',
	styleUrls: ['../../../shared/app-sidebars/form-sidebar/form-sidebar.component.scss'],
})
export class KeywordFormComponent extends FormSidebarComponent implements OnInit{
	private _keyword: Keyword;
	@Input() set keyword(value: Keyword) {
		this._keyword = value;

		// when keywords was received
		if (value) {
			this.titleControl.setValue(value?.text);
		}
	}

	get keyword(): Keyword {
		return this._keyword;
	}

	@Output() deleteClick = new EventEmitter<number>();
	@Output() saveClick = new EventEmitter<Keyword>();

	private handledError: CommsCalendarError = COMMS_CALENDAR_ERROR._407;

	public keywordFormError(formControl: string) {
		this.handledError.message = `Keyword already exists in system`;
		return this.requiredError(formControl, this.handledError);
	}

	ngOnInit() {
		this.sideForm = this.formBuilder.group({
			title: ['', [Validators.required, this.uniqueValueValidator(this.handledError)]],
			description: [null]
		});
	}

	public saveEvent() {
		this.keyword.text = this.titleControl.value;
		this.saveClick.emit(this.keyword);
	}

	public deleteButtonEvent() {
		this.deleteClick.emit(this.keyword.id);
	}
}

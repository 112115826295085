import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
	selector: 'app-textarea',
	templateUrl: './app-textarea.component.html',
	styleUrls: ['./app-textarea.component.scss'],
})
export class AppTextareaComponent {
	@Input() parentForm: UntypedFormGroup;
	@Input() controlName: string;
	@Input() title: string;
	@Input() placeholder: string;
	@Input() maxLength?: number = null;
}

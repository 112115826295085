import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {CategoryListEvent} from '../../../interfaces/category/category-list-event';
import {ToolEvent} from '../../../enums/tool-event-enum';
import {ManagementService} from '../../../services/management.service';
import {CategoryService} from '../../../services/management/category.service';
import {CategoryItem} from '../../../interfaces/category/category-item';
import {SidebarMode} from '../../../enums/sidebar-mode-enum';
import {CategoryType} from '../../../interfaces/category/category-type';
import {CategoryTypeEvent} from '../../../interfaces/category/category-type-event';
import {DataStatus} from '../../../enums/data-status-enum';
import {CategoryName} from '../../../enums/api/сategory-name-enum';
import {CategoryConfigData} from '../../../interfaces/category/categoty-config-data';
import {UnsavedChangesService} from 'src/app/services/unsaved-changes.service';
import {ErrorApi} from '../../../interfaces/api/error-api';

@Component({
	selector: 'app-audience',
	templateUrl: './audience.component.html',
	styleUrls: ['./audience.component.scss']
})
export class AudienceComponent implements OnInit, OnDestroy {
	public categoryConfig: CategoryConfigData = {
		CATEGORY: 'Audience',
		CATEGORY_NAME: CategoryName.Audience
	};
	public readonly sidebarMode = SidebarMode;
	public readonly sidebarStatus = DataStatus;

	public audienceTypes: CategoryType[];
	public audienceTypesBySearch: CategoryType[];
	public currentCategoryType: CategoryType;
	public currentCategory: CategoryItem;

	public showLoader = true;

	public categorySideMode: SidebarMode = SidebarMode.Closed;
	public categoryTypeSideMode: SidebarMode = SidebarMode.Closed;

	public currentDataStatus: DataStatus = DataStatus.Loading;

	private subscriptions: Subscription[];
	private draggedItem: CategoryItem;
	public searchValue: string;

	public showCategoryDeleteSidebar: boolean;
	public showCategoryTypeDeleteSidebar: boolean;

	public audienceError: ErrorApi;

	get categoryTypeSideTitle(): string {
		return this.categoryTypeSideMode === SidebarMode.Edit ? ('Edit Audience Type: ' + (this.currentCategoryType?.title || '')) : 'Add New Audience Type';
	}

	get categorySideTitle(): string {
		return this.categorySideMode === SidebarMode.Edit ? ('Edit Audience: ' + (this.currentCategory?.title || '')) : 'Add New Audience';
	}

	get showCategoryTypeSidebar(): boolean {
		return this.categoryTypeSideMode !== SidebarMode.Closed;
	}

	get showCategorySidebar(): boolean {
		return this.categorySideMode !== SidebarMode.Closed;
	}

	constructor(
		private categoryService: CategoryService,
		private unsavedChangesService: UnsavedChangesService,
		private managementService: ManagementService) {
	}

	ngOnInit() {
		const searchSub = this.managementService.searchValue$
			.pipe(debounceTime(300))
			.subscribe((value: string) => this.handleSearch(value));
		const addCategoryItemSub = this.managementService.categoryItemEvent$.subscribe(() => this.addCategory());
		const addAudienceTypeSub = this.managementService.audienceTypeEvent$.subscribe(() => this.addCategoryType());
		this.subscriptions = [searchSub, addCategoryItemSub, addAudienceTypeSub];
		this.closeSidebars();
	}

	private closeSidebars() {
		const closeAllSub = this.unsavedChangesService.closeAll().subscribe(() => {
			this.closeAllSidebars();
			this.clearSideData();
		});

		this.subscriptions.push(closeAllSub);
	}

	public listClickEvent(catEvent: CategoryListEvent) {
		switch (catEvent.toolEvent) {
			case ToolEvent.Delete:
				this.currentCategory = catEvent.categoryItem;
				this.openCategoryDeleteSidebar();
				break;
			case ToolEvent.Edit:
				this.openCategory(catEvent.categoryItem.id);
				break;
		}
	}

	setDraggedItem(item: CategoryItem) {
		this.draggedItem = item;
	}

	onCategoryItemDrop(droppedItem: CategoryItem) {
		this.showLoader = true;
		const newItem = {...this.draggedItem, position: droppedItem.position, typeId: droppedItem.typeId};
		this.saveCategory(newItem);
	}

	onAddPanelDrop(droppedType: CategoryType) {
		this.showLoader = true;
		const newItem = {...this.draggedItem, position: 1, typeId: droppedType.id};
		this.saveCategory(newItem);
	}

	handleSearch(searchValue: string) {
		this.searchValue = searchValue;
		this.getAllAudienceData();
	}

	public typeToolsClickEvent(catTypeEvent: CategoryTypeEvent) {
		const {id, title, position} = catTypeEvent.categoryType;
		switch (catTypeEvent.toolEvent) {
			case ToolEvent.Delete:
				this.currentCategoryType = catTypeEvent.categoryType;
				this.openCategoryTypeDeleteSidebar();
				break;
			case ToolEvent.Edit:
				this.openCategoryType(id);
				break;
			case ToolEvent.MoveDown:
				this.updateAudienceTypeById(id, title, position + 1);
				break;
			case ToolEvent.MoveUp:
				this.updateAudienceTypeById(id, title, position - 1);
				break;
		}
	}

	public saveCategoryType(newCategoryType: CategoryType) {
		this.currentDataStatus = DataStatus.Saving;

		if (newCategoryType.id) {
			this.updateAudienceTypeById(newCategoryType.id, newCategoryType.title, newCategoryType.position);
		} else {
			this.addAudienceType(newCategoryType.title);
		}
	}

	public deleteCategoryType(typeId: number) {
		this.currentDataStatus = DataStatus.Saving;
		this.categoryService.deleteAudienceType(typeId)
			.subscribe(() => {
				this.getAllAudienceData();
				this.categoryTypeSideMode = SidebarMode.Closed;
				this.showCategoryTypeDeleteSidebar = false;
				this.clearSideData();
			});
	}

	public addCategory(typeId?: number) {
		this.categorySideMode = SidebarMode.Add;
		this.currentDataStatus = DataStatus.Loading;

		this.categoryService.getAudienceTypes()
			.subscribe(res => {
				this.audienceTypes = res;
				this.currentCategory = {typeId} as CategoryItem;
				this.currentDataStatus = DataStatus.Ready;
			});
	}

	public deleteCategory(catId: number) {
		this.currentDataStatus = DataStatus.Saving;
		this.categoryService.deleteCategory(catId, this.categoryConfig)
			.subscribe(() => {
				this.getAllAudienceData();
				this.categorySideMode = SidebarMode.Closed;
				this.showCategoryDeleteSidebar = false;
				this.clearSideData();
			});
	}

	public saveCategory(newCategory: CategoryItem) {
		this.currentDataStatus = DataStatus.Saving;

		if (newCategory.id) {
			this.categoryService.updateCategory(newCategory).subscribe(() => {
					this.getAllAudienceData();
					this.closeAllSidebars();
				},
				(error) => this.handleAudienceError(error));
		} else {
			this.categoryService.addCategory(newCategory, this.categoryConfig).subscribe(() => {
					this.getAllAudienceData();
					this.closeAllSidebars();
				},
				(error) => this.handleAudienceError(error));
		}
	}

	public closeAllSidebars(isUnsavedData?: boolean) {
		if (isUnsavedData) {
			return;
		}

		this.categorySideMode = SidebarMode.Closed;
		this.categoryTypeSideMode = SidebarMode.Closed;
		this.clearSideData();
	}

	public closeCategoryDeleteSidebar() {
		this.showCategoryDeleteSidebar = false;
		this.currentDataStatus = DataStatus.Ready;
	}

	public closeCategoryTypeDeleteSidebar() {
		this.showCategoryTypeDeleteSidebar = false;
		this.currentDataStatus = DataStatus.Ready;
	}

	public openCategoryTypeDeleteSidebar() {
		this.showCategoryTypeDeleteSidebar = true;
		this.currentDataStatus = DataStatus.Loading;
	}

	public openCategoryDeleteSidebar() {
		this.showCategoryDeleteSidebar = true;
		this.currentDataStatus = DataStatus.Loading;
	}

	private getAllAudienceData() {
		this.showLoader = true;

		if (this.searchValue?.length) {
			this.categoryService.searchAudienceByTitle(this.searchValue)
				.subscribe((audienceTypes: CategoryType[]) => {
					this.audienceTypesBySearch = audienceTypes;
					this.showLoader = false;
				});
		} else {
			this.categoryService.getAudienceTypes()
				.subscribe((audienceTypes: CategoryType[]) => {
					this.audienceTypes = audienceTypes;
					this.showLoader = false;
				});
		}
	}

	private handleAudienceError(error: ErrorApi) {
		this.currentDataStatus = DataStatus.Ready;
		this.audienceError = error;
	}

	public resetCategoryError() {
		this.audienceError = null;
	}

	private addAudienceType(title: string) {
		this.categoryService.addCategoryType(title)
			.subscribe(() => {
					this.getAllAudienceData();
					this.categoryTypeSideMode = SidebarMode.Closed;
				},
				(error) => this.handleAudienceError(error));
	}

	private updateAudienceTypeById(id: number, title: string, position: number) {
		this.categoryService.updateCategoryTypeById({id, title, position})
			.subscribe(() => {
					this.getAllAudienceData();
					this.categoryTypeSideMode = SidebarMode.Closed;
				},
				(error) => this.handleAudienceError(error)
			);
	}

	private addCategoryType() {
		this.categoryTypeSideMode = SidebarMode.Add;
		this.currentCategoryType = {} as CategoryType;
		this.currentDataStatus = DataStatus.Ready;
	}

	private openCategoryType(typeId: number) {
		this.categoryTypeSideMode = SidebarMode.Edit;
		this.currentDataStatus = DataStatus.Loading;
		this.categoryService.getCategoryTypeById(typeId)
			.subscribe((res: CategoryType) => {
				this.currentCategoryType = res;
				this.currentDataStatus = DataStatus.Ready;
			});
	}

	private openCategory(categoryId: number) {
		this.categorySideMode = SidebarMode.Edit;
		this.currentDataStatus = DataStatus.Loading;

		forkJoin([
			this.categoryService.getCategoryById(categoryId),
			this.categoryService.getAudienceTypes()
		]).subscribe((res: [CategoryItem, CategoryType[]]) => {
			const [currentCategory, audienceTypes] = res;
			this.currentCategory = currentCategory;
			this.audienceTypes = audienceTypes;
			this.currentDataStatus = DataStatus.Ready;
		});
	}

	private clearSideData() {
		this.currentDataStatus = DataStatus.Loading;
		this.currentCategoryType = null;
		this.currentCategory = null;
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '../../app-routing.module';
import {AudienceItemComponent} from '../../components/general/sidebars/sidebar-forms/event-form/audience-list/audience-item/audience-item.component';
import {AudienceListComponent} from '../../components/general/sidebars/sidebar-forms/event-form/audience-list/audience-list.component';
import {CalendarCategoriesComponent} from 'src/app/components/general/calendar/calendar-category/calendar-categories.component';
import {CalendarEventComponent} from 'src/app/components/general/calendar/calendar-event/calendar-event.component';
import {CalendarHeaderComponent} from '../../components/general/calendar-header/calendar-header.component';
import {CalendarPriorityComponent} from '../../components/general/calendar-priority/calendar-priority.component';
import {CalendarResultComponent} from 'src/app/components/general/calendar-result/calendar-result.component';
import {CategoryInfoComponent} from '../../components/general/category-info/category-info.component';
import {EventDatepickerComponent} from '../../components/general/sidebars/sidebar-forms/event-form/event-datepicker/event-datepicker.component';
import {EventDescriptionComponent} from '../../components/general/sidebars/sidebar-forms/event-form/event-description/event-description.component';
import {EventFormComponent} from '../../components/general/sidebars/sidebar-forms/event-form/event-form.component';
import {EventLocationComponent} from '../../components/general/sidebars/sidebar-forms/event-form/event-location/event-location.component';
import {EventPriorityComponent} from '../../components/general/sidebars/sidebar-forms/event-form/event-priority/event-priority.component';
import {EventSpokespeopleComponent} from '../../components/general/sidebars/sidebar-forms/event-form/event-spokespeople/event-spokespeople.component';
import {EventTypeComponent} from '../../components/general/sidebars/sidebar-forms/event-form/event-type/event-type.component';
import {FilterCategoryComponent} from '../../components/general/sidebars/filter-sidebar/filter-category/filter-category.component';
import {FilterPriorityComponent} from '../../components/general/sidebars/filter-sidebar/filter-priority/filter-priority.component';
import {FilterSidebarComponent} from '../../components/general/sidebars/filter-sidebar/filter-sidebar.component';
import {FilterSpokespersonComponent} from '../../components/general/sidebars/filter-sidebar/filter-spokesperson/filter-spokesperson.component';
import {GeneralCalendarComponent} from '../../components/general/calendar/general-calendar.component';
import {GeneralViewComponent} from './general-view.component';
import {LinkItemComponent} from '../../components/general/sidebars/sidebar-forms/event-form/link-list/link-item/link-item.component';
import {LinkListComponent} from '../../components/general/sidebars/sidebar-forms/event-form/link-list/link-list.component';
import {PriorityInfoComponent} from '../../components/general/priority-info/priority-info.component';
import {SharedModule} from '../../components/shared/shared.module';
import {
	SpokespersonSelectComponent
} from '../../components/general/sidebars/sidebar-forms/event-form/event-spokespeople/spokesperson-select/spokesperson-select.component';
import {ToastModule} from 'primeng';

@NgModule({
	declarations: [
		AudienceItemComponent,
		AudienceListComponent,
		CalendarCategoriesComponent,
		CalendarEventComponent,
		CalendarHeaderComponent,
		CalendarPriorityComponent,
		CalendarResultComponent,
		CategoryInfoComponent,
		EventDatepickerComponent,
		EventDescriptionComponent,
		EventFormComponent,
		EventLocationComponent,
		EventPriorityComponent,
		EventSpokespeopleComponent,
		EventTypeComponent,
		FilterCategoryComponent,
		FilterPriorityComponent,
		FilterSidebarComponent,
		FilterSpokespersonComponent,
		GeneralCalendarComponent,
		GeneralViewComponent,
		LinkItemComponent,
		LinkListComponent,
		PriorityInfoComponent,
		SpokespersonSelectComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		AppRoutingModule,
		HttpClientModule,
		ToastModule,
	]
})
export class GeneralModule {
}

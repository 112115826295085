import {Component, EventEmitter, Input, Output} from '@angular/core';

import * as moment from 'moment';

import {Keyword} from '../../../../interfaces/keywords/keyword';
import {CalendarEvent} from '../../../../interfaces/events/calendar-event';

@Component({
	selector: 'app-quick-links-list',
	templateUrl: './quick-links-list.component.html',
	styleUrls: ['./quick-links-list.component.scss']
})
export class QuickLinksListComponent {
	@Input() keywordList: Keyword[];
	@Input() eventList: CalendarEvent[];
	@Input() searchValue: string;
	@Output() keywordsClickEvent: EventEmitter<Keyword> = new EventEmitter();
	@Output() eventItemClick: EventEmitter<CalendarEvent> = new EventEmitter();
	@Output() seeAllClickEvent = new EventEmitter();

	get eventListExist() {
		return Boolean(this.eventList?.length);
	}

	get keywordListExist() {
		return Boolean(this.keywordList?.length);
	}

	get searchValueExist() {
		return Boolean(this.searchValue?.length);
	}

	public getDateValue(item) {
		const start = moment(item.startDate);
		const end = moment(item.endDate);
		const sameYear = start.year() === end.year();
		const sameMonth = start.month() === end.month() && sameYear;
		const sameDay = start.date() === end.date() && sameMonth;

		if (sameDay) {
			return start.format('MMM D, YYYY');
		}
		if (sameMonth) {
			return `${start.format('MMM D')} - ${end.date()}, ${start.year()}`;
		}
		if (sameYear) {
			return `${start.format('MMM D')} - ${end.format('MMM D, YYYY')}`;
		}
		return `${start.format('MMM D, YYYY')} - ${end.format('MMM D, YYYY')}`;
	}

	public onKeywordsClick(item) {
		this.keywordsClickEvent.emit(item);
	}

	public onSeeAllClick() {
		this.seeAllClickEvent.emit();
	}

	public onEventsClick(item) {
		this.eventItemClick.emit(item);
	}
}

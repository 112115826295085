import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OverlayPanel} from 'primeng';
import {ALTERNATE_CATEGORIES_TOOLTIP_TEXT} from '../../../constants/category-tooltip.constants';
import {Observable, Subscription} from 'rxjs';

@Component({
	selector: 'app-category-info',
	templateUrl: './category-info.component.html',
	styleUrls: ['./category-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryInfoComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() scrollEvent: Observable<void>;
	@ViewChild('overlayPanel', {static: false}) overlayPanel: OverlayPanel;
	private closeEventSub: Subscription;

	ngOnInit() {
		this.closeEventSub = this.scrollEvent.subscribe(() => this.closeOverlayPanel());
	}

	ngOnDestroy() {
		this.closeEventSub.unsubscribe();
	}

	get panelContent(): string {
		return ALTERNATE_CATEGORIES_TOOLTIP_TEXT[this.title];
	}

	public showOverlayPanel(event: Event): void {
		this.overlayPanel?.show(event);
	}

	public closeOverlayPanel(): void {
		this.overlayPanel.hide();
	}
}
